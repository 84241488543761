import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getSelects, getDatas, deleteSystemUser,
} from '../../../services/user/sysytemUserList.service';
import {
  ACTION_TYPE,
  actCallApiSelectSystemUserSuccess,
  actCallApiGetSystemUserSuccess,
  actCallApiSystemUserDeleteSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * コンボボックス用一覧取得
 *
 */
function* callApiSelectSystemUser() {
  try {
    const selects = yield call(getSelects);
    yield put(actCallApiSelectSystemUserSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param {*} param0
 */
function* callApiGetSystemUsers({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiGetSystemUserSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param {*} param0
 */
function* callApiSystemUserDelete({ param }) {
  try {
    yield call(deleteSystemUser, param);
    yield put(actCallApiSystemUserDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSystemUserList() {
  yield takeLatest(ACTION_TYPE.CALL_API_SELECT_SYSTEM_USER, callApiSelectSystemUser);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_SEARCH, callApiGetSystemUsers);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_DELETE, callApiSystemUserDelete);
}
