import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete } from '@material-ui/core';

export default function SearchSelectBox(props) {
  const {
    options,
    error,
    value,
    onChange,
    disableClearable,
    disabled,
    label,
    name,
  } = props;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" error={error} label={label} />}
      value={value}
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue,
          },
        });
      }}
      disabled={disabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}

SearchSelectBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired).isRequired,
  error: PropTypes.bool,
  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};

SearchSelectBox.defaultProps = {
  error: false,
  value: null,
  disableClearable: false,
  disabled: false,
};
