export function amountKeyTwo(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'includTaxAmountTwo';
      break;
    case '1':
      key = 'excludTaxAmountTwo';
      break;
    case '2':
      key = 'discountAmountTwo';
      break;
    case '3':
      key = 'discountBeforeAmountTwo';
      break;
    default:
      key = '';
      break;
  }
  return key;
}

export function amountKeyThree(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'includTaxAmountThree';
      break;
    case '1':
      key = 'excludTaxAmountThree';
      break;
    case '2':
      key = 'discountAmountThree';
      break;
    case '3':
      key = 'discountBeforeAmountThree';
      break;
    default:
      key = '';
      break;
  }
  return key;
}

export function amountKeyFour(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'includTaxAmountFour';
      break;
    case '1':
      key = 'excludTaxAmountFour';
      break;
    case '2':
      key = 'discountAmountFour';
      break;
    case '3':
      key = 'discountBeforeAmountFour';
      break;
    default:
      key = '';
      break;
  }
  return key;
}

export function amountKeyFive(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'includTaxAmountFive';
      break;
    case '1':
      key = 'excludTaxAmountFive';
      break;
    case '2':
      key = 'discountAmountFive';
      break;
    case '3':
      key = 'discountBeforeAmountFive';
      break;
    default:
      key = '';
      break;
  }
  return key;
}

export function amountKeySix(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'includTaxAmountSix';
      break;
    case '1':
      key = 'excludTaxAmountSix';
      break;
    case '2':
      key = 'discountAmountSix';
      break;
    case '3':
      key = 'discountBeforeAmountSix';
      break;
    default:
      key = '';
      break;
  }
  return key;
}

export function amountKeyOther(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'includTaxAmountOther';
      break;
    case '1':
      key = 'excludTaxAmountOther';
      break;
    case '2':
      key = 'discountAmountOther';
      break;
    case '3':
      key = 'discountBeforeAmountOther';
      break;
    default:
      key = '';
      break;
  }
  return key;
}
