import {
  Box,
  DialogActions, DialogContent,
} from '@material-ui/core';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';
import TextArea from '../../../components/atoms/textArea/TextArea';
import {
  actCallApiCreateMemberDetailMemo,
  actCallApiGetMemberDetailMemo,
  actCallApiUpdateMemberDetailMemo,
  actResetMemberDetailFlg,
} from '../../../redux/member/memberDetail/action';

class MemberMemoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      loading: false,
      info: {
        content: '',
        id: '',
        memberName: '',
        storeName: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.memberDetail.info !== this.props.memberDetail.info) {
      if (this.props.memberDetail.info) {
        this.setData(this.props.memberDetail.info);
      }
    }
    if (prevProps.memberDetail.isCreateMemoSuccess
      !== this.props.memberDetail.isCreateMemoSuccess) {
      if (this.props.memberDetail.isCreateMemoSuccess) {
        this.success();
      }
    }
  }

  setData(info) {
    this.setState({
      info,
      loading: false,
    });
  }

  success = () => {
    this.props.onCreateSuccess();
  }

  handleSave = () => {
    const { info } = this.state;
    const { memoId, memberId } = this.props;
    const errorMessage = this.memoCheck(info.content);
    this.setState({ errorMessage, loading: errorMessage === '' });

    if (errorMessage) {
      return;
    }

    const memo = info.content;
    this.props.dispatch(actResetMemberDetailFlg());

    if (memoId) {
      const memoParam = {
        id: info.id,
        content: memo,
      };
      this.props.dispatch(actCallApiUpdateMemberDetailMemo(memoParam));
    } else {
      this.props.dispatch(actCallApiCreateMemberDetailMemo({ memo, memberId }));
    }
  }

  memoCheck = (value) => {
    if (!value) {
      return '入力してください';
    }
    return '';
  }

  handleChange = (event) => {
    const { value } = event.target;
    const { info } = this.state;
    this.setState({
      info: { ...info, content: value },
      errorMessage: this.memoCheck(value),
    });
  }

  init() {
    const { memoId } = this.props;
    this.setState({
      info: {
        memberName: '',
        storeName: '',
        content: '',
      },
      loading: false,
      errorMessage: '',
    });
    if (memoId) {
      this.props.dispatch(actCallApiGetMemberDetailMemo({ id: memoId }));
      this.setState({ loading: true });
    }
  }

  render() {
    const {
      open,
      onClose,
      memoId,
    } = this.props;

    const {
      errorMessage,
      loading,
      info,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={onClose}>
        <DialogColorTitle>
          <Box>{memoId ? 'メモを更新' : 'メモを登録'}</Box>
        </DialogColorTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <FormTitle title="メモ" isRequired />
            <TextArea
              value={info.content}
              onChange={this.handleChange}
              name="memo"
            />
            <FormErrorText error>{errorMessage}</FormErrorText>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>キャンセル</CancelButton>
          <SaveButton onClick={this.handleSave} loading={loading}>保存</SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  memberDetail: state.memberDetail,
});

export default connect(mapStateToProps)(MemberMemoModal);
