import React from 'react';
import urls from '../../constants/urls';

class NotFound extends React.Component {
  constructor() {
    super();
    window.location.href = urls.OTHER.HOME;
  }
}

export default NotFound;
