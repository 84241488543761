/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 1,
    width: '100vw',
    height: '100vh',
    bottom: 0,
    right: 0,
    background: 'rgba(255,255,255, 0.3)',
    display: 'flex',
  },
  progress: {
    width: '100%',
  },
});

class Loading extends React.Component {
  render() {
    const { isLoading, classes } = this.props;

    const loadingScreen = (
      <div className={classes.root}>
        <div className={classes.progress}>
          <LinearProgress color="secondary" />
        </div>
      </div>
    );
    return isLoading ? loadingScreen : null;
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.common.isLoading,
});

export default withStyles(styles)(connect(mapStateToProps)(Loading));
