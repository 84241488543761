import { call, put, takeLatest } from 'redux-saga/effects';
import { getStoreAndUsages } from '../../../services/common/selectList.service';
import { getDetails, create, update } from '../../../services/visit/visitStatusDetail.service';
import { checkExist } from '../../../services/member/memberDetail.service';
import {
  ACTION_TYPE,
  actCallApiGetSelectSuccess,
  actCallApiGetVisitStatusDetailSuccess,
  actCallApiUpdateVisitStatusDetailSuccess,
  actCallApiCheckVisitStatusMemberSuccess,
  actCallApiCreateVisitStatusDetailSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 店舗と利用タイプ取得
 * @param {*} param0
 */
function* callApiGetSelects() {
  try {
    const selects = yield call(getStoreAndUsages);
    yield put(actCallApiGetSelectSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 詳細を取得
 * @param {*} param0
 */
function* callApiGetDetail({ param }) {
  try {
    const data = yield call(getDetails, param);
    yield put(actCallApiGetVisitStatusDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiCreateVisitStatus({ param }) {
  try {
    yield call(create, param);
    yield put(actCallApiCreateVisitStatusDetailSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 来店状況更新
 * @param {*} param0
 */
function* callApiUpdateVisitStatus({ param }) {
  try {
    yield call(update, param);
    yield put(actCallApiUpdateVisitStatusDetailSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員チェック
 * @param {*} param0
 */
function* callApiCheckMember({ param }) {
  try {
    const data = yield call(checkExist, param);
    yield put(actCallApiCheckVisitStatusMemberSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallVisitStatusDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL_SELECT, callApiGetSelects);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL, callApiGetDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_VISIT_STATUS, callApiUpdateVisitStatus);
  yield takeLatest(ACTION_TYPE.CALL_API_CHECK_VISIT_STATUS_MEMBER, callApiCheckMember);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_VISIT_STATUS, callApiCreateVisitStatus);
}
