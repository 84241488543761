import React from 'react';

const RADIAN = Math.PI / 180;

export default function PieText(props) {
  const radius = props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5;
  const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
  const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > props.cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(props.percent * 100).toFixed(0)}%`}
    </text>
  );
}
