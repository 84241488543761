import React from 'react';
import {
  TextField, Checkbox, Chip, Autocomplete,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';

export default function SearchMultiSelectBox(renderProps) {
  const {
    options,
    onChange,
    error,
    values,
    name,
  } = renderProps;

  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue,
          },
        });
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip label={option.name} {...getTagProps({ index })} style={{ color: 'black' }} />
      ))}
      value={values}
      renderInput={(params) => (
        <TextField {...params} error={error} />
      )}
      noOptionsText="データがありません"
    />
  );
}

SearchMultiSelectBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SearchMultiSelectBox.defaultProps = {
  error: false,
  values: [],
};
