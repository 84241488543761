// #region Type
export const ACTION_TYPE = {
  CALL_API_CREATE_STORE: 'CALL_API_CREATE_STORE',
  CALL_API_CREATE_STORE_SUCCESS: 'CALL_API_CREATE_STORE_SUCCESS',
  CALL_API_GET_STORE_DETAIL: 'CALL_API_GET_STORE_DETAIL',
  CALL_API_GET_STORE_DETAIL_SUCCESS: 'CALL_API_GET_STORE_DETAIL_SUCCESS',
  RESET_STORE_DETAIL_FLG: 'RESET_STORE_DETAIL_FLG',
  CALL_API_UPDATE_STORE: 'CALL_API_UPDATE_STORE',
  CALL_API_UPDATE_STORE_SUCCESS: 'CALL_API_UPDATE_STORE_SUCCESS',
};
// #endregion

// #region action
export const actCallApiCreateStore = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_STORE,
  param,
});

export const actCallApiCreateStoreSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CREATE_STORE_SUCCESS,
  data,
});

export const actCallApiGetStoreDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_DETAIL,
  param,
});

export const actCallApiGetStoreDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_DETAIL_SUCCESS,
  data,
});

export const actCallApiUpdateStore = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_STORE,
  param,
});

export const actCallApiUpdateStoreSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_STORE_SUCCESS,
  data,
});

export const actResetStoreDetailFlg = () => ({
  type: ACTION_TYPE.RESET_STORE_DETAIL_FLG,
});
// #endregion
