import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button, TextField, Grid,
  Box, Typography, Paper, CssBaseline,
  Backdrop, CircularProgress,
} from '@material-ui/core';
import { actCallApiLogin, actLoginDataReset } from '../../../redux/other/login/action';
import urls from '../../../constants/urls';
import loginslide1 from '../../../public/asset/loginslide1.jpg';
import loginslide2 from '../../../public/asset/loginslide2.jpg';
import loginslide3 from '../../../public/asset/loginslide3.jpg';
import loginslide4 from '../../../public/asset/loginslide4.jpg';
import logo from '../../../public/asset/logo.png';

class Login extends React.Component {
  constructor(props) {
    super(props);
    document.cookie = 'jwt=; path=/; max-age=0';
    localStorage.clear();
    props.dispatch(actLoginDataReset());
    const imglist = [
      loginslide1,
      loginslide2,
      loginslide3,
      loginslide4,
    ];
    const selectnum = Math.floor(Math.random() * imglist.length);

    this.logoImg = imglist.splice(selectnum, 1);

    this.state = {
      info: {
        loginId: '',
        password: '',
      },
      message: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUser !== this.props.loginUser) {
      let token = '';
      let message = '';
      if (this.props.loginUser.isError === false) {
        token = this.props.loginUser.result.token;
        document.cookie = `jwt=${token}; path=/; max-age=604800`;
        this.props.history.push(urls.OTHER.HOME);
      }
      if (this.props.loginUser.isError === true) {
        message = 'ログインIDまたはパスワードが間違っています';
      }
      this.setStatus(message);
    }
  }

  setStatus(message) {
    const { info } = this.state;
    this.setState({ message, info: { ...info, password: '' }, loading: false });
  }

  handleBtnLogin = (event) => {
    this.setState({ loading: true });

    event.preventDefault();
    const { info } = this.state;

    if (!info.loginId || !info.password) {
      return;
    }

    this.props.dispatch(
      actCallApiLogin({
        loginId: info.loginId,
        password: info.password,
      }),
    );
  };

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const { info } = this.state;
    this.setState({ info: { ...info, [key]: value } });
  };

  render() {
    const {
      info, message, loading,
    } = this.state;

    return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        {loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${this.logoImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (theme) => (theme.palette.mode === 'light'
              ? theme.palette.grey[50]
              : theme.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ m: 1 }}>
              <img src={logo} alt="logo" />
            </Box>
            <Typography component="h1" variant="h5">
              ログイン
            </Typography>
            {message && <Box sx={{ my: 3, color: '#f50057', lineHeight: '1' }}>{message}</Box>}

            <Box component="form" onSubmit={this.handleBtnLogin} sx={{ mt: 1 }}>
              <TextField
                variant="outlined"
                fullWidth
                id="loginId"
                label="ログインID"
                autoComplete="off"
                name="loginId"
                autoFocus
                required
                margin="normal"
                value={info.loginId}
                onChange={(event) => this.handleChange(event)}
              />
              <TextField
                variant="outlined"
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                autoComplete="off"
                id="password"
                required
                margin="normal"
                value={info.password}
                onChange={(event) => this.handleChange(event)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                ログイン
              </Button>
            </Box>
          </Box>
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              BENKEI GROUP All Rights Reserved.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({ loginUser: state.loginUser });

export default connect(mapStateToProps)(Login);
