import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: '#f50057',
    borderRadius: '0px',
    marginLeft: '5px',
  },
}));

export default function RequiredChip() {
  const classes = useStyles();

  return (
    <Chip label="必須" size="small" className={classes.root} />
  );
}
