import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Container, Box, Checkbox } from '@material-ui/core';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import {
  actCallApiGetUseStoreMemberAnalysisList,
} from '../../../redux/memberAnalysis/useStoreMemberAnalysises/action';
import UseStoreMemberAnalysisesTable from './UseStoreMemberAnalysisesTable';

class UseStoreMemberAnalysises extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actSetBreadcrumbs([{ name: '店舗利用別会員分析' }]));

    const searchCondition = {
      searchStartVisitDate: '',
      searchEndVisitDate: '',
      allStoreSelected: false,
    };

    this.state = {
      summaryDatas: [],
      searchCondition,
    };

    this.search(searchCondition);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.useStoreMemberAnalysis.datas !== this.props.useStoreMemberAnalysis.datas) {
      if (this.props.useStoreMemberAnalysis.datas) {
        this.setSummary(this.props.useStoreMemberAnalysis.datas);
      }
    }
  }

  setSummary = (summaryDatas) => {
    this.setState({ summaryDatas });
  }

  search = (searchCondition) => {
    this.props.dispatch(actCallApiGetUseStoreMemberAnalysisList({
      searchStartVisitDate: searchCondition.searchStartVisitDate,
      searchEndVisitDate: searchCondition.searchEndVisitDate,
      allStoreSelected: searchCondition.allStoreSelected,
    }));
  }

  onSearchChange = (event) => {
    const { name, value, checked } = event.target;
    const { searchCondition } = this.state;

    if (name === 'allStoreSelected') {
      this.setState({ searchCondition: { ...searchCondition, [name]: checked } });
    } else {
      this.setState({ searchCondition: { ...searchCondition, [name]: value } });
    }
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
  }

  render() {
    const {
      searchCondition, summaryDatas,
    } = this.state;

    return (
      <>
        <Container>
          <SearchBox onSearch={this.onSearch}>
            <Box display="flex">
              <Box>
                <FormTitle title="来店日" />
                <Box display="flex" mt={1} alignItems="center">
                  <Box>
                    <Datepicker
                      value={searchCondition.searchStartVisitDate}
                      name="searchStartVisitDate"
                      handleChange={this.onSearchChange}
                      clearable
                    />
                  </Box>
                  <Box mx={4}>～</Box>
                  <Box>
                    <Datepicker
                      minDate={searchCondition.searchStartVisitDate}
                      name="searchEndVisitDate"
                      value={searchCondition.searchEndVisitDate}
                      handleChange={this.onSearchChange}
                      clearable
                    />
                  </Box>
                </Box>
              </Box>
              <Box ml={4}>
                <FormTitle title="全店舗利用" />
                <Box display="flex" mt={1} alignItems="center">
                  <Checkbox
                    name="allStoreSelected"
                    checked={searchCondition.allStoreSelected}
                    onChange={this.onSearchChange}
                  />
                </Box>
              </Box>
            </Box>
          </SearchBox>

          <Box>
            <UseStoreMemberAnalysisesTable
              rows={summaryDatas}
            />
          </Box>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  useStoreMemberAnalysis: state.useStoreMemberAnalysis,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps)(UseStoreMemberAnalysises));
