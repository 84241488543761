import { ACTION_TYPE } from './action';

// region Reducer
const storeDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_CREATE_STORE_SUCCESS:
      return {
        ...state,
        resultData: action.data,
        isCreateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GET_STORE_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.RESET_STORE_DETAIL_FLG:
      return {
        ...state,
        isCreateSuccess: false,
        isUpdateSuccess: false,
      };
    case ACTION_TYPE.CALL_API_UPDATE_STORE_SUCCESS:
      return {
        ...state,
        resultData: action.data,
        isUpdateSuccess: true,
      };
    default:
      return state;
  }
};

export default storeDetail;
