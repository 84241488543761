import { combineReducers } from 'redux';
import common from './common/common/reducer';

import loginUser from './other/login/reducer';

import systemUserList from './user/systemUserList/reducer';
import systemUserDetail from './user/systemUserDetail/reducer';
import roleList from './user/roleList/reducer';
import roleDetail from './user/roleDetail/reducer';

import storeList from './setting/storeList/reducer';
import storeDetail from './setting/storeDetail/reducer';

import visitRegist from './visit/visitRegist/reducer';
import dailyVisitStatus from './visit/dailyVisitStatus/reducer';
import visitStatusList from './visit/visitStatusList/reducer';
import storeVisitStatus from './visit/storeVisitStatus/reducer';
import visitStatusDetail from './visit/visitStatusDetail/reducer';

import memoList from './member/memoList/reducer';
import memoDetail from './member/memoDetail/reducer';
import memberList from './member/memberList/reducer';
import memberDetail from './member/memberDetail/reducer';

import memberAnalysis from './memberAnalysis/memberAnalysis/reducer';
import useStoreMemberAnalysis from './memberAnalysis/useStoreMemberAnalysises/reducer';

const appReducer = combineReducers({
  common,

  loginUser,

  systemUserList,
  systemUserDetail,
  roleList,
  roleDetail,

  storeList,
  storeDetail,

  memoList,
  memoDetail,
  memberList,
  memberDetail,

  visitRegist,
  dailyVisitStatus,
  storeVisitStatus,
  visitStatusList,
  visitStatusDetail,

  memberAnalysis,
  useStoreMemberAnalysis,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
