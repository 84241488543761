import {
  AppBar, Button, CssBaseline,
  List, Divider, Drawer,
  IconButton, Menu, Toolbar,
  Typography, MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  ChevronLeft as ChevronLeftIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import urls from '../../../constants/urls';
import {
  getVisitStatusList, getSettingList, getUserSettingList, getMemberAnalysisList, isShowArea,
} from './check-show-screen';
import BasicBreadcrumbs from './component/BasicBreadcrumbs';
import CollapseList from './component/CollapseList';
import HeaderMenuItem from './component/HeaderMenuItem';
import getStyle from './header-styles';

const styles = getStyle();

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      isOpenSetting: false,
      isOpenUserSetting: false,
      isOpenVisitStatus: false,
      isOpenMemberAnalysis: false,
      anchorMenuEl: null,
    };
  }

  handleDrawer = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleUserMenuOpen = (event) => {
    this.setState({ anchorMenuEl: event.currentTarget });
  };

  handleUserMenuClose = () => {
    this.setState({ anchorMenuEl: null });
  };

  handleLogout = () => {
    localStorage.clear();
    window.location.href = urls.OTHER.LOGIN;
  };

  handleOpenSetting = () => {
    const { isOpenSetting } = this.state;
    this.setState({ isOpenSetting: !isOpenSetting });
  };

  handleOpenUserSetting = () => {
    const { isOpenUserSetting } = this.state;
    this.setState({ isOpenUserSetting: !isOpenUserSetting });
  };

  handleOpenVisitStatus = () => {
    const { isOpenVisitStatus } = this.state;
    this.setState({ isOpenVisitStatus: !isOpenVisitStatus });
  };

  handleOpenMemberAnalysis = () => {
    const { isOpenMemberAnalysis } = this.state;
    this.setState({ isOpenMemberAnalysis: !isOpenMemberAnalysis });
  };

  render() {
    const { classes } = this.props;

    const {
      open,
      isOpenSetting,
      isOpenUserSetting,
      isOpenVisitStatus,
      isOpenMemberAnalysis,
      anchorMenuEl,
    } = this.state;

    const { children, loginUser } = this.props;

    const { user } = loginUser;

    const screenList = user ? user.screenList : [];

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" className={classes.title}>
              <a href="/" className={classes.headerLink}>
                佐渡寿司弁慶
              </a>
            </Typography>
            <div>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleUserMenuOpen}
                color="inherit"
                endIcon={<KeyboardArrowDownIcon />}
              >
                <Typography variant="h6" noWrap>
                  {user && user.user.lastName + user.user.firstName}
                </Typography>
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorMenuEl}
                keepMounted
                open={Boolean(anchorMenuEl)}
                onClose={this.handleUserMenuClose}
              >
                <MenuItem onClick={this.handleLogout}>ログアウト</MenuItem>
              </Menu>
            </div>
          </Toolbar>
          <BasicBreadcrumbs />
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />

          <List>
            <HeaderMenuItem
              onClose={this.handleDrawer}
              url={urls.VISIT.VISIT_REGIST}
              menuName="来店登録"
            />
            <HeaderMenuItem
              onClose={this.handleDrawer}
              url={urls.MEMBER.MEMBERS}
              menuName="会員一覧"
            />
            <HeaderMenuItem
              onClose={this.handleDrawer}
              url={urls.MEMBER.MEMO}
              menuName="メモ管理"
            />
          </List>

          <CollapseList
            isShow={isShowArea(screenList, getVisitStatusList())}
            onClick={this.handleOpenVisitStatus}
            menuTitle="来店状況"
            isOpen={isOpenVisitStatus}
          >
            <List>
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.VISIT.DAILY_VISIT_STATUS}
                menuName="日別"
              />
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.VISIT.STORE_VISIT_STATUS}
                menuName="店舗別"
              />
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.VISIT.VISIT_STATUS_LIST}
                menuName="来店状況一覧"
              />
            </List>
          </CollapseList>

          <CollapseList
            isShow={isShowArea(screenList, getMemberAnalysisList())}
            onClick={this.handleOpenMemberAnalysis}
            menuTitle="会員分析"
            isOpen={isOpenMemberAnalysis}
          >
            <List>
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.MEMBER_ANALYSIS.STORE_MEMBER_ANALYSIS}
                menuName="店舗別"
              />
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.MEMBER_ANALYSIS.MEMBER_ANALYSIS}
                menuName="会員別"
              />
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.MEMBER_ANALYSIS.USE_STORE_MEMBER_ANALYSIS}
                menuName="店舗利用別"
              />
            </List>
          </CollapseList>

          <CollapseList
            isShow={isShowArea(screenList, getSettingList())}
            onClick={this.handleOpenSetting}
            menuTitle="設定"
            isOpen={isOpenSetting}
          >
            <List>
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.SETTING.STORE}
                menuName="店舗"
              />
            </List>
          </CollapseList>

          <CollapseList
            isShow={isShowArea(screenList, getUserSettingList())}
            onClick={this.handleOpenUserSetting}
            menuTitle="ユーザー設定"
            isOpen={isOpenUserSetting}
          >
            <List>
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.USER.ROLE}
                menuName="権限"
              />
              <HeaderMenuItem
                isNested
                onClose={this.handleDrawer}
                url={urls.USER.SYSTEM_USER}
                menuName="システムユーザー"
              />
            </List>
          </CollapseList>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {children}
        </main>
      </div>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  loginUser: state.loginUser,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Header)));
