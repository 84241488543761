import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getData, createSystemUser, checkLoginId, updateSystemUser, changePassword,
} from '../../../services/user/sysytemUserDetail.service';
import {
  ACTION_TYPE,
  actCallApiGetSystemUserSuccess,
  actCallApiSystemUserCreateSuccess,
  actCallApiSystemUserUpdateSuccess,
  actCallApiSystemUserLoginIdCheckSuccess,
  actCallApiSystemUserChangePasswordSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 作成処理
 *
 * @param {*} param0
 */
function* callApiSystemUserCreate({ param }) {
  try {
    const datas = yield call(createSystemUser, param);
    yield put(actCallApiSystemUserCreateSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新処理
 *
 * @param {*} param0
 */
function* callApiSystemUserUpdate({ param }) {
  try {
    const datas = yield call(updateSystemUser, param);
    yield put(actCallApiSystemUserUpdateSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 一件取得処理
 *
 * @param {*} param0
 */
function* callApiGetSystemUser({ param }) {
  try {
    const data = yield call(getData, param);
    yield put(actCallApiGetSystemUserSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * ログインID存在チェック
 *
 * @param {*} param0
 */
function* callApiSystemUserLoginIdCheck({ param }) {
  try {
    const result = yield call(checkLoginId, param);
    yield put(actCallApiSystemUserLoginIdCheckSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * パスワード変更
 *
 * @param {*} param0
 */
function* callApiSystemUserChangePassword({ param }) {
  try {
    const data = yield call(changePassword, param);
    yield put(actCallApiSystemUserChangePasswordSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSystemUserDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_CREATE, callApiSystemUserCreate);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_UPDATE, callApiSystemUserUpdate);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SYSTEM_USER, callApiGetSystemUser);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_LOGIN_ID_CHECK, callApiSystemUserLoginIdCheck);
  yield takeLatest(ACTION_TYPE.CALL_API_SYSTEM_USER_CHANGE_PASSWORD,
    callApiSystemUserChangePassword);
}
