import React from 'react';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  Container, Box, ToggleButtonGroup, ToggleButton,
} from '@material-ui/core';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import getAdmin from '../../../helpers/common.helper';
import {
  actCallApiGetSelectList, actCallApiGetDailyVisitStatus,
} from '../../../redux/visit/dailyVisitStatus/action';
import DailyDataTable from './DailyDataTable';
import DailyCharts from './DailyCharts';

class DailyVisitStatus extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actSetBreadcrumbs([{ name: '日別来店状況' }]));
    props.dispatch(actCallApiGetSelectList());

    const searchCondition = {
      searchStoreIds: [],
      searchMemberGrades: [],
      searchVisitDate: format(new Date(), 'yyyy/MM/dd'),
    };

    props.dispatch(actCallApiGetDailyVisitStatus(searchCondition));

    this.state = {
      selects: { stores: [], grades: [] },
      summaryDatas: [],
      searchCondition,
      resultDisplayType: 'table',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dailyVisitStatus.selects !== this.props.dailyVisitStatus.selects) {
      if (this.props.dailyVisitStatus.selects) {
        this.setSelects(this.props.dailyVisitStatus.selects);
      }
    }
    if (prevProps.dailyVisitStatus.datas !== this.props.dailyVisitStatus.datas) {
      if (this.props.dailyVisitStatus.datas) {
        this.setSummary(this.props.dailyVisitStatus.datas);
      }
    }
  }

  setSummary = (summaryDatas) => {
    this.setState({ summaryDatas });
  }

  setSelects = (selects) => {
    this.setState({ selects });
  }

  onSearchChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({ searchCondition: { ...searchCondition, [name]: value } });
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    const searchStoreIds = searchCondition.searchStoreIds.map((n) => n.id);
    const searchMemberGrades = searchCondition.searchMemberGrades.map((n) => n.id);
    this.props.dispatch(actCallApiGetDailyVisitStatus({
      searchVisitDate: searchCondition.searchVisitDate, searchStoreIds, searchMemberGrades,
    }));
  }

  onResultTypeChange = (_, resultDisplayType) => {
    if (resultDisplayType === null) {
      return;
    }
    this.setState({ resultDisplayType });
  };

  render() {
    const {
      selects, searchCondition, summaryDatas, resultDisplayType,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <>
        <Container>
          <SearchBox onSearch={this.onSearch}>
            <Box>
              <FormTitle title="来店日" />
              <Box>
                <Datepicker
                  value={searchCondition.searchVisitDate}
                  name="searchVisitDate"
                  handleChange={this.onSearchChange}
                />
              </Box>
            </Box>
            {admin && (
              <Box width="30%" mt={3}>
                <FormTitle title="来店店舗" />
                <SearchMultiSelectBox
                  options={selects.stores}
                  values={searchCondition.searchStoreIds}
                  name="searchStoreIds"
                  onChange={this.onSearchChange}
                />
              </Box>
            )}
            <Box width="30%" mt={3}>
              <FormTitle title="会員グレード" />
              <SearchMultiSelectBox
                options={selects.grades}
                values={searchCondition.searchMemberGrades}
                name="searchMemberGrades"
                onChange={this.onSearchChange}
              />
            </Box>
          </SearchBox>

          <Box my={2}>
            <ToggleButtonGroup
              value={resultDisplayType}
              exclusive
              onChange={this.onResultTypeChange}
            >
              <ToggleButton value="table" aria-label="left aligned">
                一覧表示
              </ToggleButton>
              <ToggleButton value="graf" aria-label="right aligned">
                グラフ表示
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box display={resultDisplayType === 'table' ? '' : 'none'}>
            <DailyDataTable
              rows={summaryDatas}
            />
          </Box>
        </Container>
        <Box display={resultDisplayType === 'graf' ? '' : 'none'}>
          <Container maxWidth={false}>
            <DailyCharts
              rows={summaryDatas}
            />
          </Container>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dailyVisitStatus: state.dailyVisitStatus,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps)(DailyVisitStatus));
