import React from 'react';
import { isValid, format } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';
import { TextField } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { MobileDatePicker as MaterialPicker } from '@material-ui/lab';
import PropTypes from 'prop-types';

export default function Datepicker(props) {
  const {
    value,
    handleChange,
    error,
    maxDate,
    minDate,
    name,
    clearable,
  } = props;

  const onChange = (changeValue) => {
    let formatValue = changeValue;
    if (isValid(changeValue)) {
      formatValue = format(changeValue, 'yyyy/MM/dd');
    }
    handleChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
      <MaterialPicker
        openTo="day"
        toolbarTitle="日付を選択"
        showToolbar
        maxDate={maxDate}
        minDate={minDate}
        showTodayButton
        clearable={clearable}
        cancelText="キャンセル"
        clearText="クリア"
        todayText="今日"
        okText="決定"
        views={['year', 'month', 'day']}
        value={fnsValue}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} helperText={null} error={error} />}
        mask="____年__月__日"
        toolbarFormat="yyyy年MM月dd日"
        inputFormat="yyyy年MM月dd日"
      />
    </LocalizationProvider>
  );
}

Datepicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
};

Datepicker.defaultProps = {
  value: null,
  clearable: false,
};
