import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * メモ一覧取得
 * @param{*}param
 * @returns
 */
export function getDatas(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MEMBER.MEMO.MEMOS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 * @param{*}param
 * @returns
 */
export function deleteMemos(param) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .deletes(REST_API.MEMBER.MEMO.MEMOS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
