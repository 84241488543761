export const ACTION_TYPE = {
  CALL_API_SELECT_MEMO_STORES: 'CALL_API_SELECT_MEMO_STORES',
  CALL_API_SELECT_MEMO_STORES_SUCCESS: 'CALL_API_SELECT_MEMO_STORES_SUCCESS',
  CALL_API_MEMO_SEARCH: 'CALL_API_MEMO_SEARCH',
  CALL_API_MEMO_SEARCH_SUCCESS: 'CALL_API_MEMO_SEARCH_SUCCESS',
  RESET_MEMO_FLG: 'RESET_MEMO_FLG',
  CALL_API_DELETE_MEMO: 'CALL_API_DELETE_MEMO',
  CALL_API_DELETE_MEMO_SUCCESS: 'CALL_API_DELETE_MEMO_SUCCESS',
};

export const actCallApiSelectMemoStores = () => ({
  type: ACTION_TYPE.CALL_API_SELECT_MEMO_STORES,
});

export const actCallSelectMemoStoresSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_SELECT_MEMO_STORES_SUCCESS,
  selects,
});

export const actCallApiMemoSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_MEMO_SEARCH,
  searchCondition,
});

export const actCallApiMemoSearchSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_MEMO_SEARCH_SUCCESS,
  datas,
});

export const actResetMemoFlg = () => ({
  type: ACTION_TYPE.RESET_MEMO_FLG,
});

export const actCallApiDeleteMemo = (param) => ({
  type: ACTION_TYPE.CALL_API_DELETE_MEMO,
  param,
});

export const actCallApiDeleteMemoSuccess = () => ({
  type: ACTION_TYPE.CALL_API_DELETE_MEMO_SUCCESS,
});
