import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

export default function MaterialDialog(props) {
  const {
    children, open, onClose, maxWidth,
  } = props;

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={false}
      fullWidth
      maxWidth={maxWidth}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}
    >
      {children}
    </Dialog>
  );
}

MaterialDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
};

MaterialDialog.defaultProps = {
  maxWidth: 'sm',
};
