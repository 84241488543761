import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 選択肢を取得
 * @returns
 */
export function getSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MEMBER.MEMBER.MEMBER_SELECTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 詳細取得
 * @param {*} param
 * @returns
 */
export function getDetailData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.MEMBER.MEMBER.MEMBER_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 更新用データ取得
 * @param {*} param
 * @returns
 */
export function getMemberUpdateData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.MEMBER.MEMBER.MEMBER, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 新規登録
 * @param {*} param
 * @returns
 */
export function create(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MEMBER.MEMBER.MEMBERS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 更新
 * @param {*} param
 * @returns
 */
export function update(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.MEMBER.MEMBER.MEMBER, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 会員チェック
 * @param {*} param
 * @returns
 */
export function checkExist(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MEMBER.MEMBER.MEMBER_CHECK, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
