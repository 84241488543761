import { call, put, takeLatest } from 'redux-saga/effects';
import getSummary from '../../../services/memberAnalysis/useStoreMemberAnalysises.service';
import {
  ACTION_TYPE,
  actCallApiGetUseStoreMemberAnalysisListSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 店舗利用別会員分析サマリを取得
 * @param {*} param0
 */
function* callApiGetSummary({ param }) {
  try {
    const datas = yield call(getSummary, param);
    yield put(actCallApiGetUseStoreMemberAnalysisListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallUseStoreMemberAnalysis() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_USE_STORE_MEMBER_ANALYSIS, callApiGetSummary);
}
