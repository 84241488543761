import { Box, Container, TextField } from '@material-ui/core';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import {
  actCallApiDeleteStore,
  actCallApiGetStore,
  actResetStoreFlg,
} from '../../../redux/setting/storeList/action';
import { actCallApiMe } from '../../../redux/other/login/action';
import StoreDetail from '../storeDetail/StoreDetail';

class StoreList extends React.Component {
  constructor(props) {
    super(props);
    props.dispatch(actSetBreadcrumbs([{ name: '店舗' }]));
    this.state = {
      searchCondition: {
        searchName: '',
        page: 0,
        records: 10,
        order: 'asc',
        orderBy: '',
      },
      datas: [],
      isSuccessOpen: false,
      successMessage: '',
      isDetailOpen: false,
      selectedRow: null,
    };
    this.headCells = [{
      id: 'name', numeric: false, label: '店舗名', sortKey: 'name',
    }];
    this.search(this.state.searchCondition);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeList.datas !== this.props.storeList.datas) {
      if (this.props.storeList.datas) {
        this.setDatas(this.props.storeList.datas);
      }
    }
    if (prevProps.storeList.isDeleteSuccess !== this.props.storeList.isDeleteSuccess) {
      if (this.props.storeList.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
    if (prevProps.storeDetail.isCreateSuccess !== this.props.storeDetail.isCreateSuccess) {
      if (this.props.storeDetail.isCreateSuccess) {
        this.setDetailSuccess(this.props.storeDetail.resultData, false);
      }
    }
    if (prevProps.storeDetail.isUpdateSuccess !== this.props.storeDetail.isUpdateSuccess) {
      if (this.props.storeDetail.isUpdateSuccess) {
        this.setDetailSuccess(this.props.storeDetail.resultData, true);
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onClickSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (value) => {
    this.props.dispatch(actResetStoreFlg());
    this.props.dispatch(
      actCallApiDeleteStore({
        deletes: value,
      }),
    );
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isSuccessOpen: false });
  };

  setDeleteSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage: '店舗の削除が完了しました' });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onAdd = () => {
    this.setState({ isDetailOpen: true, selectedRow: null, isSuccessOpen: false });
  };

  onDetail = (_, row) => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, selectedRow: row });
  };

  handleDetailClose = () => {
    this.setState({ isDetailOpen: false });
  };

  setDetailSuccess(data, isGetMe) {
    this.setState({
      isSuccessOpen: true,
      successMessage: `${data.name}の保存が完了しました`,
      isDetailOpen: false,
    });
    const store = this.props.loginUser ? this.props.loginUser.user.store : {};
    if (isGetMe && store.id === data.id) {
      this.props.dispatch(actCallApiMe());
    }
    this.onSearch();
  }

  search = (searchCondition) => {
    this.props.dispatch(
      actCallApiGetStore({
        page: searchCondition.page,
        records: searchCondition.records,
        searchName: searchCondition.searchName,
        sortKey: searchCondition.orderBy,
        sortDirection: searchCondition.order,
      }),
    );
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      searchCondition, datas, isSuccessOpen, successMessage, isDetailOpen, selectedRow,
    } = this.state;

    return (
      <Container>
        <SearchBox onSearch={this.onClickSearch}>
          <Box mt={2} width="60%">
            <FormTitle title="店舗名" />
            <TextField
              fullWidth
              name="searchName"
              variant="outlined"
              autoComplete="off"
              value={searchCondition.searchName}
              onChange={this.onChange}
              inputProps={{
                maxLength: 80,
              }}
            />
          </Box>
        </SearchBox>
        <DeleteDataTable
          procName="店舗"
          rows={datas.content || []}
          headCells={this.headCells}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          handleRequestSort={this.handleRequestSort}
          handleChangePage={this.handleChangePage}
          onAdd={this.onAdd}
          onDetail={this.onDetail}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
        <StoreDetail open={isDetailOpen} data={selectedRow} handleClose={this.handleDetailClose} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  storeList: state.storeList,
  storeDetail: state.storeDetail,
  loginUser: state.loginUser,
});

export default connect(mapStateToProps)(StoreList);
