import { ACTION_TYPE } from './action';

// region Reducer
const useStoreMemberAnalysis = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_USE_STORE_MEMBER_ANALYSIS_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    default:
      return state;
  }
};

export default useStoreMemberAnalysis;
