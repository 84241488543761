// region Type
export const ACTION_TYPE = {
  CALL_API_GET_USE_STORE_MEMBER_ANALYSIS: 'CALL_API_GET_USE_STORE_MEMBER_ANALYSIS',
  CALL_API_GET_USE_STORE_MEMBER_ANALYSIS_SUCCESS: 'CALL_API_GET_USE_STORE_MEMBER_ANALYSIS_SUCCESS',
};
// endregion

// region action
export const actCallApiGetUseStoreMemberAnalysisList = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_USE_STORE_MEMBER_ANALYSIS,
  param,
});

export const actCallApiGetUseStoreMemberAnalysisListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_USE_STORE_MEMBER_ANALYSIS_SUCCESS,
  datas,
});

// endregion
