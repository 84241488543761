import React from 'react';
import {
  TableSortLabel, TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableLabel: {
    verticalAlign: 'baseline',
    width: '100%',
  },
}));

export default function TableHeaderCell(props) {
  const {
    headCell, orderBy, order, handleRequestSort,
  } = props;
  const classes = useStyles();

  const onSortHandle = (event, property) => {
    let newOrder = 'asc';
    let newOrderBy = property;
    if (orderBy === property && order === 'asc') {
      newOrder = 'desc';
    }
    if (orderBy === property && order === 'desc') {
      newOrderBy = '';
    }
    handleRequestSort(event, { order: newOrder, orderBy: newOrderBy });
  };

  return (
    <TableCell
      key={headCell.id}
      align={headCell.numeric ? 'right' : 'left'}
      sortDirection={orderBy === headCell.sortKey ? order : false}
    >
      <TableSortLabel
        active={orderBy === headCell.sortKey}
        direction={orderBy === headCell.sortKey ? order : 'asc'}
        onClick={(event) => onSortHandle(event, headCell.sortKey)}
        className={classes.tableLabel}
      >
        {headCell.label}
        {orderBy === headCell.sortKey ? (
          <span className={classes.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
}
