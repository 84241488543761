// region Type
export const ACTION_TYPE = {
  CALL_API_GET_VISIT_STATUS_DETAIL_SELECT: 'CALL_API_GET_VISIT_STATUS_DETAIL_SELECT',
  CALL_API_GET_VISIT_STATUS_DETAIL_SELECT_SUCCESS: 'CALL_API_GET_VISIT_STATUS_DETAIL_SELECT_SUCCESS',
  CALL_API_GET_VISIT_STATUS_DETAIL: 'CALL_API_GET_VISIT_STATUS_DETAIL',
  CALL_API_GET_VISIT_STATUS_DETAIL_SUCCESS: 'CALL_API_GET_VISIT_STATUS_DETAIL_SUCCESS',
  CALL_API_CREATE_VISIT_STATUS: 'CALL_API_CREATE_VISIT_STATUS',
  CALL_API_CREATE_VISIT_STATUS_SUCCESS: 'CALL_API_CREATE_VISIT_STATUS_SUCCESS',
  CALL_API_UPDATE_VISIT_STATUS: 'CALL_API_UPDATE_VISIT_STATUS',
  CALL_API_UPDATE_VISIT_STATUS_SUCCESS: 'CALL_API_UPDATE_VISIT_STATUS_SUCCESS',
  CALL_API_CHECK_VISIT_STATUS_MEMBER: 'CALL_API_CHECK_VISIT_STATUS_MEMBER',
  CALL_API_CHECK_VISIT_STATUS_MEMBER_SUCCESS: 'CALL_API_CHECK_VISIT_STATUS_MEMBER_SUCCESS',
  RESET_VISIT_STATUS_DETAIL_FLG: 'RESET_VISIT_STATUS_DETAIL_FLG',
};
// endregion

// region action
export const actCallApiGetSelect = () => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL_SELECT,
});

export const actCallApiGetSelectSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL_SELECT_SUCCESS,
  selects,
});

export const actCallApiGetVisitStatusDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL,
  param,
});

export const actCallApiGetVisitStatusDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL_SUCCESS,
  data,
});

export const actCallApiCreateVisitStatusDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_VISIT_STATUS,
  param,
});

export const actCallApiCreateVisitStatusDetailSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CREATE_VISIT_STATUS_SUCCESS,
});

export const actCallApiUpdateVisitStatusDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_VISIT_STATUS,
  param,
});

export const actCallApiUpdateVisitStatusDetailSuccess = () => ({
  type: ACTION_TYPE.CALL_API_UPDATE_VISIT_STATUS_SUCCESS,
});

export const actCallApiCheckVisitStatusMember = (param) => ({
  type: ACTION_TYPE.CALL_API_CHECK_VISIT_STATUS_MEMBER,
  param,
});

export const actCallApiCheckVisitStatusMemberSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CHECK_VISIT_STATUS_MEMBER_SUCCESS,
  data,
});

export const actResetVisitStatusDetailFlg = () => ({
  type: ACTION_TYPE.RESET_VISIT_STATUS_DETAIL_FLG,
});

// endregion
