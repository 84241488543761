// region Type
export const ACTION_TYPE = {
  CALL_API_GET_DAILY_VISIT_STATUS_SELECT: 'CALL_API_GET_DAILY_VISIT_STATUS_SELECT',
  CALL_API_GET_DAILY_VISIT_STATUS_SELECT_SUCCESS: 'CALL_API_GET_DAILY_VISIT_STATUS_SELECT_SUCCESS',
  CALL_API_GET_DAILY_VISIT_STATUS: 'CALL_API_GET_DAILY_VISIT_STATUS',
  CALL_API_GET_DAILY_VISIT_STATUS_SUCCESS: 'CALL_API_GET_DAILY_VISIT_STATUS_SUCCESS',
};
// endregion

// region action
export const actCallApiGetSelectList = () => ({
  type: ACTION_TYPE.CALL_API_GET_DAILY_VISIT_STATUS_SELECT,
});

export const actCallApiGetSelectListSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_GET_DAILY_VISIT_STATUS_SELECT_SUCCESS,
  selects,
});

export const actCallApiGetDailyVisitStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_DAILY_VISIT_STATUS,
  param,
});

export const actCallApiGetDailyVisitStatusSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_DAILY_VISIT_STATUS_SUCCESS,
  datas,
});

// endregion
