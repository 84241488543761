import urls from '../../../constants/urls';

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export function isShowArea(list, targets) {
  if (list.length <= 0) {
    return false;
  }
  let isShow = false;
  for (const key in targets) {
    const target = targets[key];
    if (list.filter((row) => row.url === target).length > 0) {
      isShow = true;
    }
  }
  return isShow;
}

export function getVisitStatusList() {
  return [
    urls.VISIT.DAILY_VISIT_STATUS, urls.VISIT.VISIT_STATUS_LIST,
  ];
}

export function getMemberAnalysisList() {
  return [
    urls.MEMBER_ANALYSIS.STORE_MEMBER_ANALYSIS,
    urls.MEMBER_ANALYSIS.MEMBER_ANALYSIS,
    urls.MEMBER_ANALYSIS.USE_STORE_MEMBER_ANALYSIS,
  ];
}

export function getSettingList() {
  return [
    urls.SETTING.STORE, urls.SETTING.MAIL,
  ];
}

export function getUserSettingList() {
  return [
    urls.USER.SYSTEM_USER,
  ];
}

export function isShowItem(list, target) {
  if (list.length <= 0) {
    return false;
  }
  return list.filter((row) => row.url === target).length > 0;
}
