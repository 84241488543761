import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getSelects, getDetailData, getMemberUpdateData, create, update, checkExist,
} from '../../../services/member/memberDetail.service';
import { create as createMemo, getData as getMemo, update as updateMemo } from '../../../services/member/memoDetail.service';
import { deleteMemos } from '../../../services/member/memoList.service';
import { getAddress } from '../../../services/common/selectList.service';
import { actCallApiServerError } from '../../common/common/action';
import {
  ACTION_TYPE, actCallApiGetMemberDetailSuccess, actCallApiSelectMemberDetailSuccess,
  actCallApiGetUpdateMemberDataSuccess, actCallApiCreateMemberSuccess,
  actCallApiUpdateMemberSuccess, actCallApiCheckExistMemberSuccess,
  actCallApiGetMemberAddressSuccess, actCallApiGetMemberDetailMemoSuccess,
  actCallApiCreateMemberDetailMemoSuccess, actCallApiUpdateMemberDetailMemoSuccess,
  actCallApiDeleteMemberDetailMemoSuccess,
} from './action';

/**
 * 住所取得
 * @param {*} param0
 */
function* callApiGetMemberAddress({ param }) {
  try {
    const datas = yield call(getAddress, param);
    yield put(actCallApiGetMemberAddressSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 選択肢取得
 */
function* callApiSelectMemberDetail() {
  try {
    const selects = yield call(getSelects);
    yield put(actCallApiSelectMemberDetailSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 一件取得処理
 * @param {*} param0
 */
function* callApiGetDetailMember({ param }) {
  try {
    const data = yield call(getDetailData, param);
    yield put(actCallApiGetMemberDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新データ取得
 * @param {*} param0
 */
function* callApiGetUpdateMemberData({ param }) {
  try {
    const data = yield call(getMemberUpdateData, param);
    yield put(actCallApiGetUpdateMemberDataSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 登録
 * @param {*} param0
 */
function* callApiCreateMember({ param }) {
  try {
    const data = yield call(create, param);
    yield put(actCallApiCreateMemberSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新
 * @param {*} param0
 */
function* callApiUpdateMember({ param }) {
  try {
    const data = yield call(update, param);
    yield put(actCallApiUpdateMemberSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員存在チェック
 * @param {*} param0
 */
function* callApiCheckExistMember({ param }) {
  try {
    const data = yield call(checkExist, param);
    yield put(actCallApiCheckExistMemberSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メモ一件取得処理
 * @param {*} param0
 */
function* callApiGetMemberDetailMemo({ param }) {
  try {
    const data = yield call(getMemo, param);
    yield put(actCallApiGetMemberDetailMemoSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メモ登録
 * @param {*} param0
 */
function* callApiCreateMemberDetailMemo({ param }) {
  try {
    const data = yield call(createMemo, param);
    yield put(actCallApiCreateMemberDetailMemoSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メモ更新
 * @param {*} param0
 */
function* callApiUpdateMemberDetailMemo({ param }) {
  try {
    const data = yield call(updateMemo, param);
    yield put(actCallApiUpdateMemberDetailMemoSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メモ削除
 * @param {*} param0
 */
function* callApiDeleteMemberDetailMemo({ param }) {
  try {
    yield call(deleteMemos, param);
    yield put(actCallApiDeleteMemberDetailMemoSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemberDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_ADDRESS, callApiGetMemberAddress);
  yield takeLatest(ACTION_TYPE.CALL_API_SELECT_MEMBER_DETAIL, callApiSelectMemberDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL, callApiGetDetailMember);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_UPDATE_MEMBER_DATA, callApiGetUpdateMemberData);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_MEMBER, callApiCreateMember);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_MEMBER, callApiUpdateMember);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_EXIST_CHECK, callApiCheckExistMember);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_MEMO, callApiGetMemberDetailMemo);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_MEMBER_DETAIL_MEMO, callApiCreateMemberDetailMemo);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL_MEMO, callApiUpdateMemberDetailMemo);
  yield takeLatest(ACTION_TYPE.CALL_API_DELETE_MEMBER_DETAIL_MEMO, callApiDeleteMemberDetailMemo);
}
