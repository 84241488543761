import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getDatas, regist, getDetail,
} from '../../../services/visit/visitRegist.service';
import { getStoreAndUsages } from '../../../services/common/selectList.service';
import { checkExist } from '../../../services/member/memberDetail.service';
import { create } from '../../../services/member/memoDetail.service';
import {
  ACTION_TYPE,
  actCallApiGetVisitRegistSelectsSuccess,
  actCallApiGetVisitRegistListSuccess,
  actCallApiRegistMemberVisitSuccess,
  actCallApiGetVisitRegistDetailSuccess,
  actCallApiCreateMemoVisitRegistSuccess,
  actCallApiGetMemberVisitRegistSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 選択肢取得
 */
function* callApiGetVisitRegistSelects() {
  try {
    const selects = yield call(getStoreAndUsages);
    yield put(actCallApiGetVisitRegistSelectsSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 一覧取得
 * @param {*} param0
 */
function* callApiGetVisitRegist({ param }) {
  try {
    const datas = yield call(getDatas, param);
    yield put(actCallApiGetVisitRegistListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 詳細取得
 * @param {*} param0
 */
function* callApiGetVisitRegistDetail({ param }) {
  try {
    const data = yield call(getDetail, param);
    yield put(actCallApiGetVisitRegistDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 登録
 * @param {*} param0
 */
function* callApiRegistMemberVisit({ param }) {
  try {
    const data = yield call(regist, param);
    yield put(actCallApiRegistMemberVisitSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * メモ登録
 * @param {*} param0
 */
function* callApiCreateMemo({ param }) {
  try {
    yield call(create, param);
    yield put(actCallApiCreateMemoVisitRegistSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員取得
 * @param {*} param0
 */
function* callApiGetMember({ param }) {
  try {
    const data = yield call(checkExist, param);
    yield put(actCallApiGetMemberVisitRegistSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallVisitRegist() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_VISIT_REGIST_SELECTS, callApiGetVisitRegistSelects);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_VISIT_REGIST_LIST, callApiGetVisitRegist);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_VISIT_REGIST_DETAIL, callApiGetVisitRegistDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_REGIST_MEMBER_VISIT, callApiRegistMemberVisit);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_MEMO_VISIT_REGIST, callApiCreateMemo);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_VISIT_REGIST, callApiGetMember);
}
