import { ACTION_TYPE } from './action';

const common = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.TOGGLE_LOADING_SCREEN:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ACTION_TYPE.CALL_API_SERVER_ERROR:
      return {
        ...state,
        errorMessages: action.errorMessages,
        isServerError: true,
      };
    case ACTION_TYPE.CALL_API_RESET_SERVER_ERROR:
      return {
        ...state,
        errorMessages: [],
        isServerError: false,
      };
    case ACTION_TYPE.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };
    default:
      return state;
  }
};

export default common;
