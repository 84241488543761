import React from 'react';
import {
  DialogActions, DialogContent, Box,
} from '@material-ui/core';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import urls from '../../../constants/urls';

export default function LogoutModal(props) {
  const logout = () => {
    window.location.href = `${urls.OTHER.LOGIN}`;
  };

  const {
    open,
    content,
  } = props;

  return (
    <MaterialDialog open={open} onClose={logout}>
      <DialogColorTitle>
        <Box>ログアウトします</Box>
      </DialogColorTitle>
      <DialogContent dividers>
        {content}
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={logout}>閉じる</CancelButton>
      </DialogActions>
    </MaterialDialog>
  );
}
