import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Box, ToggleButtonGroup, ToggleButton,
  TableContainer, Table, TableHead, TableRow,
  TableCell, TableBody, Grid,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import {
  ResponsiveContainer, ComposedChart,
  Line, Bar, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend,
  PieChart, Pie, Cell,
} from 'recharts';
import Item from '../../component/Item';
import ItemTitle from '../../component/ItemTitle';
import {
  getChartTooltipFormat, getChartLegendFormat, getXlineFormat, getSummaryKey,
} from '../../store-proc';
import PieText from '../../component/PieText';
import { actCallApiGetStoreAgeVisitStatus } from '../../../../../redux/visit/storeVisitStatus/action';
import {
  amountKeyTwo, amountKeyThree, amountKeyFour, amountKeyFive, amountKeySix, amountKeyOther,
} from './age-visit-item-proc';

class AgeVisitItem extends React.Component {
  constructor() {
    super();
    this.state = {
      alignment: 'time',
      searchCondition: {},
      displayType: '0',
      datas: {
        datas: [],
        summary: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSearch !== this.props.isSearch) {
      if (this.props.isSearch) {
        this.clearSearch();
      }
    }
    if (prevProps.storeVisitStatus.ageDatas !== this.props.storeVisitStatus.ageDatas) {
      if (this.props.storeVisitStatus.ageDatas) {
        this.setDatas(this.props.storeVisitStatus.ageDatas);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  }

  clearSearch = () => {
    this.props.onSearchClear();
    this.search(this.props.searchCondition, 'time');
    this.setState({
      alignment: 'time',
      searchCondition: this.props.searchCondition,
    });
  }

  onChange = (_, alignment) => {
    if (alignment === null) {
      return;
    }
    const { searchCondition } = this.state;
    this.setState({ alignment });
    this.search(searchCondition, alignment);
  }

  search = (searchCondition, searchType) => {
    const searchMemberGrades = searchCondition.searchMemberGrades.map((n) => n.id);
    this.props.dispatch(actCallApiGetStoreAgeVisitStatus({
      searchMemberGrades,
      searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
      searchStartVisitDate: searchCondition.searchStartVisitDate,
      searchEndVisitDate: searchCondition.searchEndVisitDate,
      searchType,
    }));
  }

  onDisplayChange = (event) => {
    this.setState({ displayType: event.target.value });
  }

  render() {
    const { alignment, datas, displayType } = this.state;

    return (
      <Item>

        <Box display="flex" mb={2}>
          <ItemTitle>年齢別</ItemTitle>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            size="small"
            onChange={this.onChange}
          >
            <ToggleButton value="time">時間</ToggleButton>
            <ToggleButton value="day">日</ToggleButton>
            <ToggleButton value="week">曜日</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">金額表示形式</FormLabel>
            <RadioGroup row value={displayType} onChange={this.onDisplayChange}>
              <FormControlLabel value="0" control={<Radio />} label="合計会計金額(税込)" />
              <FormControlLabel value="1" control={<Radio />} label="合計会計金額(税抜)" />
              <FormControlLabel value="2" control={<Radio />} label="合計値引き額" />
              <FormControlLabel value="3" control={<Radio />} label="合計値引き前金額(税抜)" />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={datas.datas}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />

                <XAxis dataKey="type" scale="band" tickFormatter={(tickItem) => getXlineFormat(tickItem, alignment)} />
                <YAxis orientation="left" unit="人" />
                <YAxis orientation="right" yAxisId="rightYAxis" unit="円" tickFormatter={(item) => item.toLocaleString()} />
                <Tooltip formatter={(value, name) => getChartTooltipFormat(value, name)} />
                <Legend formatter={(value, name) => getChartLegendFormat(value, name)} />
                <Bar dataKey="visitTwo" stackId="visit" barSize={20} fill="#ffd700" />
                <Bar dataKey="visitThree" stackId="visit" barSize={20} fill="#f4a460" />
                <Bar dataKey="visitFour" stackId="visit" barSize={20} fill="#daa520" />
                <Bar dataKey="visitFive" stackId="visit" barSize={20} fill="#a0522d" />
                <Bar dataKey="visitSix" stackId="visit" barSize={20} fill="#800000" />
                <Bar dataKey="visitOther" stackId="visit" barSize={20} fill="#a9a9a9" />

                <Line type="monotone" dataKey={amountKeyTwo(displayType)} stroke="#008b8b" yAxisId="rightYAxis" />
                <Line type="monotone" dataKey={amountKeyThree(displayType)} stroke="#2f4f4f" yAxisId="rightYAxis" />
                <Line type="monotone" dataKey={amountKeyFour(displayType)} stroke="#006400" yAxisId="rightYAxis" />
                <Line type="monotone" dataKey={amountKeyFive(displayType)} stroke="#66cdaa" yAxisId="rightYAxis" />
                <Line type="monotone" dataKey={amountKeySix(displayType)} stroke="#8fbc8f" yAxisId="rightYAxis" />
                <Line type="monotone" dataKey={amountKeyOther(displayType)} stroke="#696969" yAxisId="rightYAxis" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Box>

        <Box mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">来店者</Box>
                <PieChart width={400} height={350}>
                  <Pie
                    data={datas.summary}
                    cx={200}
                    cy={200}
                    labelLine={false}
                    label={PieText}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="visit"
                  >
                    <Cell fill="#ffd700" />
                    <Cell fill="#f4a460" />
                    <Cell fill="#daa520" />
                    <Cell fill="#a0522d" />
                    <Cell fill="#800000" />
                    <Cell fill="#a9a9a9" />
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">会計金額</Box>
                <PieChart width={400} height={350}>
                  <Pie
                    data={datas.summary}
                    cx={200}
                    cy={200}
                    labelLine={false}
                    label={PieText}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey={getSummaryKey(displayType)}
                  >
                    <Cell fill="#ffd700" />
                    <Cell fill="#f4a460" />
                    <Cell fill="#daa520" />
                    <Cell fill="#a0522d" />
                    <Cell fill="#800000" />
                    <Cell fill="#a9a9a9" />
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={5}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>年代</TableCell>
                  <TableCell>来店者数</TableCell>
                  <TableCell>会計金額(税込)</TableCell>
                  <TableCell>会計金額(税抜)</TableCell>
                  <TableCell>値引き額</TableCell>
                  <TableCell>値引き前金額(税抜)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.summary.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.visit.toLocaleString()}</TableCell>
                    <TableCell>{row.includTaxAmount.toLocaleString()}</TableCell>
                    <TableCell>{row.excludTaxAmount.toLocaleString()}</TableCell>
                    <TableCell>{row.discountAmount.toLocaleString()}</TableCell>
                    <TableCell>{row.discountBeforeAmount.toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Item>
    );
  }
}

const mapStateToProps = (state) => ({
  storeVisitStatus: state.storeVisitStatus,
});

export default connect(mapStateToProps)(AgeVisitItem);
