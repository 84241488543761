import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { CSVLink } from 'react-csv';
import {
  DialogActions, DialogContent, CircularProgress, Box,
} from '@material-ui/core';
import {
  Download as DownloadIcon,
} from '@material-ui/icons';
import LoadingButton from '@material-ui/lab/LoadingButton';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import TransferList from '../../../components/templates/transferList/TransferList';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import { getCsvItem } from './member-proc';
import { actCallApiGetCsvMemberData } from '../../../redux/member/memberList/action';

class MemberDownloadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      right: [],
      left: getCsvItem(),
      loading: false,
      isDone: false,
      csvDatas: {
        datas: [],
        headers: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.memberList.csvDatas !== this.props.memberList.csvDatas) {
      if (this.props.memberList.csvDatas) {
        this.setCsvData(this.props.memberList.csvDatas);
      }
    }
  }

  init = () => {
    this.setState({
      right: [],
      left: getCsvItem(),
      loading: false,
      isDone: false,
    });
  }

  setCsvData = (csvDatas) => {
    this.setState({ csvDatas, loading: false, isDone: true });
  }

  onSetValue = (right, left) => {
    this.setState({ right, left });
  }

  onGetData = () => {
    const { left } = this.state;
    const { searchCondition } = this.props;
    this.setState({ loading: true });
    const searchStoreIds = searchCondition.searchStoreIds.map((n) => n.id);
    const searchMemberRankIds = searchCondition.searchMemberRankIds.map((n) => n.id);
    const items = left.map((n) => n.key);
    this.props.dispatch(actCallApiGetCsvMemberData({
      memberNo: searchCondition.memberNo,
      keyword: searchCondition.keyword,
      searchStoreIds,
      birthdayMonth: searchCondition.birthdayMonth !== '0' ? searchCondition.birthdayMonth : '',
      searchMemberRankIds,
      items,
    }));
  }

  render() {
    const {
      open,
      onClose,
    } = this.props;
    const {
      right, left, loading, isDone, csvDatas,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={onClose} maxWidth="md">
        <DialogColorTitle>CSV出力</DialogColorTitle>
        <DialogContent dividers>
          {(!isDone && !loading) && (
            <TransferList
              right={right}
              left={left}
              leftTitle="出力項目"
              rightTitle="非出力項目"
              onSetValue={this.onSetValue}
            />
          )}
          {loading && (
            <Box textAlign="center">
              <CircularProgress color="secondary" />
              <Box mt={3}>出力の準備をしています。このまましばらくお待ちください。</Box>
            </Box>
          )}
          {isDone && (
            <Box>
              <Box mb={3}>出力の準備が整いました。</Box>
              <CSVLink
                data={csvDatas.datas}
                headers={csvDatas.headers}
                filename="member.csv"
                ref={(node) => { this.csvLinkRef = node; }}
              >
                ダウンロードする
              </CSVLink>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>{isDone ? '閉じる' : 'キャンセル' }</CancelButton>
          {!isDone && (
            <LoadingButton
              color="secondary"
              onClick={this.onGetData}
              loading={loading}
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              variant="outlined"
              disabled={left.length === 0}
            >
              出力する
            </LoadingButton>
          )}
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  memberList: state.memberList,
});

export default connect(mapStateToProps)(MemberDownloadModal);
