import React, { Component } from 'react';
import {
  Box, Button, IconButton, Container, Divider, Grid, Tab, Tabs,
  Chip, Toolbar, List, ListItem, ListItemText, Menu, MenuItem,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Grade as GradeIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons/';
import connect from 'react-redux/es/connect/connect';
import urls from '../../../constants/urls';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import { actCallApiGetMemberDetail, actResetMemberDetailFlg, actCallApiDeleteMemberDetailMemo } from '../../../redux/member/memberDetail/action';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import SecondColorText from '../../../components/atoms/secondColorText/SecondColorText';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import MemberUpsertModal from './upsertModal/MemberUpsertModal';
import MemberMemoModal from './MemberMemoModal';
import DeleteConfirmDialog from '../../../components/templates/deleteConfirmDialog/DeleteConfirmDialog';

class MemberDetail extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actSetBreadcrumbs([{ url: urls.MEMBER.MEMBERS, name: '会員一覧' }, { name: '会員詳細' }]));

    let successMessage = '';
    let isSuccessOpen = false;

    if (props.memberDetail.isSetCreateMessage) {
      successMessage = '会員の追加が完了しました';
      isSuccessOpen = true;
      props.dispatch(actResetMemberDetailFlg());
    }

    this.state = {
      historyTabValue: 0,
      successMessage,
      isSuccessOpen,
      isUpdateModalOpen: false,
      isMemoModalOpen: false,
      data: {
        member: {},
        latestVisit: {},
        visitList: [],
        memoList: [],
      },
      memoId: '',
      deleteConfopen: false,
      loading: false,
      anchorMenuEl: null,
    };

    if (!props.location.state) {
      props.history.push({
        pathname: urls.MEMBER.MEMBERS,
      });
    }

    if (props.location.state) {
      this.props.dispatch(actCallApiGetMemberDetail({ id: props.location.state.memberId }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memberDetail.data !== this.props.memberDetail.data) {
      if (this.props.memberDetail.data) {
        this.setData(this.props.memberDetail.data);
      }
    }
    if (prevProps.memberDetail.isDeleteSuccess !== this.props.memberDetail.isDeleteSuccess) {
      if (this.props.memberDetail.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
  }

  setData(data) {
    this.setState({ data });
  }

  tabChange = (_, historyTabValue) => {
    this.setState({ historyTabValue });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  }

  onUpdateModalClose = () => {
    this.setState({ isUpdateModalOpen: false });
  }

  onUpdateModalOpen = () => {
    this.setState({ isUpdateModalOpen: true, isSuccessOpen: false });
  }

  onUpdateModalSuccess = () => {
    const { member } = this.state.data;
    this.setState({ isUpdateModalOpen: false, isSuccessOpen: true, successMessage: '会員の編集が完了しました' });
    this.props.dispatch(actCallApiGetMemberDetail({ id: member.id }));
  }

  onCreateMemoClose = () => {
    this.setState({ isMemoModalOpen: false, memoId: '' });
  }

  onCreateMemoOpen = () => {
    this.setState({ isMemoModalOpen: true, isSuccessOpen: false, anchorMenuEl: null });
  }

  onCreateMemoSuccess = () => {
    const { member } = this.state.data;
    this.setState({
      isMemoModalOpen: false,
      isSuccessOpen: true,
      successMessage: 'メモの保存が完了しました',
      memoId: '',
    });
    this.props.dispatch(actCallApiGetMemberDetail({ id: member.id }));
  }

  onPreDeleteMemo = () => {
    this.setState({ deleteConfopen: true, anchorMenuEl: null, loading: false });
  }

  onDeleteMemo = () => {
    const { memoId } = this.state;
    this.setState({ deleteConfopen: false, loading: true });

    this.props.dispatch(actResetMemberDetailFlg());
    this.props.dispatch(actCallApiDeleteMemberDetailMemo({
      deletes: [memoId],
    }));
    this.setState({ isSuccessOpen: false });
  }

  setDeleteSuccess = () => {
    const { member } = this.state.data;
    this.setState({ isSuccessOpen: true, successMessage: 'メモの削除が完了しました', memoId: '' });
    this.props.dispatch(actCallApiGetMemberDetail({ id: member.id }));
  }

  handleCloseDeleteConfirmDialog = () => {
    this.setState({ deleteConfopen: false, memoId: '' });
  }

  handleClickMenu = (event, memoId) => {
    this.setState({ anchorMenuEl: event.currentTarget, memoId });
  };

  handleCloseMenu = () => {
    this.setState({ anchorMenuEl: null, memoId: '' });
  };

  render() {
    const {
      historyTabValue, data, successMessage, isSuccessOpen, isUpdateModalOpen,
      isMemoModalOpen, memoId, deleteConfopen, loading, anchorMenuEl,
    } = this.state;
    const {
      member, latestVisit, memoList, visitList,
    } = data;

    return (
      <Container>
        <Box flexGrow={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box boxShadow={2} padding={2}>

                <Box textAlign="center">
                  <SecondColorText>{member.memberNameKana}</SecondColorText>
                  <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">{member.memberName}</Box>
                  <Box display="flex" justifyContent="center">
                    <Box>会員番号:</Box>
                    <Box>{member.memberNo}</Box>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Box>ランク:</Box>
                    <Box>{member.rank}</Box>
                  </Box>
                  <Box display={member.grade ? '' : 'none'} mt={1}>
                    <Chip
                      style={{
                        background: member.gradeBackGround,
                        color: member.gradeColor,
                      }}
                      icon={<GradeIcon style={{ color: member.gradeColor }} />}
                      label={member.grade}
                    />
                  </Box>
                </Box>

                <Box border={1} borderColor="grey.500" p={1} mt={2} display={latestVisit.id ? '' : 'none'}>
                  <FormTitle title="前回来店情報" />
                  <Box mt={1}>
                    <FormTitle title="店舗" />
                    <Box>{latestVisit.storeName}</Box>
                  </Box>
                  <Box mt={1}>
                    <FormTitle title="来店日時" />
                    <Box>{latestVisit.visitedAt}</Box>
                  </Box>
                  <Box mt={1}>
                    <FormTitle title="会計金額" />
                    <Box>{latestVisit.money}</Box>
                  </Box>
                  <Box mt={1}>
                    <FormTitle title="来店人数" />
                    <Box>{latestVisit.visitors}</Box>
                  </Box>
                  <Box mt={1}>
                    <FormTitle title="利用タイプ" />
                    <Box>{latestVisit.usageType}</Box>
                  </Box>
                </Box>

              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box boxShadow={2} pb={2}>

                <Box>
                  <Toolbar>
                    <Box flexGrow={1} fontSize="h6.fontSize" fontWeight="fontWeightBold">
                      会員データ
                    </Box>
                    <Button variant="outlined" onClick={this.onUpdateModalOpen} color="secondary" startIcon={<EditIcon />}>編集する</Button>
                  </Toolbar>
                </Box>

                <Divider />

                <Box p={2}>

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Box>
                          <FormTitle title="性別" />
                          <Box>{member.gender}</Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Box>
                          <FormTitle title="登録店舗" />
                          <Box>{member.storeName}</Box>
                        </Box>
                      </Grid>
                    </Grid>

                  </Box>

                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Box>
                          <FormTitle title="電話番号" />
                          <Box>{member.phone}</Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Box>
                          <FormTitle title="生年月日" />
                          <Box>{member.birthday}</Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <FormTitle title="メールアドレス" />
                    <Box>{member.email}</Box>
                  </Box>

                  <Box mt={2}>
                    <FormTitle title="住所" />
                    <Box>{member.zipCode && `〒${member.zipCode}`}</Box>
                    <Box>{member.addressKana}</Box>
                    <Box>{member.address}</Box>
                  </Box>

                  {member.incorrectAddress && <Chip size="small" label="誤住所" color="secondary" />}

                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <Box>
                          <FormTitle title="勤務先" />
                          <Box>{member.workPlaceKana}</Box>
                          <Box>{member.workPlace}</Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Box>
                          <FormTitle title="勤務先電話番号" />
                          <Box>{member.workPlacePhone}</Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <FormTitle title="役職" />
                    <Box>{member.position}</Box>
                  </Box>

                  <Box mt={2}>
                    <FormTitle title="入会日" />
                    <Box>{member.enrolledOn}</Box>
                  </Box>

                </Box>

                <Box p={1}>
                  <Box borderBottom={1} borderColor="divider">
                    <Tabs value={historyTabValue} onChange={this.tabChange}>
                      <Tab label="直近のメモ履歴" />
                      <Tab label="直近の来店履歴" />
                    </Tabs>
                  </Box>

                  <TabPanel value={historyTabValue} index={0}>
                    <Box textAlign="right" mr={1} mt={1}>
                      <Button onClick={this.onCreateMemoOpen} variant="outlined" color="secondary" startIcon={<AddCircleOutlineIcon />}>メモを追加する</Button>
                    </Box>

                    {
                      memoList.length > 0 && (
                        <List sx={{ width: '100%', bgcolor: 'background.paper', paddingLeft: 2 }}>
                          <Divider />
                          {memoList.map((memo) => (
                            <Box key={memo.id} mt={1}>
                              <Box style={{ display: 'flex' }}>
                                <Box style={{ flex: '1 1 50%' }}>{memo.storeName}</Box>
                                <Box>
                                  <IconButton
                                    aria-label="memo menu"
                                    aria-controls="memo-menu"
                                    aria-haspopup="true"
                                    onClick={(e) => this.handleClickMenu(e, memo.id)}
                                    color="inherit"
                                    style={{ padding: '0px' }}
                                  >
                                    <KeyboardArrowDownIcon />
                                  </IconButton>
                                  <Menu
                                    id="memo-menu"
                                    anchorEl={anchorMenuEl}
                                    keepMounted
                                    open={Boolean(anchorMenuEl)}
                                    onClose={this.handleCloseMenu}
                                    elevation={1}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ horizontal: 'right' }}
                                  >
                                    <MenuItem onClick={this.onCreateMemoOpen} variant="outlined" color="primary">編集</MenuItem>
                                    <MenuItem onClick={this.onPreDeleteMemo} color="primary" variant="outlined">削除</MenuItem>
                                  </Menu>
                                </Box>
                              </Box>
                              <SecondColorText>{memo.createdAt}</SecondColorText>
                              <Box my={1}>
                                {memo.content && memo.content.split('\n').map((t, i) => (
                                  t === '' ? <br key={`${t} ${i + 1}`} /> : <Box key={`${t} ${i + 1}`}>{t}</Box>
                                ))}
                              </Box>
                              <Divider />
                            </Box>
                          ))}
                        </List>
                      )
                    }
                    {
                      memoList.length < 1 && (
                        <Box textAlign="center">
                          メモはありません
                        </Box>
                      )
                    }
                  </TabPanel>

                  <TabPanel value={historyTabValue} index={1}>
                    {
                      visitList.length > 0 && (
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          {visitList.map((visit) => (
                            <div key={visit.id}>
                              <ListItem alignItems="flex-start">
                                <ListItemText
                                  primary={visit.storeName}
                                  secondary={(
                                    <>
                                      <Box color="text.primary">
                                        {`${visit.visitedAt} — ${visit.money}`}
                                      </Box>
                                      <Box color="text.primary">
                                        {`${visit.visitors} — ${visit.usageType}`}
                                      </Box>
                                    </>
                                  )}
                                />
                              </ListItem>
                              <Divider />
                            </div>
                          ))}
                        </List>
                      )
                    }
                    {
                      visitList.length < 1 && (
                        <Box textAlign="center" mt={3}>
                          来店履歴はありません
                        </Box>
                      )
                    }
                  </TabPanel>
                </Box>

              </Box>
            </Grid>
          </Grid>
        </Box>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
        <MemberUpsertModal
          memberNo={member.memberNo}
          open={isUpdateModalOpen}
          onClose={this.onUpdateModalClose}
          id={member.id}
          onUpdateSuccess={this.onUpdateModalSuccess}
        />
        <MemberMemoModal
          open={isMemoModalOpen}
          memberId={member.id}
          memoId={memoId}
          onCreateSuccess={this.onCreateMemoSuccess}
          onClose={this.onCreateMemoClose}
        />
        <DeleteConfirmDialog
          open={deleteConfopen}
          onClose={this.handleCloseDeleteConfirmDialog}
          onDelete={this.onDeleteMemo}
          procName="メモ"
          loading={loading}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  memberDetail: state.memberDetail,
});
export default connect(mapStateToProps)(MemberDetail);
