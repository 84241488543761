function getDates() {
  return [
    { key: 'visitNumber', label: '来店総数' },
    { key: 'includTaxAmount', label: '総会計税込金額' },
    { key: 'excludTaxAmount', label: '総会計税抜金額' },
    { key: 'discountAmount', label: '総値引き金額' },
  ];
}
export function getChartTooltipFormat(value, name) {
  const labelMap = getDates();
  const labels = labelMap.filter((data) => data.key === name);
  let label = name;
  if (labels.length > 0) {
    label = labels[0].label;
  }
  return [value.toLocaleString(), label];
}

export function getChartLegendFormat(name) {
  const labelMap = getDates();
  const labels = labelMap.filter((data) => data.key === name);
  let label = name;
  if (labels.length > 0) {
    label = labels[0].label;
  }
  return [label];
}
