import * as React from 'react';
import {
  Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper,
} from '@material-ui/core';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import Pagination from '../../../components/atoms/pagination/Pagination';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

class DailyDataTable extends React.Component {
  constructor() {
    super();
    this.state = {
      order: 'asc',
      orderBy: '',
      page: 0,
      rowsPerPage: 10,
    };
    this.headCells = [
      {
        id: 'storeName', numeric: false, label: '店舗名', sortKey: 'storeName',
      },
      {
        id: 'targetDate', numeric: false, label: '来店日', sortKey: 'targetDate',
      },
      {
        id: 'visitNumber', numeric: false, label: '来店総数', sortKey: 'visitNumber',
      },
      {
        id: 'includTaxAmount', numeric: false, label: '総会計税込金額', sortKey: 'includTaxAmount',
      },
      {
        id: 'excludTaxAmount', numeric: false, label: '総会計税抜金額', sortKey: 'excludTaxAmount',
      },
      {
        id: 'discountAmount', numeric: false, label: '総値引き金額', sortKey: 'discountAmount',
      },
    ];
  }

  handleRequestSort = (_, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property.orderBy && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property.orderBy });
  };

  handleChangePage = (_, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  render() {
    const { rows } = this.props;
    const {
      page, rowsPerPage, order, orderBy,
    } = this.state;

    return (
      <>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {this.headCells.map((headCell) => (
                      <TableHeaderCell
                        headCell={headCell}
                        orderBy={orderBy}
                        order={order}
                        key={headCell.id}
                        handleRequestSort={this.handleRequestSort}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell>{row.storeName}</TableCell>
                        <TableCell>{row.targetDate}</TableCell>
                        <TableCell>{`${row.visitNumber}人`}</TableCell>
                        <TableCell>{`${row.includTaxAmount.toLocaleString()}円`}</TableCell>
                        <TableCell>{`${row.excludTaxAmount.toLocaleString()}円`}</TableCell>
                        <TableCell>{`${row.discountAmount.toLocaleString()}円`}</TableCell>
                      </TableRow>
                    ))}
                  {rows.length <= 0 && (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={6}>来店情報がありません</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              totalElements={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  }
}

export default DailyDataTable;
