// region Type
export const ACTION_TYPE = {
  CALL_API_GET_VISIT_REGIST_SELECTS: 'CALL_API_GET_VISIT_REGIST_SELECTS',
  CALL_API_GET_VISIT_REGIST_SELECTS_SUCCESS: 'CALL_API_GET_VISIT_REGIST_SELECTS_SUCCESS',
  CALL_API_GET_VISIT_REGIST_LIST: 'CALL_API_GET_VISIT_REGIST_LIST',
  CALL_API_GET_VISIT_REGIST_LIST_SUCCESS: 'CALL_API_GET_VISIT_REGIST_LIST_SUCCESS',
  CALL_API_REGIST_MEMBER_VISIT: 'CALL_API_REGIST_MEMBER_VISIT',
  CALL_API_REGIST_MEMBER_VISIT_SUCCESS: 'CALL_API_REGIST_MEMBER_VISIT_SUCCESS',
  CALL_API_GET_VISIT_REGIST_DETAIL: 'CALL_API_GET_VISIT_REGIST_DETAIL',
  CALL_API_GET_VISIT_REGIST_DETAIL_SUCCESS: 'CALL_API_GET_VISIT_REGIST_DETAIL_SUCCESS',
  CALL_API_CREATE_MEMO_VISIT_REGIST: 'CALL_API_CREATE_MEMO_VISIT_REGIST',
  CALL_API_CREATE_MEMO_VISIT_REGIST_SUCCESS: 'CALL_API_CREATE_MEMO_VISIT_REGIST_SUCCESS',
  CALL_API_GET_MEMBER_VISIT_REGIST: 'CALL_API_GET_MEMBER_VISIT_REGIST',
  CALL_API_GET_MEMBER_VISIT_REGIST_SUCCESS: 'CALL_API_GET_MEMBER_VISIT_REGIST_SUCCESS',
  RESET_VISIT_REGIST_FLG: 'RESET_VISIT_REGIST_FLG',
};
// endregion

// region action
export const actCallApiGetVisitRegistSelects = () => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_REGIST_SELECTS,
});

export const actCallApiGetVisitRegistSelectsSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_REGIST_SELECTS_SUCCESS,
  selects,
});

export const actCallApiGetVisitRegistList = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_REGIST_LIST,
  param,
});

export const actCallApiGetVisitRegistListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_REGIST_LIST_SUCCESS,
  datas,
});

export const actCallApiRegistMemberVisit = (param) => ({
  type: ACTION_TYPE.CALL_API_REGIST_MEMBER_VISIT,
  param,
});

export const actCallApiRegistMemberVisitSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_REGIST_MEMBER_VISIT_SUCCESS,
  data,
});

export const actCallApiGetVisitRegistDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_REGIST_DETAIL,
  param,
});

export const actCallApiGetVisitRegistDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_REGIST_DETAIL_SUCCESS,
  data,
});

export const actCallApiCreateMemoVisitRegist = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_MEMO_VISIT_REGIST,
  param,
});

export const actCallApiCreateMemoVisitRegistSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CREATE_MEMO_VISIT_REGIST_SUCCESS,
});

export const actCallApiGetMemberVisitRegist = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_VISIT_REGIST,
  param,
});

export const actCallApiGetMemberVisitRegistSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_VISIT_REGIST_SUCCESS,
  data,
});

export const actVisitRegistFlgReset = () => ({
  type: ACTION_TYPE.RESET_VISIT_REGIST_FLG,
});
// endregion
