import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 来店状況詳細取得
 * @param {*} param
 * @returns
 */
export function getDetails(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.VISIT.VISIT_STATUS_DETAIL.VISIT_STATUS_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 来店状況登録
 * @param {*} param
 * @returns
 */
export function create(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.VISIT.VISIT_STATUS.LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 来店状況更新
 * @param {*} param
 * @returns
 */
export function update(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.VISIT.VISIT_STATUS_DETAIL.VISIT_STATUS_DETAIL, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
