import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Snackbar, IconButton, Accordion, AccordionSummary, AccordionDetails, Box, Alert,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = () => ({
  root: {
    justifyContent: 'center',
  },
  boxRoot: {
    border: '1px solid #f44336',
  },
  alertRoot: {
    borderRadius: '0px',
  },
  accordionRoot: {
    margin: '0px!important',
  },
});

class ServerErrorProc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessages: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.isServerError !== this.props.common.isServerError) {
      if (this.props.common.isServerError) {
        this.setData(this.props.common.errorMessages);
      }
    }
  }

  setData = (errorMessages) => {
    this.setState({ open: true, errorMessages });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const { classes } = this.props;
    const { open, errorMessages } = this.state;

    return (
      <Snackbar
        open={open}
        className={classes.root}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box className={classes.boxRoot}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity="error"
            action={(
              <IconButton aria-label="delete" size="small" onClick={this.handleClose} color="inherit">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
            className={classes.alertRoot}
          >
            <div>問題が発生しました</div>
          </Alert>
          <Accordion square className={classes.accordionRoot}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>次を確認してください</div>
            </AccordionSummary>
            <AccordionDetails>
              {errorMessages ? errorMessages.map((message) => (
                <div key={`err-${message}`}>{message}</div>
              )) : (<div>予期せぬエラーが発生しました</div>)}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Snackbar>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common });

export default withStyles(styles)(connect(mapStateToProps)(ServerErrorProc));
