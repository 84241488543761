import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';

export default function Pagination(props) {
  const {
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props;

  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50, 100]}
      component="div"
      count={totalElements}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      labelRowsPerPage="1ページの表示件数："
      labelDisplayedRows={({ from, to, count }) => `全${count}件中 ${from}件から ${to}件までを表示中`}
    />
  );
}

Pagination.propTypes = {
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  totalElements: 0,
};
