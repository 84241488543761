import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  Container, Paper, TextField, Box, FormControl,
  RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FollowTheSignsIcon from '@material-ui/icons/FollowTheSigns';
import VisitRegistTable from './VisitRegistTable';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import PersonInput from '../../../components/atoms/personInput/PersonInput';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiGetVisitRegistSelects,
  actCallApiGetVisitRegistList,
  actCallApiRegistMemberVisit,
  actVisitRegistFlgReset,
  actCallApiCreateMemoVisitRegist,
  actCallApiGetMemberVisitRegist,
} from '../../../redux/visit/visitRegist/action';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import VisitRegistDetail from './VisitRegistDetail';

const Main = styled(Container, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

class VisitRegist extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actSetBreadcrumbs([{ name: '来店登録' }]));

    this.headCells = [
      { id: 'name', numeric: false, label: '名前' },
      { id: 'authName', numeric: false, label: '権限' },
      { id: 'storeName', numeric: false, label: '店舗' },
    ];
    this.tempMemoId = null;

    props.dispatch(actCallApiGetVisitRegistSelects());

    const searchCondition = { page: 0, records: 10 };
    props.dispatch(actCallApiGetVisitRegistList(searchCondition));

    this.state = {
      datas: [],
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      memberNo: '',
      money: '',
      visitors: '',
      usageType: 1,
      btnLoading: false,
      isDetailOpen: false,
      selectedId: null,
      isNotExistMember: false,
      isChangeNumber: false,
      memberName: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitRegist.datas !== this.props.visitRegist.datas) {
      if (this.props.visitRegist.datas) {
        this.setDatas(this.props.visitRegist.datas);
      }
    }
    if (prevProps.visitRegist.isRegistSuccess
      !== this.props.visitRegist.isRegistSuccess) {
      if (this.props.visitRegist.isRegistSuccess) {
        this.setRegistSuccess(this.props.visitRegist.registData);
      }
    }
    if (prevProps.visitRegist.isCreateMemoSuccess
      !== this.props.visitRegist.isCreateMemoSuccess) {
      if (this.props.visitRegist.isCreateMemoSuccess) {
        this.setCreateMemoSuccess();
      }
    }
    if (prevProps.visitRegist.isGetMemberSuccess
      !== this.props.visitRegist.isGetMemberSuccess) {
      if (this.props.visitRegist.isGetMemberSuccess) {
        this.setMemberData(this.props.visitRegist.member);
      }
    }
  }

  setRegistSuccess(data) {
    this.setState({
      memberNo: '',
      money: '',
      visitors: '',
      usageType: 1,
      isSuccessOpen: true,
      btnLoading: false,
      successMessage: '来店登録が完了しました',
      isDetailOpen: true,
      selectedId: data.id,
      isNotExistMember: false,
      memberName: '',
    });
    const { searchCondition } = this.state;
    this.props.dispatch(actCallApiGetVisitRegistList(searchCondition));
  }

  setMemberData = (member) => {
    let isNotExistMember = true;
    let memberName = '';
    if (member) {
      isNotExistMember = false;
      memberName = `${member.lastName} ${member.firstName}`;
    }
    this.setState({ isNotExistMember, memberName, isChangeNumber: false });
  }

  setCreateMemoSuccess = () => {
    this.setState({
      isSuccessOpen: true,
      successMessage: 'メモの登録が完了しました',
      isDetailOpen: true,
      selectedId: this.tempMemoId,
    });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  onValueChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value, isChangeNumber: name === 'memberNo' });
  }

  onRadioChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: parseInt(value, 10) });
  }

  onClickRegist = () => {
    const {
      memberNo, money, visitors, usageType,
    } = this.state;
    this.setState({ isSuccessOpen: false, btnLoading: true });
    this.props.dispatch(actVisitRegistFlgReset());
    this.props.dispatch(actCallApiRegistMemberVisit({
      memberNo, money, visitors, usageType,
    }));
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.props.dispatch(actCallApiGetVisitRegistList(tempCondition));
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.props.dispatch(actCallApiGetVisitRegistList(tempCondition));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  }

  onDetail = (_, row) => {
    this.setState({ isDetailOpen: true, selectedId: row.id });
  }

  onDetailClose = () => {
    this.setState({ isDetailOpen: false });
  }

  onMemoSave = (_, param) => {
    const { selectedId } = this.state;
    this.tempMemoId = selectedId;
    this.setState({ selectedId: null, isSuccessOpen: false });
    this.props.dispatch(actVisitRegistFlgReset());
    this.props.dispatch(actCallApiCreateMemoVisitRegist(param));
  }

  onGetMemberNo = () => {
    const { memberNo } = this.state;
    if (!memberNo) {
      this.setState({ memberName: '', isNotExistMember: false });
      return;
    }
    this.props.dispatch(actVisitRegistFlgReset());
    this.props.dispatch(actCallApiGetMemberVisitRegist(
      { memberNo },
    ));
  }

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      memberNo,
      money,
      visitors,
      usageType,
      btnLoading,
      isDetailOpen,
      selectedId,
      successMessage,
      isNotExistMember,
      memberName,
      isChangeNumber,
    } = this.state;

    const selects = this.props.visitRegist.selects ? this.props.visitRegist.selects.usages : [];

    return (
      <>
        <Main open={isDetailOpen}>
          <Paper
            component="form"
            sx={{
              p: 2, width: 'fit-content',
            }}
          >
            <Box display="flex">
              <Box>
                <FormTitle title="会員番号" />
                <TextField
                  value={memberNo}
                  onBlur={this.onGetMemberNo}
                  name="memberNo"
                  variant="outlined"
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={this.onValueChange}
                />
                <Box mt={2}>{memberName}</Box>
                {isNotExistMember && (
                  <>
                    <FormErrorText error>この会員番号は登録されていません。</FormErrorText>
                  </>
                )}
              </Box>
              <Box ml={2}>
                <FormTitle title="会計金額(税込)" />
                <MoneyInput
                  inputProps={{
                    maxLength: 13,
                  }}
                  onChange={this.onValueChange}
                  value={money}
                  name="money"
                />
              </Box>
              <Box ml={2}>
                <FormTitle title="来店人数" />
                <PersonInput
                  inputProps={{
                    maxLength: 13,
                  }}
                  onChange={this.onValueChange}
                  value={visitors}
                  name="visitors"
                />
              </Box>
            </Box>
            <Box display="flex" mt={2} alignItems="flex-end">
              <Box>
                <FormTitle title="利用タイプ" />
                <FormControl component="fieldset">
                  <RadioGroup row value={usageType} name="usageType" onChange={this.onRadioChange}>
                    {selects.map((select) => (
                      <FormControlLabel
                        key={select.id}
                        value={select.code}
                        control={<Radio />}
                        label={select.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box ml={2}>
                <LoadingButton
                  color="secondary"
                  onClick={this.onClickRegist}
                  loading={btnLoading}
                  variant="contained"
                  disabled={memberNo === '' || isNotExistMember || isChangeNumber}
                  loadingPosition="start"
                  startIcon={<FollowTheSignsIcon />}
                >
                  来店登録
                </LoadingButton>
              </Box>
            </Box>
          </Paper>

          <VisitRegistTable
            rows={datas.content || []}
            onDetail={this.onDetail}
            totalElements={datas.totalElements}
            rowsPerPage={searchCondition.records}
            page={searchCondition.page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            selectedId={selectedId}
          />
          <SuccessSnackbar
            open={isSuccessOpen}
            handleClose={this.handleSuccessSnackbarClose}
            message={successMessage}
          />
        </Main>
        <VisitRegistDetail
          open={isDetailOpen}
          onClose={this.onDetailClose}
          id={selectedId}
          onMemoSave={this.onMemoSave}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  visitRegist: state.visitRegist,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps)(VisitRegist));
