import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 会員別データ取得
 *
 * @param {*} param
 * @returns
 */
export function getMembers(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.VISIT.VISIT_STATUS.LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 来店状況削除
 * @param {*} param
 * @returns
 */
export function deleteMembers(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.VISIT.VISIT_STATUS.LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
