import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(value) => {
        onChange({
          target: {
            name: props.name,
            value: value.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix="円"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function MoneyInput(props) {
  const {
    value, onChange, error, name,
  } = props;
  return (
    <TextField
      {...props}
      value={value}
      onChange={onChange}
      name={name}
      error={error}
      autoComplete="off"
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
}

MoneyInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

MoneyInput.defaultProps = {
  value: null,
  error: false,
};
