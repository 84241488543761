import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import RequiredChip from '../requiredChip/RequiredChip';

export default function FormTitle(props) {
  const { title, isRequired, helper } = props;

  return (
    <Box fontWeight="fontWeightBold" mb={isRequired || helper ? 1 : 0} display="flex">
      {title}
      {isRequired && (<RequiredChip />)}
      {helper && (
        <Tooltip title={helper}>
          <HelpIcon />
        </Tooltip>
      )}
    </Box>
  );
}

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  helper: PropTypes.string,
};

FormTitle.defaultProps = {
  isRequired: false,
  helper: null,
};
