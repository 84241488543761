import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 店舗を取得する
 *
 * @returns
 */
export function getData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SETTING.STORE.STORE_LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 *
 * @param {*} param
 * @returns
 */
export function deleteStore(param) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .deletes(REST_API.SETTING.STORE.STORE_LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
