import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPE, actCallApiMemberSearchSuccess,
  actCallApiDeleteMemberSuccess, actCallApiGetCsvMemberDataSuccess,
  actCallApiGetStoreMemberListSuccess,
  actCallApiGetSelectListSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import { getStores, getStoresAndMemberRanks } from '../../../services/common/selectList.service';
import { getDatas, deleteMembers, getCsvDatas } from '../../../services/member/memberList.service';

/**
 * 店舗一覧取得
 */
function* callApiGetStore() {
  try {
    const datas = yield call(getStores);
    yield put(actCallApiGetStoreMemberListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

/**
 * 店舗と会員ランク取得
 */
function* callApiGetStoresAndMemberRank() {
  try {
    const datas = yield call(getStoresAndMemberRanks);
    yield put(actCallApiGetSelectListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

/**
 * 検索処理
 *
 * @param {*} param0
 */
function* callApiGetMembers({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiMemberSearchSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

/**
 * 削除処理
 *
 * @param {*} param0
 */
function* callApiDeleteMembers({ param }) {
  try {
    yield call(deleteMembers, param);
    yield put(actCallApiDeleteMemberSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * CSV出力用データ取得
 * @param {*} param0
 */
function* callApiGetCsvMembers({ searchCondition }) {
  try {
    const datas = yield call(getCsvDatas, searchCondition);
    yield put(actCallApiGetCsvMemberDataSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError);
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemberList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE_MEMBER_LIST, callApiGetStore);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_LIST_SELECT, callApiGetStoresAndMemberRank);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_SEARCH, callApiGetMembers);
  yield takeLatest(ACTION_TYPE.CALL_API_DELETE_MEMBER, callApiDeleteMembers);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA, callApiGetCsvMembers);
}
