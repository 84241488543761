import { ACTION_TYPE } from './action';

// region Reducer
const systemUserList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_SELECT_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_SYSTEM_USER_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_SYSTEM_USER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.RESET_SYSTEM_USER_FLG:
      return {
        ...state,
        isDeleteSuccess: false,
      };
    default:
      return state;
  }
};

export default systemUserList;
