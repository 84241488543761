import {
  Box, Container, TextField,
} from '@material-ui/core';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import {
  actCallApiDeleteMemo, actCallApiMemoSearch, actCallApiSelectMemoStores, actResetMemoFlg,
} from '../../../redux/member/memoList/action';
import getAdmin from '../../../helpers/common.helper';
import MemoDetail from '../memoDetail/MemoDetail';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';

class MemoList extends Component {
  constructor(props) {
    super(props);
    props.dispatch(actSetBreadcrumbs([{ name: 'メモ管理' }]));

    props.dispatch(actCallApiSelectMemoStores());

    props.dispatch(actCallApiSelectMemoStores({
      page: 0,
      records: 10,
    }));

    this.headCells = [
      {
        id: 'memberNo', numeric: false, label: '会員番号', sortKey: 'member_no',
      },
      {
        id: 'memberName', numeric: false, label: '名前', sortKey: 'member_name',
      },
      {
        id: 'storeName', numeric: false, label: '登録店舗', sortKey: 'store_name',
      },
      {
        id: 'content', numeric: false, label: 'メモ', sortKey: 'content',
      },
    ];

    this.state = {
      stores: [],

      searchCondition: {
        mstStore: null,
        memberNo: '',
        memberName: '',
        content: '',
        page: 0,
        records: 10,
        order: 'asc',
        orderBy: '',
      },

      datas: [],
      isSuccessOpen: false,
      successMessage: '',
      isDetailOpen: false,
      selectedRow: null,
    };
    this.search(this.state.searchCondition);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memoList.stores !== this.props.memoList.stores) {
      if (this.props.memoList.stores) {
        this.setSelects(this.props.memoList.stores);
      }
    }
    if (prevProps.memoList.datas !== this.props.memoList.datas) {
      if (this.props.memoList.datas) {
        this.setDatas(this.props.memoList.datas);
      }
    }
    if (prevProps.memoList.isDeleteSuccess !== this.props.memoList.isDeleteSuccess) {
      if (this.props.memoList.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
    if (prevProps.memoDetail.isUpdateSuccess !== this.props.memoDetail.isUpdateSuccess) {
      if (this.props.memoDetail.isUpdateSuccess) {
        this.setDetailSuccess();
      }
    }
  }

  onClickSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  setSelects(stores) {
    this.setState({ stores });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setDetailSuccess() {
    this.setState({
      isSuccessOpen: true,
      successMessage: 'メモの保存が完了しました。',
      isDetailOpen: false,
    });
    const { searchCondition } = this.state;
    this.search(searchCondition);
  }

  search = (searchCondition) => {
    this.props.dispatch(
      actCallApiMemoSearch({
        mstStoreId: searchCondition.mstStore && searchCondition.mstStore.id,
        memberNo: searchCondition.memberNo,
        memberName: searchCondition.memberName,
        content: searchCondition.content,
        page: searchCondition.page,
        records: searchCondition.records,
        sortKey: searchCondition.orderBy,
        sortDirection: searchCondition.order,
      }),
    );
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  }

  onDelete = (value) => {
    this.props.dispatch(actResetMemoFlg());
    this.props.dispatch(actCallApiDeleteMemo({
      deletes: value,
    }));
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isSuccessOpen: false });
  }

  setDeleteSuccess = () => {
    this.search(this.state.searchCondition);
    this.setState({ isSuccessOpen: true, successMessage: 'メモの削除が完了しました' });
  }

  onDetail = (_, row) => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, selectedRow: row });
  };

  handleDetailClose = () => {
    this.setState({ isDetailOpen: false });
  }

  render() {
    const {
      stores, searchCondition, datas, isSuccessOpen, successMessage, isDetailOpen, selectedRow,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <Container>
        <SearchBox onSearch={this.onClickSearch}>

          {admin && (
            <Box width={{ md: '30%', xs: '30%' }} mb={2}>
              <FormTitle title="登録店" />
              <SearchSelectBox
                options={stores}
                value={searchCondition.mstStore}
                onChange={this.onChange}
                name="mstStore"
              />
            </Box>
          )}

          <Box display="flex">
            <Box width={{ xs: '30%', lg: '20%' }} mr={2}>
              <FormTitle title="会員番号" />
              <TextField variant="outlined" value={searchCondition.memberNo} onChange={this.onChange} name="memberNo" />
            </Box>
            <Box width={{ xs: '30%', lg: '20%' }}>
              <FormTitle title="名前" />
              <TextField variant="outlined" value={searchCondition.memberName} onChange={this.onChange} name="memberName" />
            </Box>
          </Box>

          <Box mt={2}>
            <FormTitle title="キーワード" />
            <TextField variant="outlined" fullWidth value={searchCondition.content} onChange={this.onChange} name="content" />
          </Box>

        </SearchBox>

        <DeleteDataTable
          rows={datas.content || []}
          headCells={this.headCells}
          procName="メモ"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          onDetail={this.onDetail}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
        <MemoDetail
          open={isDetailOpen}
          data={selectedRow}
          handleClose={this.handleDetailClose}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginUser,
  memoList: state.memoList,
  memoDetail: state.memoDetail,
});

export default connect(mapStateToProps)(MemoList);
