import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Checkbox, Card, CardContent, Grid, Button, Box, FormControlLabel, Chip,
  Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Container,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { actCallApiMe } from '../../../redux/other/login/action';
import {
  actCallApiGetScreens, actCallApiUpdateRoleScreen, actResetRoleDetailFlg, actCallApiGetRoleDetail,
} from '../../../redux/user/roleDetail/action';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import RoleDetailDialog from './RoleDetailDialog';
import urls from '../../../constants/urls';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';

const styles = () => ({
  container: {
    maxHeight: '70vh',
  },
});

class RoleDetail extends React.Component {
  constructor(props) {
    super(props);

    const breadcrumbsText = [
      { url: urls.USER.ROLE, name: '権限' },
      { name: '権限詳細' },
    ];

    props.dispatch(actSetBreadcrumbs(breadcrumbsText));

    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.roleDetail.isRoleCreatedMessage) {
      successSnackOpen = true;
      successSnackMessage = '権限の登録が完了しました';
    }
    props.dispatch(actResetRoleDetailFlg());

    this.state = {
      screens: [],
      roleScreens: [],
      role: {
        id: null,
        store: {
          name: '',
        },
        name: '',
        admin: false,
      },
      updateModalOpen: false,
      successSnackOpen,
      successSnackMessage,
      loading: false,
    };

    if (!props.location.state) {
      props.history.push({
        pathname: urls.USER.ROLES,
      });
      return;
    }
    this.props.dispatch(actCallApiGetScreens());
    this.props.dispatch(actCallApiGetRoleDetail({ id: props.location.state.roleId }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roleDetail.screens !== this.props.roleDetail.screens) {
      if (this.props.roleDetail.screens) {
        this.setScreens(this.props.roleDetail.screens);
      }
    }
    if (prevProps.roleDetail.roleDetail !== this.props.roleDetail.roleDetail) {
      if (this.props.roleDetail.roleDetail) {
        this.setData(this.props.roleDetail.roleDetail);
      }
    }
    if (prevProps.roleDetail.isRoleUpdated !== this.props.roleDetail.isRoleUpdated) {
      if (this.props.roleDetail.isRoleUpdated) {
        this.setUpdateSuccess(this.props.roleDetail.role);
      }
    }
    if (prevProps.roleDetail.isRoleScreenUpdate !== this.props.roleDetail.isRoleScreenUpdate) {
      if (this.props.roleDetail.isRoleScreenUpdate) {
        this.setScreenUpdateSuccess(this.props.roleDetail.roleScreens);
      }
    }
  }

  setScreens(screens) {
    this.setState({ screens });
  }

  setData(roleDetail) {
    this.setState({ roleScreens: roleDetail.roleScreens, role: roleDetail.role });
  }

  onUpdate = () => {
    this.props.dispatch(actResetRoleDetailFlg());
    this.setState({ updateModalOpen: true, successSnackOpen: false });
  }

  handleSelectAllClick = () => {
    const { screens, roleScreens } = this.state;
    let selectedData = [];
    if (roleScreens.length !== screens.length) {
      selectedData = screens.map((scr) => scr.id);
    }
    this.setState({ roleScreens: selectedData });
  };

  handleClick = (_, screenId) => {
    const { roleScreens } = this.state;
    const selectedIndex = roleScreens.indexOf(screenId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(roleScreens, screenId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(roleScreens.slice(1));
    } else if (selectedIndex === roleScreens.length - 1) {
      newSelected = newSelected.concat(roleScreens.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        roleScreens.slice(0, selectedIndex),
        roleScreens.slice(selectedIndex + 1),
      );
    }

    this.setState({ roleScreens: newSelected });
  };

  updateRoleScreen = () => {
    this.setState({ successSnackOpen: false, loading: true });
    const { roleScreens, role } = this.state;
    this.props.dispatch(actResetRoleDetailFlg());
    this.props.dispatch(actCallApiUpdateRoleScreen({ id: role.id, screenIds: roleScreens }));
  }

  setScreenUpdateSuccess = (roleScreens) => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '使用可能画面の保存が完了しました',
      roleScreens,
      loading: false,
    });
  }

  handleDetailClose = () => {
    this.setState({ updateModalOpen: false });
  }

  setUpdateSuccess = (role) => {
    const loginUser = this.props.loginUser ? this.props.loginUser.user.user : {};
    const isSameUser = loginUser.roleId === role.id;
    if (isSameUser) {
      this.props.dispatch(actCallApiMe());
    }
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '権限の変更が完了しました',
      updateModalOpen: false,
      role,
    });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  }

  isSelected = (screenId) => this.state.roleScreens.includes(screenId);

  render() {
    const { classes } = this.props;

    const {
      screens, updateModalOpen, role,
      successSnackOpen, successSnackMessage, roleScreens, loading,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Card>
              <CardContent>
                <Box display={role.admin ? '' : 'none'} mb={2}>
                  <Chip label="システム管理者" />
                </Box>
                <Box mb={2}>
                  <FormTitle title="店舗" />
                  <Box>{role.store.name}</Box>
                </Box>
                <Box>
                  <FormTitle title="権限名" />
                  <Box>{role.name}</Box>
                </Box>
              </CardContent>
              <Box borderTop={1} borderColor="#eeeeee" />
              <Box textAlign="right" my={2} mr={2}>
                <Button color="secondary" variant="outlined" onClick={this.onUpdate} startIcon={<CreateIcon />}>権限名の変更</Button>
              </Box>
            </Card>
          </Grid>

          <Grid item sm={8} xs={12}>
            <Paper>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span>
                          <Button variant="outlined" onClick={this.handleSelectAllClick}>
                            {roleScreens.length === screens.length ? ('全解除') : ('全選択')}
                          </Button>
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {screens.map((screen) => {
                      const isSelected = this.isSelected(screen.id);
                      return (
                        <TableRow
                          key={`box-check-${screen.id}`}
                          hover
                          aria-checked={isSelected}
                          selected={isSelected}
                        >
                          <TableCell>
                            <Box>
                              <FormControlLabel
                                control={<Checkbox checked={isSelected} color="primary" onClick={(event) => this.handleClick(event, screen.id)} />}
                                label={screen.name}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box textAlign="right" p={1}>
                <SaveButton loading={loading} onClick={this.updateRoleScreen}>保存</SaveButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={successSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successSnackMessage}
        />

        <RoleDetailDialog
          open={updateModalOpen}
          handleClose={this.handleDetailClose}
          id={role.id}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  roleDetail: state.roleDetail,
  loginUser: state.loginUser,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(RoleDetail)));
