import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import {
  DialogActions, DialogContent, CircularProgress, Box,
} from '@material-ui/core';
import {
  Download as DownloadIcon,
} from '@material-ui/icons';
import LoadingButton from '@material-ui/lab/LoadingButton';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import TransferList from '../../../components/templates/transferList/TransferList';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';

class AnalyticsDownloadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      right: [],
      left: [],
      loading: false,
      isDone: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  init = () => {
    this.setState({
      right: [],
      left: this.props.items,
      loading: false,
      isDone: false,
    });
  }

  onSetValue = (right, left) => {
    this.setState({ right, left });
  }

  onGetData = () => {
    this.setState({ isDone: true });
  }

  render() {
    const {
      open, onClose, datas,
    } = this.props;
    const {
      right, left, loading, isDone,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={onClose} maxWidth="md">
        <DialogColorTitle>CSV出力</DialogColorTitle>
        <DialogContent dividers>
          {(!isDone && !loading) && (
            <TransferList
              right={right}
              left={left}
              leftTitle="出力項目"
              rightTitle="非出力項目"
              onSetValue={this.onSetValue}
            />
          )}
          {loading && (
            <Box textAlign="center">
              <CircularProgress color="secondary" />
              <Box mt={3}>出力の準備をしています。このまましばらくお待ちください。</Box>
            </Box>
          )}
          {isDone && (
            <Box>
              <Box mb={3}>出力の準備が整いました。</Box>
              <CSVLink
                data={datas}
                headers={left}
                filename="分析.csv"
                ref={(node) => { this.csvLinkRef = node; }}
              >
                ダウンロードする
              </CSVLink>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>{isDone ? '閉じる' : 'キャンセル' }</CancelButton>
          {!isDone && (
            <LoadingButton
              color="secondary"
              onClick={this.onGetData}
              loading={loading}
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              variant="outlined"
              disabled={left.length === 0}
            >
              出力する
            </LoadingButton>
          )}
        </DialogActions>
      </MaterialDialog>
    );
  }
}

export default AnalyticsDownloadModal;
