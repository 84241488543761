import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 選択用画面一覧取得
 *
 * @returns
 */
export function getScreens() {
  const httpConnection = new HttpConnection();
  return httpConnection
    .get(REST_API.USER.ROLE.SCREENS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限取得
 *
 * @param {*} param
 * @returns
 */
export function getRoles(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.USER.ROLE.ROLE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限と権限画面取得
 *
 * @param {*} param
 * @returns
 */
export function getRoleDetails(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.USER.ROLE.ROLE_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限追加
 *
 * @param {*} param
 * @returns
 */
export function insertData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER.ROLE.ROLES, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限画面更新
 *
 * @param {*} param
 * @returns
 */
export function updateRoleScreen(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.USER.ROLE.ROLE_SCREENS, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 権限更新
 *
 * @param {*} param
 * @returns
 */
export function updateData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.USER.ROLE.ROLE, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
