const urls = {
  OTHER: {
    HOME: '/',
    LOGIN: '/login',
    SYSTEM_ERROR: '/error',
  },

  MEMBER: {
    MEMBERS: '/members',
    MEMBER_DETAIL: '/members/detail',
    MEMO: '/memos',
  },

  VISIT: {
    VISIT_REGIST: '/visit-regists',
    STORE_VISIT_STATUS: '/store-visit-statuses',
    DAILY_VISIT_STATUS: '/daily-visit-statuses',
    VISIT_STATUS_LIST: '/visit-statuses',
  },

  MEMBER_ANALYSIS: {
    STORE_MEMBER_ANALYSIS: '/store-member-analysises',
    MEMBER_ANALYSIS: '/member-analysises',
    USE_STORE_MEMBER_ANALYSIS: '/use-store-member-analysises',
  },

  SETTING: {
    STORE: '/stores',
  },

  USER: {
    ROLE: '/roles',
    ROLE_DETAIL: '/roles/detail',
    SYSTEM_USER: '/system-users',
  },
};
export default urls;
