import { call, put, takeLatest } from 'redux-saga/effects';
import { getStoreAndGrades } from '../../../services/common/selectList.service';
import {
  getAllSummary, getTaxSummary, getAgeSummary, getGenderSummary,
} from '../../../services/visit/storeVisitStatus.service';
import {
  ACTION_TYPE,
  actCallApiGetSelectListSuccess,
  actCallApiGetStoreAllVisitStatusSuccess,
  actCallApiGetStoreTaxVisitStatusSuccess,
  actCallApiGetStoreAgeVisitStatusSuccess,
  actCallApiGetStoreGenderVisitStatusSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 店舗と会員グレード取得
 * @param {*} param0
 */
function* callApiGetStoreAndGrade() {
  try {
    const selects = yield call(getStoreAndGrades);
    yield put(actCallApiGetSelectListSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 全体サマリを取得
 * @param {*} param0
 */
function* callApiGetAllSummary({ param }) {
  try {
    const datas = yield call(getAllSummary, param);
    yield put(actCallApiGetStoreAllVisitStatusSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 税別サマリー取得
 * @param {*} param0
 */
function* callApiGetTaxSummary({ param }) {
  try {
    const datas = yield call(getTaxSummary, param);
    yield put(actCallApiGetStoreTaxVisitStatusSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 年齢サマリを取得
 * @param {*} param0
 */
function* callApiGetAgeSummary({ param }) {
  try {
    const datas = yield call(getAgeSummary, param);
    yield put(actCallApiGetStoreAgeVisitStatusSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 性別サマリを取得
 * @param {*} param0
 */
function* callApiGetGenderSummary({ param }) {
  try {
    const datas = yield call(getGenderSummary, param);
    yield put(actCallApiGetStoreGenderVisitStatusSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallStoreVisitStatus() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE_VISIT_STATUS_SELECT, callApiGetStoreAndGrade);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE_ALL_VISIT_STATUS, callApiGetAllSummary);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE_TAX_VISIT_STATUS, callApiGetTaxSummary);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE_AGE_VISIT_STATUS, callApiGetAgeSummary);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE_GENDER_VISIT_STATUS, callApiGetGenderSummary);
}
