import {
  Box, DialogActions, DialogContent, LinearProgress,
} from '@material-ui/core';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import TextArea from '../../../components/atoms/textArea/TextArea';
import { actCallApiGetMemoDetail, actCallApiUpdateMemo, actResetMemoDetailFlg } from '../../../redux/member/memoDetail/action';
import Validation from './validation';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';

class MemoDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      btnLoading: false,
      info: {
        memberName: '',
        storeName: '',
        content: '',
      },
      message: {
        errorMessage: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.memoDetail.data !== this.props.memoDetail.data) {
      if (this.props.memoDetail.data) {
        this.setData(this.props.memoDetail.data);
      }
    }
  }

  setData(info) {
    this.setState({
      info,
      loading: false,
      btnLoading: false,
    });
  }

  handleSave = () => {
    const { info } = this.state;
    let isError = false;
    const msg = Validation.formValidate(info.content);
    if (msg)isError = true;
    this.setState({ message: { errorMessage: msg }, btnLoading: !isError });
    if (isError) return;
    const param = {
      id: info.id,
      content: info.content,
    };
    this.props.dispatch(actResetMemoDetailFlg());
    this.props.dispatch(actCallApiUpdateMemo(param));
  }

  handleChange = (event) => {
    const { value } = event.target;
    const { info, message } = this.state;
    this.setState({
      info: { ...info, content: value },
      message: { ...message, errorMessage: Validation.formValidate(value) },
    });
  }

  init() {
    const { data } = this.props;
    this.props.dispatch(actCallApiGetMemoDetail({ id: data.id }));
    this.setState({
      info: {
        memberName: '',
        storeName: '',
        content: '',
      },
      loading: true,
      btnLoading: true,
      message: {
        errorMessage: '',
      },

    });
  }

  render() {
    const { open, handleClose } = this.props;
    const {
      loading, btnLoading, info, message,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={handleClose}>
        <DialogColorTitle>
          <Box>次の会員のメモを編集</Box>
          <Box>{info.memberName}</Box>
        </DialogColorTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>

          <Box>
            <FormTitle title="登録店舗" />
            {info.storeName}
          </Box>
          <Box mt={2}>
            <FormTitle title="メモ" isRequired />
            <TextArea name="content" onChange={this.handleChange} value={info.content} />
            <FormErrorText error>{message.errorMessage}</FormErrorText>
          </Box>

        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose}>キャンセル</CancelButton>
          <SaveButton loading={btnLoading} onClick={this.handleSave}>保存</SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  memoDetail: state.memoDetail,
});
export default connect(mapStateToProps)(MemoDetail);
