import React from 'react';
import PropTypes from 'prop-types';
import { TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    padding: '16.5px 14px',
    fontSize: '1rem',
    minWidth: '100%',
    color: 'rgba(0, 0, 0, 0.87)',
    borderRadius: '4px',
    minHeight: '80px',
    maxWidth: '100%',
  },
}));

export default function TextArea(props) {
  const classes = useStyles();
  const {
    name, value, onChange,
  } = props;

  return (
    <TextareaAutosize
      name={name}
      className={classes.root}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextArea.defaultProps = {
  value: null,
};
