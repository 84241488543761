import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 全体サマリー取得
 * @param {*} searchCondition
 * @returns
 */
export function getAllSummary(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.VISIT.VISIT_STATUS_STORE.ALL, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 税別サマリー取得
 * @param {*} searchCondition
 * @returns
 */
export function getTaxSummary(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.VISIT.VISIT_STATUS_STORE.TAX, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 性別サマリー取得
 * @param {*} searchCondition
 * @returns
 */
export function getGenderSummary(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.VISIT.VISIT_STATUS_STORE.GENDER, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 年齢別サマリー取得
 * @param {*} searchCondition
 * @returns
 */
export function getAgeSummary(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.VISIT.VISIT_STATUS_STORE.AGE, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
