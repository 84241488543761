import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 作成処理
 * @param {*} param
 * @returns
 */
export function createStore(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.SETTING.STORE.STORE_LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 一件取得処理
 *
 * @param {*} param
 * @returns
 */
export function getData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.SETTING.STORE.STORE_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 更新処理
 *
 * @param {*} param
 * @returns
 */
export function updateStore(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.SETTING.STORE.STORE_DETAIL, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
