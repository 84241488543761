import * as React from 'react';
import {
  Box, Table, TableBody, TableCell, TableContainer, Button,
  TableHead, TableRow, Paper, Toolbar, Typography, Tooltip,
} from '@material-ui/core';
import { Download as DownloadIcon } from '@material-ui/icons';
import AnalyticsDownloadModal from '../memberAnalysises/AnalyticsDownloadModal';

export default function UseStoreMemberAnalysisesTable(props) {
  const { rows } = props;
  const [open, setOpen] = React.useState(false);

  const getCsvItem = [
    { label: '会員番号', key: 'memberNo' },
    { label: '姓', key: 'memberLastName' },
    { label: '名', key: 'memberFirstName' },
    { label: '姓フリガナ', key: 'memberLastNameKana' },
    { label: '名フリガナ', key: 'memberFirstNameKana' },
    { label: '郵便番号', key: 'zipCode' },
    { label: '都道府県', key: 'prefecture' },
    { label: '市区町村', key: 'city' },
    { label: '町名・番地・部屋番号', key: 'street' },
    { label: '都道府県フリガナ', key: 'prefectureKana' },
    { label: '市区町村フリガナ', key: 'cityKana' },
    { label: '町名・番地・部屋番号フリガナ', key: 'streetKana' },
    { label: '利用店舗', key: 'storesName' },
    { label: '利用金額', key: 'excludTaxAmount' },
    { label: '利用店舗数', key: 'storeCount' },
  ];

  const onDownload = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box>
        <Paper>
          <Toolbar>
            <Typography style={{ flex: '1 1 50%' }} color="inherit" variant="subtitle1" component="div" />
            <Tooltip title="CSVの出力">
              <Button color="secondary" variant="outlined" onClick={onDownload} startIcon={<DownloadIcon />}>CSVの出力</Button>
            </Tooltip>
          </Toolbar>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>会員番号</TableCell>
                  <TableCell>会員名</TableCell>
                  <TableCell>住所</TableCell>
                  <TableCell width="40%">利用店舗</TableCell>
                  <TableCell>利用金額</TableCell>
                  <TableCell>利用店舗数</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover tabIndex={-1} key={row.memberNo}>
                    <TableCell>{row.memberNo}</TableCell>
                    <TableCell>
                      <Box>{`${row.memberLastNameKana} ${row.memberFirstNameKana}`}</Box>
                      <Box>{`${row.memberLastName} ${row.memberFirstName}`}</Box>
                    </TableCell>
                    <TableCell key="memberAddress">
                      <Box display={row.zipCode ? '' : 'none'}>{`〒${row.zipCode}`}</Box>
                      <Box>{`${row.prefectureKana}${row.cityKana}${row.streetKana}`}</Box>
                      <Box>{`${row.prefecture}${row.city}${row.street}`}</Box>
                    </TableCell>
                    <TableCell>{row.storesName}</TableCell>
                    <TableCell>{`${row.excludTaxAmount.toLocaleString()}円`}</TableCell>
                    <TableCell>{row.storeCount}</TableCell>
                  </TableRow>
                ))}
                {rows.length <= 0 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={4}>会員情報がありません</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <AnalyticsDownloadModal
        open={open}
        onClose={onClose}
        datas={rows}
        items={getCsvItem}
      />
    </>
  );
}
