import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  TextField, Typography,
  DialogActions, DialogContent,
  LinearProgress, Box, Alert,
} from '@material-ui/core';
import {
  actCallApiGetSystemUser, actCallApiSystemUserCreate,
  actCallApiSystemUserLoginIdCheck, actResetSystemUserDetailFlg,
  actCallApiSystemUserUpdate,
} from '../../../redux/user/systemUserDetail/action';
import getAdmin from '../../../helpers/common.helper';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import Validation from './validation';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';

class SystemUserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnLoading: false,
      loading: true,
      isShowLogoutMessage: false,
      titleName: '',
      info: {
        id: null,
        store: null,
        lastName: '',
        firstName: '',
        loginId: '',
        password: '',
        role: null,
      },
      message: {
        store: '',
        lastName: '',
        firstName: '',
        loginId: '',
        password: '',
        role: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.systemUserDetail.data !== this.props.systemUserDetail.data) {
      if (this.props.systemUserDetail.data) {
        this.setData(this.props.systemUserDetail.data);
      }
    }
    if (prevProps.systemUserDetail.isCheckSuccess
      !== this.props.systemUserDetail.isCheckSuccess) {
      if (this.props.systemUserDetail.isCheckSuccess) {
        this.checkSuccess(this.props.systemUserDetail.result);
      }
    }
  }

  setData(data) {
    const { user } = this.props.loginUser;
    let isShowLogoutMessage = false;
    if (user) {
      isShowLogoutMessage = user.user.id === data.id;
    }

    this.setState({
      info: {
        ...data,
        password: '',
      },
      titleName: `${data.lastName} ${data.firstName}`,
      loading: false,
      btnLoading: false,
      isShowLogoutMessage,
    });
  }

  checkSuccess = (result) => {
    const { message } = this.state;
    let errorMessage = '';
    if (result.exist) {
      errorMessage = '既に使用されているログインIDです';
    }
    this.setState({ message: { ...message, loginId: errorMessage } });
  }

  handleSave = () => {
    const { info, message } = this.state;

    let tempMessage = message;
    let isError = false;

    Object.keys(info).filter((key) => key !== 'loginId').forEach((key) => {
      if (info.id && key === 'password') {
        return;
      }
      const msg = Validation.formValidate(key, info[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ message: tempMessage, btnLoading: !isError });

    if (isError) {
      return;
    }

    const param = {
      id: info.id,
      storeId: info.store.id,
      lastName: info.lastName,
      firstName: info.firstName,
      loginId: info.loginId,
      password: info.password,
      roleId: info.role.id,
    };

    this.props.dispatch(actResetSystemUserDetailFlg());
    if (info.id) {
      this.props.dispatch(actCallApiSystemUserUpdate(param));
    } else {
      this.props.dispatch(actCallApiSystemUserCreate(param));
    }
  }

  handleChange = (event) => {
    const { value, name } = event.target;

    const { info, message } = this.state;
    this.setState({
      info: { ...info, [name]: value },
      message: { ...message, [name]: Validation.formValidate(name, value) },
    });
  }

  loginCheck = () => {
    const { message, info } = this.state;

    if (message.loginId || !info.loginId) {
      return;
    }

    this.props.dispatch(actResetSystemUserDetailFlg());
    this.props.dispatch(actCallApiSystemUserLoginIdCheck({ id: info.id, loginId: info.loginId }));
  }

  init() {
    const { data } = this.props;
    const { user } = this.props.loginUser;

    let store = {};
    if (user) {
      store = user.store;
    }

    const info = {
      id: null,
      store,
      lastName: '',
      firstName: '',
      loginId: '',
      password: '',
      role: null,
    };

    const message = {
      store: '',
      lastName: '',
      firstName: '',
      loginId: '',
      password: '',
      role: '',
    };

    let loading = false;
    let btnLoading = false;
    if (data && data.id) {
      this.props.dispatch(actCallApiGetSystemUser({ id: data.id }));
      loading = true;
      btnLoading = true;
    }

    this.setState({
      loading,
      info,
      message,
      btnLoading,
      isShowLogoutMessage: false,
    });
  }

  render() {
    const {
      open,
      handleClose,
    } = this.props;

    const {
      selects,
    } = this.props.systemUserList;

    const stores = selects ? selects.stores : [];
    const roles = selects ? selects.roles : [];
    const admin = getAdmin(this.props.loginUser);

    const {
      info,
      message,
      loading,
      btnLoading,
      titleName,
      isShowLogoutMessage,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={handleClose}>
        <DialogColorTitle>
          <Box display={info.id ? 'none' : ''}>システムユーザーの追加</Box>
          <Box display={info.id ? '' : 'none'}>
            <Box>次のシステムユーザーの変更</Box>
            <Box>
              {titleName}
            </Box>
          </Box>
        </DialogColorTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          {admin && (
            <Box mb={2}>
              <FormTitle title="店舗" isRequired />
              <SearchSelectBox error={message.store !== ''} options={stores} value={info.store} onChange={this.handleChange} name="store" disableClearable />
              <FormErrorText error>{message.store}</FormErrorText>
            </Box>
          )}
          <Box display="flex" mb={2}>
            <Box>
              <FormTitle title="姓" isRequired />
              <TextField
                name="lastName"
                variant="outlined"
                value={info.lastName}
                onChange={this.handleChange}
                error={message.lastName !== ''}
                inputProps={{
                  maxLength: 80,
                }}
              />
              <FormErrorText error>{message.lastName}</FormErrorText>
            </Box>
            <Box ml={2}>
              <FormTitle title="名" isRequired />
              <TextField
                name="firstName"
                variant="outlined"
                value={info.firstName}
                onChange={this.handleChange}
                error={message.firstName !== ''}
                inputProps={{
                  maxLength: 40,
                }}
              />
              <FormErrorText error>{message.firstName}</FormErrorText>
            </Box>
          </Box>
          <Box mb={2}>
            <FormTitle title="ログインID" isRequired />
            <TextField
              name="loginId"
              variant="outlined"
              value={info.loginId}
              onChange={this.handleChange}
              fullWidth
              error={message.loginId !== ''}
              onBlur={this.loginCheck}
              inputProps={{
                maxLength: 80,
              }}
            />
            <FormErrorText error>{message.loginId}</FormErrorText>
            <Box mt={1} display={isShowLogoutMessage ? '' : 'none'}>
              <Alert severity="warning">ログインIDを変更すると自動でログアウトします</Alert>
            </Box>
          </Box>
          <Box>
            <FormTitle title="権限" isRequired />
            <SearchSelectBox error={message.role !== ''} options={info.store ? roles.filter((data) => data.mstStoreId === info.store.id) : roles} value={info.role} onChange={this.handleChange} name="role" disableClearable />
            <FormErrorText error>{message.role}</FormErrorText>
          </Box>
          <Box mt={2} display={info.id ? 'none' : ''}>
            <FormTitle title="パスワード" isRequired />
            <PasswordField
              value={info.password}
              onChange={this.handleChange}
              name="password"
              isError={message.password !== ''}
            />
            <FormErrorText error>{message.password}</FormErrorText>
            <Typography variant="caption" display="block" gutterBottom>8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose}>キャンセル</CancelButton>
          <SaveButton loading={btnLoading} onClick={this.handleSave}>保存</SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  systemUserList: state.systemUserList,
  systemUserDetail: state.systemUserDetail,
  loginUser: state.loginUser,
});

export default connect(mapStateToProps)(SystemUserDetail);
