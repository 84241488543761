import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  DialogActions, DialogContent, Box,
} from '@material-ui/core';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import TextArea from '../../../components/atoms/textArea/TextArea';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';

class VisitRegistMemoDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memo: '',
      errorMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  handleSave = (event) => {
    const { memo } = this.state;
    const { memberId, onMemoSave, onClose } = this.props;

    const errorMessage = this.memoCheck(memo);

    this.setState({ errorMessage });

    if (errorMessage) {
      return;
    }
    onMemoSave(event, { memberId, memo });
    onClose();
  }

  memoCheck = (value) => {
    if (!value) {
      return '入力してください';
    }
    return '';
  }

  handleChange = (event) => {
    const { value } = event.target;

    this.setState({ memo: value, errorMessage: this.memoCheck(value) });
  }

  init() {
    this.setState({ memo: '', errorMessage: '' });
  }

  render() {
    const {
      open,
      onClose,
      memberName,
    } = this.props;

    const {
      memo,
      errorMessage,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={onClose}>
        <DialogColorTitle>
          <Box>次の会員にメモを登録</Box>
          <Box>
            {memberName}
          </Box>
        </DialogColorTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <FormTitle title="メモ" isRequired />
            <TextArea
              value={memo}
              onChange={this.handleChange}
              name="memo"
            />
            <FormErrorText error>{errorMessage}</FormErrorText>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>キャンセル</CancelButton>
          <SaveButton onClick={this.handleSave}>保存する</SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  visitRegist: state.visitRegist,
});

export default connect(mapStateToProps)(VisitRegistMemoDetail);
