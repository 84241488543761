export const ACTION_TYPE = {
  CALL_API_GET_STORE_MEMBER_LIST: 'CALL_API_GET_STORE_MEMBER_LIST',
  CALL_API_GET_STORE_MEMBER_LIST_SUCCESS: 'CALL_API_GET_STORE_MEMBER_LIST_SUCCESS',
  CALL_API_GET_MEMBER_LIST_SELECT: 'CALL_API_GET_MEMBER_LIST_SELECT',
  CALL_API_GET_MEMBER_LIST_SELECT_SUCCESS: 'CALL_API_GET_MEMBER_LIST_SELECT_SUCCESS',
  CALL_API_MEMBER_SEARCH: 'CALL_API_MEMBER_SEARCH',
  CALL_API_MEMBER_SEARCH_SUCCESS: 'CALL_API_MEMBER_SEARCH_SUCCESS',
  CALL_API_DELETE_MEMBER: 'CALL_API_DELETE_MEMBER',
  CALL_API_DELETE_MEMBER_SUCCESS: 'CALL_API_DELETE_MEMBER_SUCCESS',
  CALL_API_GET_CSV_MEMBER_DATA: 'CALL_API_GET_CSV_MEMBER_DATA',
  CALL_API_GET_CSV_MEMBER_DATA_SUCCESS: 'CALL_API_GET_CSV_MEMBER_DATA_SUCCESS',
  RESET_MEMBER_LIST_FLG: 'RESET_MEMBER_LIST_FLG',
  SET_MEMBER_LIST_CONDITION: 'SET_MEMBER_LIST_CONDITION',
};

export const actCallApiGetStoreMemberList = () => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_MEMBER_LIST,
});

export const actCallApiGetStoreMemberListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_MEMBER_LIST_SUCCESS,
  datas,
});

export const actCallApiGetSelectList = () => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_LIST_SELECT,
});

export const actCallApiGetSelectListSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_LIST_SELECT_SUCCESS,
  selects,
});

export const actCallApiMemberSearch = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_SEARCH,
  searchCondition,
});

export const actCallApiMemberSearchSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_SEARCH_SUCCESS,
  datas,
});

export const actCallApiDeleteMember = (param) => ({
  type: ACTION_TYPE.CALL_API_DELETE_MEMBER,
  param,
});

export const actCallApiDeleteMemberSuccess = () => ({
  type: ACTION_TYPE.CALL_API_DELETE_MEMBER_SUCCESS,
});

export const actCallApiGetCsvMemberData = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA,
  searchCondition,
});

export const actCallApiGetCsvMemberDataSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA_SUCCESS,
  datas,
});

export const actResetMemberListFlg = () => ({
  type: ACTION_TYPE.RESET_MEMBER_LIST_FLG,
});

export const actSetMemberListCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_MEMBER_LIST_CONDITION,
  searchCondition,
});
