const nameValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const storeValidation = (value) => {
  if (!value || !value.id) return '選択してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'visitedAt':
      case 'memberNo':
      case 'includTaxAmount':
      case 'excludTaxAmount':
      case 'discountAmount':
      case 'visitors':
        return nameValidation(value);
      case 'store':
        return storeValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
