import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * コンボボックス用の一覧を取得する
 *
 * @returns
 */
export function getStores() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SELECTS.STORES)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 店舗とグレードを取得
 * @returns
 */
export function getStoreAndGrades() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SELECTS.STORE_GRADE)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 選択肢取得
 * @returns
 */
export function getStoreAndUsages() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SELECTS.STORE_USAGE)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 住所を取得
 * @param {*} param
 * @returns
 */
export function getAddress(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SELECTS.ADDRESS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 店舗と会員ランクを取得
 *
 * @returns
 */
export function getStoresAndMemberRanks() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.SELECTS.STORES_MEMBER_RANKS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
