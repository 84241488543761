export function menAmountKey(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'menIncludTaxAmount';
      break;
    case '1':
      key = 'menExcludTaxAmount';
      break;
    case '2':
      key = 'menDiscountAmount';
      break;
    case '3':
      key = 'menDiscountBeforeAmount';
      break;
    default:
      key = '';
      break;
  }
  return key;
}

export function womenAmountKey(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'womenIncludTaxAmount';
      break;
    case '1':
      key = 'womenExcludTaxAmount';
      break;
    case '2':
      key = 'womenDiscountAmount';
      break;
    case '3':
      key = 'womenDiscountBeforeAmount';
      break;
    default:
      key = '';
      break;
  }
  return key;
}

export function otherAmountKey(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'otherIncludTaxAmount';
      break;
    case '1':
      key = 'otherExcludTaxAmount';
      break;
    case '2':
      key = 'otherDiscountAmount';
      break;
    case '3':
      key = 'otherDiscountBeforeAmount';
      break;
    default:
      key = '';
      break;
  }
  return key;
}
