// region Type
export const ACTION_TYPE = {
  CALL_API_GET_MEMBER_VISIT_STATUS_SELECT: 'CALL_API_GET_MEMBER_VISIT_STATUS_SELECT',
  CALL_API_GET_MEMBER_VISIT_STATUS_SELECT_SUCCESS: 'CALL_API_GET_MEMBER_VISIT_STATUS_SELECT_SUCCESS',
  CALL_API_GET_VISIT_STATUS_MEMBER: 'CALL_API_GET_VISIT_STATUS_MEMBER',
  CALL_API_GET_VISIT_STATUS_MEMBER_SUCCESS: 'CALL_API_GET_VISIT_STATUS_MEMBER_SUCCESS',
  CALL_API_DELETE_VISIT_STATUS: 'CALL_API_DELETE_VISIT_STATUS',
  CALL_API_DELETE_VISIT_STATUS_SUCCESS: 'CALL_API_DELETE_VISIT_STATUS_SUCCESS',
  RESET_MEMBER_VISIT_STATUS_FLG: 'RESET_MEMBER_VISIT_STATUS_FLG',
};
// endregion

// region action
export const actCallApiGetSelect = () => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_VISIT_STATUS_SELECT,
});

export const actCallApiGetSelectSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_VISIT_STATUS_SELECT_SUCCESS,
  selects,
});

export const actCallApiGetMember = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_STATUS_MEMBER,
  param,
});

export const actCallApiGetMemberSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_VISIT_STATUS_MEMBER_SUCCESS,
  datas,
});

export const actCallApiDeleteVisitStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_DELETE_VISIT_STATUS,
  param,
});

export const actCallApiDeleteVisitStatusSuccess = () => ({
  type: ACTION_TYPE.CALL_API_DELETE_VISIT_STATUS_SUCCESS,
});

export const actResetVisitStatusFlg = () => ({
  type: ACTION_TYPE.RESET_MEMBER_VISIT_STATUS_FLG,
});

// endregion
