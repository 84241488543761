// #region Type
export const ACTION_TYPE = {
  CALL_API_SYSTEM_USER_CREATE: 'CALL_API_SYSTEM_USER_CREATE',
  CALL_API_SYSTEM_USER_CREATE_SUCCESS: 'CALL_API_SYSTEM_USER_CREATE_SUCCESS',
  CALL_API_SYSTEM_USER_UPDATE: 'CALL_API_SYSTEM_USER_UPDATE',
  CALL_API_SYSTEM_USER_UPDATE_SUCCESS: 'CALL_API_SYSTEM_USER_UPDATE_SUCCESS',
  CALL_API_GET_SYSTEM_USER: 'CALL_API_GET_SYSTEM_USER',
  CALL_API_GET_SYSTEM_USER_SUCCESS: 'CALL_API_GET_SYSTEM_USER_SUCCESS',
  CALL_API_SYSTEM_USER_LOGIN_ID_CHECK: 'CALL_API_SYSTEM_USER_LOGIN_ID_CHECK',
  CALL_API_SYSTEM_USER_LOGIN_ID_CHECK_SUCCESS: 'CALL_API_SYSTEM_USER_LOGIN_ID_CHECK_SUCCESS',
  CALL_API_SYSTEM_USER_CHANGE_PASSWORD: 'CALL_API_SYSTEM_USER_CHANGE_PASSWORD',
  CALL_API_SYSTEM_USER_CHANGE_PASSWORD_SUCCESS: 'CALL_API_SYSTEM_USER_CHANGE_PASSWORD_SUCCESS',
  RESET_SYSTEM_USER_DETAIL_FLG: 'RESET_SYSTEM_USER_DETAIL_FLG',
};
// #endregion

// #region action
export const actCallApiSystemUserCreate = (param) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_CREATE,
  param,
});

export const actCallApiSystemUserCreateSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_CREATE_SUCCESS,
  datas,
});

export const actCallApiSystemUserUpdate = (param) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_UPDATE,
  param,
});

export const actCallApiSystemUserUpdateSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_UPDATE_SUCCESS,
  datas,
});

export const actCallApiGetSystemUser = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_SYSTEM_USER,
  param,
});

export const actCallApiGetSystemUserSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_SYSTEM_USER_SUCCESS,
  data,
});

export const actCallApiSystemUserLoginIdCheck = (param) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_LOGIN_ID_CHECK,
  param,
});

export const actCallApiSystemUserLoginIdCheckSuccess = (result) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_LOGIN_ID_CHECK_SUCCESS,
  result,
});

export const actCallApiSystemUserChangePassword = (param) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_CHANGE_PASSWORD,
  param,
});

export const actCallApiSystemUserChangePasswordSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_SYSTEM_USER_CHANGE_PASSWORD_SUCCESS,
  data,
});

export const actResetSystemUserDetailFlg = () => ({
  type: ACTION_TYPE.RESET_SYSTEM_USER_DETAIL_FLG,
});
// #endregion
