import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 会員別サマリー情報を取得する
 *
 * @param {*} searchCondition
 * @returns
 */
export default function getSummary(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MEMBER_ANALYSIS.MEMBER_ANALYSIS.MEMBER_ANALYSIS_LIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
