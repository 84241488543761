import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}));

export default function SecondColorText(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} fontSize={13}>{children}</Box>
  );
}
