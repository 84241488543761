import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Box, Table, TableHead,
  TableContainer, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import Item from '../component/Item';
import { actCallApiGetStoreTaxVisitStatus } from '../../../../redux/visit/storeVisitStatus/action';

class TaxVisitItem extends React.Component {
  constructor() {
    super();
    this.state = {
      datas: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSearch !== this.props.isSearch) {
      if (this.props.isSearch) {
        this.clearSearch();
      }
    }
    if (prevProps.storeVisitStatus.taxDatas !== this.props.storeVisitStatus.taxDatas) {
      if (this.props.storeVisitStatus.taxDatas) {
        this.setDatas(this.props.storeVisitStatus.taxDatas);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  }

  clearSearch = () => {
    this.props.onSearchClear();
    this.search(this.props.searchCondition);
  }

  search = (searchCondition) => {
    const searchMemberGrades = searchCondition.searchMemberGrades.map((n) => n.id);
    this.props.dispatch(actCallApiGetStoreTaxVisitStatus({
      searchMemberGrades,
      searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
      searchStartVisitDate: searchCondition.searchStartVisitDate,
      searchEndVisitDate: searchCondition.searchEndVisitDate,
    }));
  }

  render() {
    const {
      datas,
    } = this.state;

    return (
      <Item>
        <Box mt={5}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>来店者数</TableCell>
                  <TableCell>合計会計金額(税込)</TableCell>
                  <TableCell>合計会計金額(税抜)</TableCell>
                  <TableCell>合計値引き額</TableCell>
                  <TableCell>合計値引き前金額(税抜)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((data) => (
                  <TableRow>
                    <TableCell>{data.type}</TableCell>
                    <TableCell>{data.includTaxAmount.toLocaleString()}</TableCell>
                    <TableCell>{data.excludTaxAmount.toLocaleString()}</TableCell>
                    <TableCell>{data.discountAmount.toLocaleString()}</TableCell>
                    <TableCell>{data.discountBeforeAmount.toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </Item>
    );
  }
}

const mapStateToProps = (state) => ({
  storeVisitStatus: state.storeVisitStatus,
});

export default connect(mapStateToProps)(TaxVisitItem);
