import { ACTION_TYPE } from './action';

// region Reducer
const visitRegist = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_VISIT_REGIST_SELECTS_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_GET_VISIT_REGIST_LIST_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_GET_VISIT_REGIST_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_REGIST_MEMBER_VISIT_SUCCESS:
      return {
        ...state,
        isRegistSuccess: true,
        registData: action.data,
      };
    case ACTION_TYPE.CALL_API_CREATE_MEMO_VISIT_REGIST_SUCCESS:
      return {
        ...state,
        isCreateMemoSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GET_MEMBER_VISIT_REGIST_SUCCESS:
      return {
        ...state,
        member: action.data,
        isGetMemberSuccess: true,
      };
    case ACTION_TYPE.RESET_VISIT_REGIST_FLG:
      return {
        ...state,
        isRegistSuccess: false,
        isCreateMemoSuccess: false,
        isGetMemberSuccess: false,
      };
    default:
      return state;
  }
};

export default visitRegist;
