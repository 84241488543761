import { ACTION_TYPE } from './action';

const memberList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_STORE_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        stores: action.datas,
      };
    case ACTION_TYPE.CALL_API_GET_MEMBER_LIST_SELECT_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_MEMBER_SEARCH_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.RESET_MEMBER_LIST_FLG:
      return {
        ...state,
        isDeleteSuccess: false,
      };
    case ACTION_TYPE.CALL_API_GET_CSV_MEMBER_DATA_SUCCESS:
      return {
        ...state,
        csvDatas: action.datas,
      };
    case ACTION_TYPE.SET_MEMBER_LIST_CONDITION:
      return {
        ...state,
        searchCondition: action.searchCondition,
      };
    default:
      return state;
  }
};

export default memberList;
