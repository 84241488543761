import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 作成処理
 * @param {*} param
 * @returns
 */
export function createSystemUser(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER.SYSTEM_USER.SYSTEM_USERS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 更新処理
 *
 * @param {*} param
 * @returns
 */
export function updateSystemUser(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.USER.SYSTEM_USER.SYSTEM_USER, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 一件取得処理
 *
 * @param {*} param
 * @returns
 */
export function getData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.USER.SYSTEM_USER.SYSTEM_USER, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * ログインIDチェック
 *
 * @param {*} param
 * @returns
 */
export function checkLoginId(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.USER.SYSTEM_USER.LOGIN_ID_CHECK, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * パスワード更新
 *
 * @param {*} param
 * @returns
 */
export function changePassword(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.USER.SYSTEM_USER.CHANGE_PASSWORD, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
