import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  Box, ListItem,
  ListItemAvatar, ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { isShowItem } from '../../common/header/check-show-screen';

const styles = () => ({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxSizing: 'border-box',
    color: '#212121',
    position: 'relative',
    '&:hover': {
      borderColor: 'transparent',
      boxShadow: '0 1px 2px 0 rgb(66 66 66 / 30%), 0 2px 6px 2px rgb(66 66 66 / 15%)',
      backgroundColor: 'rgba(66,66,66,0.04)',
      cursor: 'pointer',
    },
  },
});

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

class Menu extends React.Component {
  onClick = () => {
    const { url } = this.props;
    this.props.history.push({
      pathname: url,
    });
  }

  render() {
    const {
      icon, title, content, url, classes, loginUser,
    } = this.props;

    const screenList = loginUser.user ? loginUser.user.screenList : [];
    const isShow = isShowItem(screenList, url);

    return (
      <Item className={classes.root} style={{ display: isShow ? '' : 'none' }} onClick={this.onClick}>
        <ListItem>
          <ListItemAvatar>
            {icon}
          </ListItemAvatar>
          <ListItemText primary={title} secondary={content} />
        </ListItem>
      </Item>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginUser,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Menu)));
