import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 検索条件から来店一覧を取得する
 *
 * @param {*} searchCondition
 * @returns
 */
export function getDatas(searchCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.VISIT.VISIT_REGIST.VISIT_REGIST, searchCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 詳細情報取得
 *
 * @param {*} param
 * @returns
 */
export function getDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.VISIT.VISIT_REGIST.VISIT_REGIST_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 来店登録処理
 *
 * @param {*} param
 * @returns
 */
export function regist(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.VISIT.VISIT_REGIST.VISIT_REGIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
