import React from 'react';
import { format } from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import {
  Container, Box, AppBar, Tabs, Tab,
} from '@material-ui/core';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import { actCallApiGetSelectList } from '../../../redux/visit/storeVisitStatus/action';
import getAdmin from '../../../helpers/common.helper';
import AllVisitItem from './visitItem/AllVisitItem';
import TaxVisitItem from './visitItem/TaxVisitItem';
import GenderVisitItem from './visitItem/genderVisitItem/GenderVisitItem';
import AgeVisitItem from './visitItem/ageVisitItem/AgeVisitItem';

class StoreVisitStatus extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actSetBreadcrumbs([{ name: '店舗別来店状況' }]));
    props.dispatch(actCallApiGetSelectList());

    let searchStore = null;
    if (props.loginUser.user.store) {
      searchStore = props.loginUser.user.store;
    }

    const searchCondition = {
      searchStore,
      searchMemberGrades: [],
      searchStartVisitDate: format(new Date(), 'yyyy/MM/dd'),
      searchEndVisitDate: format(new Date(), 'yyyy/MM/dd'),
    };

    this.state = {
      selects: { stores: [], grades: [] },
      tabValue: 0,
      searchCondition,
      isSearch: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeVisitStatus.selects !== this.props.storeVisitStatus.selects) {
      if (this.props.storeVisitStatus.selects) {
        this.setSelects(this.props.storeVisitStatus.selects);
      }
    }
  }

  setSelects = (selects) => {
    this.setState({ selects });
  }

  onSearchChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({ searchCondition: { ...searchCondition, [name]: value } });
  }

  onSearch = () => {
    this.setState({ isSearch: true });
  }

  onSearchClear = () => {
    this.setState({ isSearch: false });
  }

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  }

  render() {
    const {
      selects, searchCondition, isSearch, tabValue,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <>
        <Container>
          <SearchBox onSearch={this.onSearch}>
            {admin && (
              <Box width="30%" mb={3}>
                <FormTitle title="来店店舗" />
                <SearchSelectBox
                  disableClearable
                  options={selects.stores}
                  value={searchCondition.searchStore}
                  name="searchStore"
                  onChange={this.onSearchChange}
                />
              </Box>
            )}
            <Box>
              <FormTitle title="来店日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker
                    value={searchCondition.searchStartVisitDate}
                    name="searchStartVisitDate"
                    handleChange={this.onSearchChange}
                  />
                </Box>
                <Box mx={4}>～</Box>
                <Box>
                  <Datepicker
                    minDate={searchCondition.searchStartVisitDate}
                    name="searchEndVisitDate"
                    value={searchCondition.searchEndVisitDate}
                    handleChange={this.onSearchChange}
                  />
                </Box>
              </Box>
            </Box>
            <Box width="30%" mt={3}>
              <FormTitle title="会員グレード" />
              <SearchMultiSelectBox
                options={selects.grades}
                values={searchCondition.searchMemberGrades}
                name="searchMemberGrades"
                onChange={this.onSearchChange}
              />
            </Box>
          </SearchBox>
        </Container>

        <Container maxWidth={false}>
          <Box mt={3}>
            <AppBar position="static">
              <Tabs
                value={tabValue}
                onChange={this.onTabChange}
                indicatorColor="secondary"
                variant="fullWidth"
              >
                <Tab label="全体" />
                <Tab label="税別" />
                <Tab label="性別" />
                <Tab label="年齢別" />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <AllVisitItem
                onSearchClear={this.onSearchClear}
                isSearch={isSearch}
                searchCondition={searchCondition}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <TaxVisitItem
                onSearchClear={this.onSearchClear}
                isSearch={isSearch}
                searchCondition={searchCondition}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <GenderVisitItem
                onSearchClear={this.onSearchClear}
                isSearch={isSearch}
                searchCondition={searchCondition}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <AgeVisitItem
                onSearchClear={this.onSearchClear}
                isSearch={isSearch}
                searchCondition={searchCondition}
              />
            </TabPanel>
          </Box>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  storeVisitStatus: state.storeVisitStatus,
  loginUser: state.loginUser,
});

export default connect(mapStateToProps)(StoreVisitStatus);
