import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  DialogContent,
  DialogActions,
  Box,
  Alert,
  AlertTitle,
} from '@material-ui/core';
import MaterialDialog from '../../../../components/atoms/materialDialog/MaterialDialog';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import { actCallApiSystemUserChangePassword, actResetSystemUserDetailFlg } from '../../../../redux/user/systemUserDetail/action';
import Validation from './validation';
import PasswordField from '../../../../components/atoms/passwordFeild/PasswordField';
import DialogColorTitle from '../../../../components/atoms/dialogColorTitle/DialogColorTitle';
import CancelButton from '../../../../components/atoms/cancaelButton/CancelButton';
import SaveButton from '../../../../components/atoms/saveButton/SaveButton';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordCheck: '',
      passwordMessage: '',
      passwordCheckMessage: '',
      loading: false,
      isShowLogoutMessage: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.systemUserList.isUserUpdateSuccess
      !== this.props.systemUserList.isUserUpdateSuccess) {
      if (this.props.systemUserList.isUserUpdateSuccess) {
        this.success();
      }
    }
  }

  onPasswordChange = (event) => {
    const { passwordCheck } = this.state;
    const { value } = event.target;

    const passwordMessage = Validation.formValidate('password', value, null);

    let passwordCheckMessage = '';
    if (passwordCheck) {
      passwordCheckMessage = Validation.formValidate('passwordCheck', value, passwordCheck);
    }

    this.setState({
      password: value,
      passwordMessage,
      passwordCheckMessage,
    });
  }

  onPasswordCheckChange = (event) => {
    const { password } = this.state;

    this.setState({
      passwordCheck: event.target.value,
      passwordCheckMessage: Validation.formValidate('passwordCheck', password, event.target.value),
    });
  }

  onSave = () => {
    const {
      password,
      passwordCheck,
    } = this.state;

    const { data } = this.props;

    const passwordMessage = Validation.formValidate('password', password, null);
    const passwordCheckMessage = Validation.formValidate('passwordCheck', password, passwordCheck);
    let loading = true;

    if (passwordMessage || passwordCheckMessage) {
      loading = false;
    }

    this.setState({ loading, passwordMessage, passwordCheckMessage });

    if (!loading) {
      return;
    }

    this.props.dispatch(actResetSystemUserDetailFlg());
    this.props.dispatch(
      actCallApiSystemUserChangePassword({
        id: data.id,
        password,
      }),
    );
  }

  success() {
    this.props.updateSuccess();
  }

  init() {
    const { data } = this.props;
    let isShowLogoutMessage = false;
    const { user } = this.props.loginUser;
    if (user) {
      isShowLogoutMessage = user.user.id === data.id;
    }
    this.setState({
      password: '',
      passwordCheck: '',
      passwordMessage: '',
      passwordCheckMessage: '',
      loading: false,
      title: data.name,
      isShowLogoutMessage,
    });
  }

  render() {
    const {
      password,
      passwordCheck,
      passwordMessage,
      passwordCheckMessage,
      loading,
      title,
      isShowLogoutMessage,
    } = this.state;

    const {
      open,
      onClose,
    } = this.props;

    return (
      <MaterialDialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
      >
        <DialogColorTitle>
          <Box>
            <Box>次のパスワードを再設定</Box>
            <Box>{title}</Box>
          </Box>
        </DialogColorTitle>
        <DialogContent dividers>
          <Box mt={1}>
            <Alert severity="info">
              <AlertTitle>パスワードの安全度</AlertTitle>
              <Box mb={1}>・8文字以上、16文字以下で設定してください</Box>
              <Box>・半角英数字、記号(! # $ @ . _)のみで入力出来ます</Box>
            </Alert>
          </Box>
          <Box mt={1} display={isShowLogoutMessage ? '' : 'none'}>
            <Alert severity="warning">パスワード変更後、自動でログアウトします</Alert>
          </Box>
          <Box>
            <Box mt={2}>
              <FormTitle title="新しいパスワード" isRequired />
              <PasswordField
                value={password}
                onChange={this.onPasswordChange}
                isError={passwordMessage !== ''}
              />
              <FormErrorText error>{passwordMessage}</FormErrorText>
            </Box>
            <Box mt={2} mb={1}>
              <FormTitle title="新しいパスワードの確認" isRequired />
              <PasswordField
                value={passwordCheck}
                onChange={this.onPasswordCheckChange}
                isError={passwordCheckMessage !== ''}
              />
              <FormErrorText error>{passwordCheckMessage}</FormErrorText>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>キャンセル</CancelButton>
          <SaveButton onClick={this.onSave} loading={loading}>
            パスワードを変更
          </SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  systemUserList: state.systemUserList,
  loginUser: state.loginUser,
});

export default connect(mapStateToProps)(ChangePassword);
