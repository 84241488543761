import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * 会員一覧取得
 * @param{*}param
 * @returns
 */
export function getDatas(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MEMBER.MEMBER.MEMBERS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 * @param{*}param
 * @returns
 */
export function deleteMembers(param) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .deletes(REST_API.MEMBER.MEMBER.MEMBERS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * CSV出力用データ取得
 * @param {*} param
 * @returns
 */
export function getCsvDatas(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MEMBER.MEMBER.MEMBERS_CSV, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
