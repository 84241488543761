// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_STORE: 'CALL_API_GET_STORE',
  CALL_API_GET_STORE_SUCCESS: 'CALL_API_GET_STORE_SUCCESS',
  CALL_API_DELETE_STORE: 'CALL_API_DELETE_STORE',
  CALL_API_DELETE_STORE_SUCCESS: 'CALL_API_DELETE_STORE_SUCCESS',
  RESET_STORE_FLG: 'RESET_STORE_FLG',
};
// #endregion

// #region action
export const actCallApiGetStore = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE,
  param,
});

export const actCallApiGetStoreSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_SUCCESS,
  datas,
});

export const actCallApiDeleteStore = (param) => ({
  type: ACTION_TYPE.CALL_API_DELETE_STORE,
  param,
});

export const actCallApiDeleteStoreSuccess = () => ({
  type: ACTION_TYPE.CALL_API_DELETE_STORE_SUCCESS,
});

export const actResetStoreFlg = () => ({
  type: ACTION_TYPE.RESET_STORE_FLG,
});
// #endregion
