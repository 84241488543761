import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  Container, Box, TextField,
} from '@material-ui/core';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import {
  actCallApiGetSelect, actCallApiDeleteVisitStatus, actResetVisitStatusFlg, actCallApiGetMember,
} from '../../../redux/visit/visitStatusList/action';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import getAdmin from '../../../helpers/common.helper';
import VisitStatusDetail from '../visitStatusDetail/VisitStatusDetail';

class VisitStatusList extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actSetBreadcrumbs([{ name: '来店状況一覧' }]));
    props.dispatch(actCallApiGetSelect());

    const searchCondition = {
      page: 0,
      records: 10,
      searchStoreIds: [],
      searchStartDate: '',
      searchEndDate: '',
      searchMemberNo: '',
      searchMemberName: '',
      orderBy: '',
      order: 'asc',
    };

    props.dispatch(actCallApiGetMember(searchCondition));

    this.state = {
      selects: { stores: [] },
      isSuccessOpen: false,
      memberDatas: [],
      searchCondition,
      isDetailOpen: false,
      selectedRow: {},
      successMessage: '',
    };

    this.headCells = [
      {
        id: 'storeName', numeric: false, label: '店舗名', sortKey: 'store_name',
      },
      {
        id: 'visitedAt', numeric: false, label: '来店日時', sortKey: 'visited_at',
      },
      {
        id: 'memberName', numeric: false, label: '会員名', sortKey: 'member_name',
      },
      {
        id: 'includTaxAmount', numeric: false, label: '会計金額(税込)', sortKey: 'includ_tax_amount',
      },
      {
        id: 'excludTaxAmount', numeric: false, label: '会計金額(税抜)', sortKey: 'exclud_tax_amount',
      },
      {
        id: 'discountAmount', numeric: false, label: '値引き額', sortKey: 'discount_amount',
      },
      {
        id: 'visitors', numeric: false, label: '来店人数', sortKey: 'visitors',
      },
      {
        id: 'usageType', numeric: false, label: '利用タイプ', sortKey: 'usage_type',
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitStatusList.selects !== this.props.visitStatusList.selects) {
      if (this.props.visitStatusList.selects) {
        this.setSelects(this.props.visitStatusList.selects);
      }
    }
    if (prevProps.visitStatusList.datas !== this.props.visitStatusList.datas) {
      if (this.props.visitStatusList.datas) {
        this.setMember(this.props.visitStatusList.datas);
      }
    }
    if (prevProps.visitStatusList.isDeleteSuccess
      !== this.props.visitStatusList.isDeleteSuccess) {
      if (this.props.visitStatusList.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
    if (prevProps.visitStatusDetail.isUpdateSuccess
      !== this.props.visitStatusDetail.isUpdateSuccess) {
      if (this.props.visitStatusDetail.isUpdateSuccess) {
        this.setUpdateSuccess();
      }
    }
    if (prevProps.visitStatusDetail.isCreateSuccess
      !== this.props.visitStatusDetail.isCreateSuccess) {
      if (this.props.visitStatusDetail.isCreateSuccess) {
        this.setCreateSuccess();
      }
    }
  }

  setCreateSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true, isDetailOpen: false, successMessage: '来店状況の登録が完了しました' });
  }

  setUpdateSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true, isDetailOpen: false, successMessage: '来店状況の更新が完了しました' });
  }

  setDeleteSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage: '来店状況の削除が完了しました' });
  }

  setMember = (memberDatas) => {
    this.setState({ memberDatas });
  }

  setSelects = (selects) => {
    this.setState({ selects });
  }

  onSearchChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({ searchCondition: { ...searchCondition, [name]: value } });
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
  }

  search = (searchCondition) => {
    const searchStoreIds = searchCondition.searchStoreIds.map((n) => n.id);
    this.props.dispatch(actCallApiGetMember({
      ...searchCondition,
      sortDirection: searchCondition.order,
      sortKey: searchCondition.orderBy,
      searchStoreIds,
    }));
  }

  onDelete = (value) => {
    this.props.dispatch(actResetVisitStatusFlg());
    this.props.dispatch(
      actCallApiDeleteVisitStatus({
        deletes: value,
      }),
    );
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isSuccessOpen: false });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  }

  onDetailOpen = (_, selectedRow) => {
    this.setState({ isDetailOpen: true, selectedRow, isSuccessOpen: false });
  }

  onAddOpen = () => {
    this.setState({ isDetailOpen: true, selectedRow: null, isSuccessOpen: false });
  }

  onDetailClose = () => {
    this.setState({ isDetailOpen: false });
  }

  render() {
    const {
      selects, searchCondition, memberDatas, isSuccessOpen,
      isDetailOpen, successMessage, selectedRow,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <>
        <Container>
          <SearchBox onSearch={this.onSearch}>
            {admin && (
              <Box width="30%" mb={3}>
                <FormTitle title="来店店舗" />
                <SearchMultiSelectBox
                  options={selects.stores}
                  values={searchCondition.searchStoreIds}
                  name="searchStoreIds"
                  onChange={this.onSearchChange}
                />
              </Box>
            )}
            <Box>
              <FormTitle title="来店日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker
                    value={searchCondition.searchStartDate}
                    name="searchStartDate"
                    clearable
                    handleChange={this.onSearchChange}
                  />
                </Box>
                <Box mx={4}>～</Box>
                <Box>
                  <Datepicker
                    minDate={searchCondition.searchStartDate}
                    name="searchEndDate"
                    clearable
                    value={searchCondition.searchEndDate}
                    handleChange={this.onSearchChange}
                  />
                </Box>
              </Box>
            </Box>

            <Box mt={3} display="flex">
              <Box>
                <FormTitle title="会員番号" />
                <TextField
                  onChange={this.onSearchChange}
                  variant="outlined"
                  value={searchCondition.searchMemberNo}
                  inputProps={{ maxLength: 10 }}
                  name="searchMemberNo"
                />
              </Box>
              <Box ml={2}>
                <FormTitle title="会員名" />
                <TextField
                  onChange={this.onSearchChange}
                  variant="outlined"
                  value={searchCondition.searchMemberName}
                  inputProps={{ maxLength: 100 }}
                  name="searchMemberName"
                />
              </Box>
            </Box>
          </SearchBox>
          <DeleteDataTable
            onDetail={this.onDetailOpen}
            onAdd={this.onAddOpen}
            rows={memberDatas.content || []}
            headCells={this.headCells}
            procName="来店状況"
            page={searchCondition.page}
            rowsPerPage={searchCondition.records}
            totalElements={memberDatas.totalElements}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onDelete={this.onDelete}
            handleRequestSort={this.handleRequestSort}
            order={searchCondition.order}
            orderBy={searchCondition.orderBy}
          />
        </Container>

        <VisitStatusDetail
          open={isDetailOpen}
          onClose={this.onDetailClose}
          selectedRow={selectedRow}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  visitStatusList: state.visitStatusList,
  loginUser: state.loginUser,
  visitStatusDetail: state.visitStatusDetail,
});

export default withRouter(connect(mapStateToProps)(VisitStatusList));
