import { call, put, takeLatest } from 'redux-saga/effects';
import { getData, update } from '../../../services/member/memoDetail.service';
import { actCallApiServerError } from '../../common/common/action';
import { actCallApiGetMemoDetailSuccess, actCallApiUpdateMemoSuccess, ACTION_TYPE } from './action';

/**
 * 更新処理
 *
 * @param {*} param0
 */
function* callApiMemoUpdate({ param }) {
  try {
    const data = yield call(update, param);
    yield put(actCallApiUpdateMemoSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 一件取得処理
 *
 * @param {*} param0
 */
function* callApiMemoGet({ param }) {
  try {
    const data = yield call(getData, param);
    yield put(actCallApiGetMemoDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemoDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_MEMO, callApiMemoUpdate);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMO_DETAIL, callApiMemoGet);
}
