import { ACTION_TYPE } from './action';

const memberDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_MEMBER_ADDRESS_SUCCESS:
      return {
        ...state,
        isGetAddressSuccess: true,
        address: action.data,
      };
    case ACTION_TYPE.CALL_API_SELECT_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_GET_UPDATE_MEMBER_DATA_SUCCESS:
      return {
        ...state,
        memberData: action.data,
      };
    case ACTION_TYPE.CALL_API_CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        isCreateSuccess: true,
        createDate: action.data,
      };
    case ACTION_TYPE.CALL_API_UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
        updateData: action.data,
      };
    case ACTION_TYPE.CALL_API_MEMBER_EXIST_CHECK_SUCCESS:
      return {
        ...state,
        isCheckSuccess: true,
        checkData: action.data,
      };
    case ACTION_TYPE.SET_MEMBER_CREATE_SUCCESS:
      return {
        ...state,
        isSetCreateMessage: true,
      };
    case ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_MEMO_SUCCESS:
      return {
        ...state,
        info: action.data,
      };
    case ACTION_TYPE.CALL_API_CREATE_MEMBER_DETAIL_MEMO_SUCCESS:
      return {
        ...state,
        isCreateMemoSuccess: true,
      };
    case ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL_MEMO_SUCCESS:
      return {
        ...state,
        isCreateMemoSuccess: true,
      };
    case ACTION_TYPE.CALL_API_DELETE_MEMBER_DETAIL_MEMO_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.RESET_MEMBER_DETAIL_FLG:
      return {
        ...state,
        isSetCreateMessage: false,
        isGetAddressSuccess: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isCheckSuccess: false,
        isCreateMemoSuccess: false,
        isDeleteSuccess: false,
      };
    default:
      return state;
  }
};

export default memberDetail;
