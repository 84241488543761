export const ACTION_TYPE = {
  CALL_API_ROLE_GET_SCREENS: 'CALL_API_ROLE_GET_SCREENS',
  CALL_API_ROLE_GET_SCREENS_SUCCESS: 'CALL_API_ROLE_GET_SCREENS_SUCCESS',
  CALL_API_GET_ROLE: 'CALL_API_GET_ROLE',
  CALL_API_GET_ROLE_SUCCESS: 'CALL_API_GET_ROLE_SUCCESS',
  CALL_API_GET_ROLE_DETAIL: 'CALL_API_GET_ROLE_DETAIL',
  CALL_API_GET_ROLE_DETAIL_SUCCESS: 'CALL_API_GET_ROLE_DETAIL_SUCCESS',
  CALL_API_UPDATE_ROLE_SCREEN: 'CALL_API_UPDATE_ROLE_SCREEN',
  CALL_API_UPDATE_ROLE_SCREEN_SUCCESS: 'CALL_API_UPDATE_ROLE_SCREEN_SUCCESS',
  CALL_API_ROLE_DETAIL_CREATE: 'CALL_API_ROLE_DETAIL_INSERT',
  CALL_API_ROLE_DETAIL_CREATE_SUCCESS: 'CALL_API_ROLE_DETAIL_INSERT_SUCCESS',
  CALL_API_ROLE_DETAIL_UPDATE: 'CALL_API_ROLE_DETAIL_UPDATE',
  CALL_API_ROLE_DETAIL_UPDATE_SUCCESS: 'CALL_API_ROLE_DETAIL_UPDATE_SUCCESS',
  RESET_ROLE_DETAIL_FLG: 'RESET_ROLE_DETAIL_FLG',
  SET_ROLE_CREATED: 'SET_ROLE_CREATED',
};

export const actCallApiGetScreens = (id) => ({
  type: ACTION_TYPE.CALL_API_ROLE_GET_SCREENS,
  id,
});

export const actCallApiGetScreensSuccess = (screens) => ({
  type: ACTION_TYPE.CALL_API_ROLE_GET_SCREENS_SUCCESS,
  screens,
});

export const actCallApiGetRole = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_ROLE,
  param,
});

export const actCallApiGetRoleSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_ROLE_SUCCESS,
  data,
});

export const actCallApiGetRoleDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_ROLE_DETAIL,
  param,
});

export const actCallApiGetRoleDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_ROLE_DETAIL_SUCCESS,
  data,
});

export const actCallApiUpdateRoleScreen = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREEN,
  param,
});

export const actCallApiUpdateRoleScreenSuccess = (roleScreens) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREEN_SUCCESS,
  roleScreens,
});

export const actCallApiCreateRole = (param) => ({
  type: ACTION_TYPE.CALL_API_ROLE_DETAIL_CREATE,
  param,
});

export const actCallApiCreateRoleSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_ROLE_DETAIL_CREATE_SUCCESS,
  data,
});

export const actCallApiUpdateRole = (param) => ({
  type: ACTION_TYPE.CALL_API_ROLE_DETAIL_UPDATE,
  param,
});

export const actCallApiUpdateRoleSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_ROLE_DETAIL_UPDATE_SUCCESS,
  data,
});

export const actResetRoleDetailFlg = () => ({
  type: ACTION_TYPE.RESET_ROLE_DETAIL_FLG,
});

export const actSetRoleCreated = () => ({
  type: ACTION_TYPE.SET_ROLE_CREATED,
});
