import React from 'react';
import { Route, Switch } from 'react-router-dom';

import urls from '../../constants/urls';
import ApplicationError from './ApplicationError';
import NotFound from './NotFound';

import Home from '../other/home/Home';

import SystemUserList from '../user/systemUserList/SystemUserList';
import RoleList from '../user/roleList/RoleList';
import RoleDetail from '../user/roleDetail/RoleDetail';

import StoreList from '../setting/storeList/StoreList';
import MemoList from '../member/memoList/MemoList';
import MemberList from '../member/memberList/MemberList';
import MemberDetail from '../member/memberDetail/MemberDetail';

import VisitRegist from '../visit/visitRegist/VisitRegist';
import DailyVisitStatus from '../visit/dailyVisitStatus/DailyVisitStatus';
import VisitStatusList from '../visit/visitStatusList/VisitStatusList';
import StoreVisitStatus from '../visit/storeVisitStatus/StoreVisitStatus';

import StoreMemberAnalysis from '../memberAnalysis/storeMemberAnalysis/StoreMemberAnalysis';
import MemberAnalysises from '../memberAnalysis/memberAnalysises/MemberAnalysises';
import UseStoreMemberAnalysises from '../memberAnalysis/useStoreMemberAnalysises/UseStoreMemberAnalysises';

export default function Routes() {
  return (
    <Switch>
      <Route exact path={urls.OTHER.HOME} component={Home} />
      <Route exact path={urls.OTHER.SYSTEM_ERROR} component={ApplicationError} />

      <Route exact path={urls.USER.SYSTEM_USER} component={SystemUserList} />
      <Route exact path={urls.USER.ROLE} component={RoleList} />
      <Route exact path={urls.USER.ROLE_DETAIL} component={RoleDetail} />

      <Route exact path={urls.SETTING.STORE} component={StoreList} />

      <Route exact path={urls.MEMBER.MEMO} component={MemoList} />
      <Route exact path={urls.MEMBER.MEMBERS} component={MemberList} />
      <Route exact path={urls.MEMBER.MEMBER_DETAIL} component={MemberDetail} />

      <Route exact path={urls.VISIT.VISIT_REGIST} component={VisitRegist} />
      <Route exact path={urls.VISIT.STORE_VISIT_STATUS} component={StoreVisitStatus} />
      <Route exact path={urls.VISIT.DAILY_VISIT_STATUS} component={DailyVisitStatus} />
      <Route exact path={urls.VISIT.VISIT_STATUS_LIST} component={VisitStatusList} />

      <Route
        exact
        path={urls.MEMBER_ANALYSIS.STORE_MEMBER_ANALYSIS}
        component={StoreMemberAnalysis}
      />
      <Route
        exact
        path={urls.MEMBER_ANALYSIS.MEMBER_ANALYSIS}
        component={MemberAnalysises}
      />
      <Route
        exact
        path={urls.MEMBER_ANALYSIS.USE_STORE_MEMBER_ANALYSIS}
        component={UseStoreMemberAnalysises}
      />

      <Route component={NotFound} />
    </Switch>
  );
}
