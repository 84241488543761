const nameValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const zipCodeValidation = (value) => {
  if (value && !value.match(/^\d{3}[-]?\d{4}$/)) {
    return '正しい郵便番号を入力してください';
  }
  return '';
};

const emailValidation = (value) => {
  if (!value) return '';
  const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  if (!regex.test(value)) return '正しい形式で入力してください';
  return '';
};

const storeValidation = (value) => {
  if (!value || !value.id) return '選択してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'firstName':
      case 'lastName':
      case 'memberNo':
        return nameValidation(value);
      case 'zipCode':
        return zipCodeValidation(value);
      case 'email':
        return emailValidation(value);
      case 'store':
        return storeValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
