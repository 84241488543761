import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  Button, TextField,
  DialogActions, DialogContent, Box,
} from '@material-ui/core';
import {
  SavedSearch as SavedSearchIcon,
  SkipNext as SkipNextIcon,
} from '@material-ui/icons';
import LoadingButton from '@material-ui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import urls from '../../../constants/urls';
import {
  actResetMemberDetailFlg, actCallApiCheckExistMember,
} from '../../../redux/member/memberDetail/action';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';

class MemberCreateCheckModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnLoading: false,
      memberNo: '',
      resultId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.memberDetail.isCheckSuccess !== this.props.memberDetail.isCheckSuccess) {
      if (this.props.memberDetail.isCheckSuccess) {
        this.setData(this.props.memberDetail.checkData);
      }
    }
  }

  setData = (data) => {
    const { memberNo } = this.state;
    if (!data) {
      this.props.onNext(memberNo);
      return;
    }
    this.setState({ resultId: data.id, btnLoading: false });
  }

  init = () => {
    this.setState({
      btnLoading: false,
      memberNo: '',
      resultId: null,
    });
  }

  onChange = (event) => {
    this.setState({ memberNo: event.target.value });
  }

  onNext = () => {
    const { memberNo } = this.state;
    this.setState({ resultId: null, btnLoading: true });
    this.props.dispatch(actResetMemberDetailFlg());
    this.props.dispatch(actCallApiCheckExistMember({ memberNo }));
  }

  onDetail = () => {
    const { resultId } = this.state;
    this.props.onClose();
    this.props.history.push({
      pathname: urls.MEMBER.MEMBER_DETAIL,
      state: { memberId: resultId },
    });
  }

  render() {
    const {
      open,
      onClose,
    } = this.props;

    const {
      btnLoading,
      memberNo,
      resultId,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={onClose}>
        <DialogColorTitle>
          <Box>会員の追加</Box>
        </DialogColorTitle>
        <DialogContent dividers>
          <Box display={resultId === null ? '' : 'none'}>
            <FormTitle title="会員番号" isRequired />
            <TextField
              name="lastName"
              variant="outlined"
              value={memberNo}
              onChange={this.onChange}
              error={false}
              inputProps={{
                maxLength: 10,
              }}
            />
          </Box>
          <Box display={resultId === null ? 'none' : ''}>
            <Box mb={2}>{`${memberNo}は既に登録されている会員番号です。`}</Box>
            <Button variant="outlined" startIcon={<SavedSearchIcon />} color="secondary" onClick={this.onDetail}>登録された会員を確認する</Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>キャンセル</CancelButton>
          <Box display={resultId === null ? '' : 'none'} ml={2}>
            <LoadingButton loading={btnLoading} variant="outlined" onClick={this.onNext} disabled={memberNo === ''} color="secondary" startIcon={<SkipNextIcon />}>次へ</LoadingButton>
          </Box>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  memberDetail: state.memberDetail,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps)(MemberCreateCheckModal));
