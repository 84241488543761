import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  DialogActions, DialogContent, Box, LinearProgress,
  TextField, Radio, FormControl, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import Validation from './validation';
import {
  actCallApiGetSelect, actResetVisitStatusDetailFlg, actCallApiUpdateVisitStatusDetail,
  actCallApiGetVisitStatusDetail, actCallApiCheckVisitStatusMember,
  actCallApiCreateVisitStatusDetail,
} from '../../../redux/visit/visitStatusDetail/action';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import PersonInput from '../../../components/atoms/personInput/PersonInput';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import DateTimePicker from '../../../components/atoms/dateTimePicker/DateTimePicker';
import getAdmin from '../../../helpers/common.helper';
import {
  getExcludTaxAmount, getDiscountAmount,
  getNewForm, getNewMessages,
} from './visit-status-detail-proc';

class VisitStatusDetail extends Component {
  constructor(props) {
    super(props);
    props.dispatch(actCallApiGetSelect());

    this.state = {
      form: getNewForm(),
      messages: getNewMessages(),
      loading: false,
      btnLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.visitStatusDetail.data !== this.props.visitStatusDetail.data) {
      if (this.props.visitStatusDetail.data) {
        this.setData(this.props.visitStatusDetail.data);
      }
    }
    if (prevProps.visitStatusDetail.isCheckSuccess
      !== this.props.visitStatusDetail.isCheckSuccess) {
      if (this.props.visitStatusDetail.isCheckSuccess) {
        this.setMember(this.props.visitStatusDetail.checkData);
      }
    }
  }

  setMember = (data) => {
    const { form, messages } = this.state;
    let tempMessages = messages;
    if (!data) {
      tempMessages = {
        ...messages,
        memberNo: '存在しない会員です',
      };
    }

    const tempForm = {
      ...form,
      member: data || {},
    };

    this.setState({ form: tempForm, messages: tempMessages });
  }

  setData = (data) => {
    const form = {
      ...data,
      memberNo: data.member.memberNo,
    };
    this.setState({ form, loading: false, btnLoading: false });
  }

  handleSave = () => {
    const { form, messages } = this.state;

    if (messages.memberNo) {
      return;
    }

    let tempMessages = messages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessages = {
        ...tempMessages,
        [key]: msg,
      };
    });

    this.setState({ messages: tempMessages, btnLoading: !isError });

    if (isError) {
      return;
    }

    const param = {
      id: form.id,
      storeId: form.store && form.store.id,
      visitedAt: form.visitedAt,
      memberId: form.member && form.member.id,
      includTaxAmount: form.includTaxAmount,
      excludTaxAmount: form.excludTaxAmount,
      discountAmount: form.discountAmount,
      spareIncludTaxAmount: form.spareIncludTaxAmount,
      spareExcludTaxAmount: form.spareExcludTaxAmount,
      spareDiscountAmount: form.spareDiscountAmount,
      visitors: form.visitors,
      usageCode: form.usageCode,
    };

    this.props.dispatch(actResetVisitStatusDetailFlg());
    if (!form.id) {
      this.props.dispatch(actCallApiCreateVisitStatusDetail(param));
    } else {
      this.props.dispatch(actCallApiUpdateVisitStatusDetail(param));
    }
  }

  onChange = (event) => {
    const { value, name } = event.target;
    const { form, messages } = this.state;
    const error = Validation.formValidate(name, value);
    let tempMember = form.member;
    if (name === 'memberNo' && error) {
      tempMember = {};
    }
    this.setState({
      form: {
        ...form,
        [name]: value,
        member: tempMember,
      },
      messages: {
        ...messages,
        [name]: error,
      },
    });
  }

  onIncludTaxAmountChange = (event) => {
    const { form, messages } = this.state;
    const { value } = event.target;
    const { selects } = this.props.visitStatusDetail;
    const usages = (selects && selects.usages) ? selects.usages : [];
    const excludAmount = getExcludTaxAmount(value, form.usageCode, usages, form.excludTaxAmount);
    const discount = getDiscountAmount(excludAmount, form.member);
    this.setState({
      form: {
        ...form,
        includTaxAmount: value,
        excludTaxAmount: excludAmount,
        discountAmount: discount,
      },
      messages: {
        ...messages,
        includTaxAmount: Validation.formValidate('includTaxAmount', value),
        excludTaxAmount: Validation.formValidate('excludTaxAmount', excludAmount),
        discountAmount: Validation.formValidate('discountAmount', discount),
      },
    });
  }

  onExcludTaxAmountChange = (event) => {
    const { form, messages } = this.state;
    const { value } = event.target;
    const discount = getDiscountAmount(value, form.member);
    this.setState({
      form: {
        ...form,
        excludTaxAmount: value,
        discountAmount: discount,
      },
      messages: {
        ...messages,
        excludTaxAmount: Validation.formValidate('excludTaxAmount', value),
        discountAmount: Validation.formValidate('discountAmount', discount),
      },
    });
  }

  onRadioChange = (event) => {
    const { value, name } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: parseInt(value, 10),
      },
    });
  }

  onCheckMemberNo = () => {
    const { form } = this.state;
    if (!form.memberNo) {
      return;
    }
    this.props.dispatch(actResetVisitStatusDetailFlg());
    this.props.dispatch(actCallApiCheckVisitStatusMember(
      { memberNo: form.memberNo },
    ));
  }

  init() {
    const { selectedRow } = this.props;
    let loading = false;
    let btnLoading = false;
    if (selectedRow) {
      this.props.dispatch(actCallApiGetVisitStatusDetail({ id: selectedRow.id }));
      loading = true;
      btnLoading = true;
    }
    const store = this.props.loginUser ? this.props.loginUser.user.store : {};
    this.setState({
      loading,
      btnLoading,
      form: {
        ...getNewForm(),
        store,
      },
      messages: getNewMessages(),
    });
  }

  render() {
    const {
      open,
      onClose,
      visitStatusDetail,
    } = this.props;
    const selects = visitStatusDetail.selects ? visitStatusDetail.selects : {};

    const {
      form,
      messages,
      btnLoading,
      loading,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <MaterialDialog open={open} onClose={onClose}>
        <DialogColorTitle>
          <Box>{form.id ? '来店状況の変更' : '来店状況の追加'}</Box>
        </DialogColorTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          {admin && (
            <Box mb={2}>
              <FormTitle title="来店店舗" isRequired />
              <SearchSelectBox
                options={selects.stores}
                disableClearable
                value={form.store}
                name="store"
                error={messages.store !== ''}
                onChange={this.onChange}
              />
              <FormErrorText error>{messages.store}</FormErrorText>
            </Box>
          )}
          <Box mb={2}>
            <FormTitle title="来店日時" isRequired />
            <DateTimePicker
              value={form.visitedAt}
              name="visitedAt"
              handleChange={this.onChange}
              error={messages.visitedAt !== ''}
            />
            <FormErrorText error>{messages.visitedAt}</FormErrorText>
          </Box>
          <Box mb={2}>
            <FormTitle title="会員番号" isRequired />
            <TextField
              value={form.memberNo}
              name="memberNo"
              variant="outlined"
              onBlur={this.onCheckMemberNo}
              inputProps={{
                maxLength: 10,
              }}
              error={messages.memberNo !== ''}
              onChange={this.onChange}
            />
            {(form.member && form.member.id) && (
              <Box mt={2}>{`${form.member.lastName} ${form.member.firstName}`}</Box>
            )}
            <FormErrorText error>{messages.memberNo}</FormErrorText>
          </Box>
          <Box mb={2}>
            <FormTitle title="利用タイプ" isRequired />
            <FormControl component="fieldset">
              <RadioGroup row value={form.usageCode} name="usageCode" onChange={this.onRadioChange}>
                {selects.usages && selects.usages.map((select) => (
                  <FormControlLabel
                    key={select.id}
                    value={select.code}
                    control={<Radio />}
                    label={select.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mb={2} display="flex">
            <Box>
              <FormTitle title="会計金額(税込)" isRequired />
              <MoneyInput
                inputProps={{
                  maxLength: 13,
                }}
                error={messages.includTaxAmount !== ''}
                onChange={this.onIncludTaxAmountChange}
                value={form.includTaxAmount}
                name="includTaxAmount"
              />
              <FormErrorText error>{messages.includTaxAmount}</FormErrorText>
            </Box>
            <Box ml={2}>
              <FormTitle title="会計金額(税抜)" isRequired />
              <MoneyInput
                inputProps={{
                  maxLength: 13,
                }}
                error={messages.excludTaxAmount !== ''}
                onChange={this.onExcludTaxAmountChange}
                value={form.excludTaxAmount}
                name="excludTaxAmount"
              />
              <FormErrorText error>{messages.excludTaxAmount}</FormErrorText>
            </Box>
          </Box>
          <Box mb={2}>
            <FormTitle title="値引き金額" isRequired />
            <MoneyInput
              inputProps={{
                maxLength: 13,
              }}
              error={messages.discountAmount !== ''}
              onChange={this.onChange}
              value={form.discountAmount}
              name="discountAmount"
            />
            <FormErrorText error>{messages.discountAmount}</FormErrorText>
          </Box>
          <Box mb={3} display="flex">
            <Box>
              <FormTitle title="予備会計金額(税込)" />
              <MoneyInput
                inputProps={{
                  maxLength: 13,
                }}
                onChange={this.onChange}
                value={form.spareIncludTaxAmount}
                name="spareIncludTaxAmount"
              />
            </Box>
            <Box ml={2}>
              <FormTitle title="予備会計金額(税抜)" />
              <MoneyInput
                inputProps={{
                  maxLength: 13,
                }}
                onChange={this.onChange}
                value={form.spareExcludTaxAmount}
                name="spareExcludTaxAmount"
              />
            </Box>
          </Box>
          <Box mb={2}>
            <FormTitle title="予備値引き金額" />
            <MoneyInput
              inputProps={{
                maxLength: 13,
              }}
              onChange={this.onChange}
              value={form.spareDiscountAmount}
              name="spareDiscountAmount"
            />
          </Box>
          <Box>
            <FormTitle title="来店人数" isRequired />
            <PersonInput
              inputProps={{
                maxLength: 13,
              }}
              error={messages.visitors !== ''}
              onChange={this.onChange}
              value={form.visitors}
              name="visitors"
            />
            <FormErrorText error>{messages.visitors}</FormErrorText>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>キャンセル</CancelButton>
          <SaveButton onClick={this.handleSave} loading={btnLoading}>保存</SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  visitStatusDetail: state.visitStatusDetail,
  loginUser: state.loginUser,
});

export default connect(mapStateToProps)(VisitStatusDetail);
