export const ACTION_TYPE = {
  CALL_API_UPDATE_MEMO: 'CALL_API_UPDATE_MEMO',
  CALL_API_UPDATE_MEMO_SUCCESS: 'CALL_API_UPDATE_MEMO_SUCCESS',
  CALL_API_GET_MEMO_DETAIL: 'CALL_API_GET_MEMO_DETAIL',
  CALL_API_GET_MEMO_DETAIL_SUCCESS: 'CALL_API_GET_MEMO_DETAIL_SUCCESS',
  RESET_MEMO_DETAIL_FLG: 'RESET_MEMO_DETAIL_FLG',
};

export const actCallApiUpdateMemo = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMO,
  param,
});

export const actCallApiUpdateMemoSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMO_SUCCESS,
  data,
});

export const actCallApiGetMemoDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMO_DETAIL,
  param,
});

export const actCallApiGetMemoDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMO_DETAIL_SUCCESS,
  data,
});

export const actResetMemoDetailFlg = () => ({
  type: ACTION_TYPE.RESET_MEMO_DETAIL_FLG,
});
