import React from 'react';
import { Box } from '@material-ui/core';

export default function Item(props) {
  const { children } = props;

  return (
    <Box p={2} boxShadow={3}>{children}</Box>
  );
}
