export function getExcludTaxAmount(amount, usageCode, usages, excludTaxAmount) {
  if (usageCode === '3') {
    return excludTaxAmount;
  }
  if (amount === '0' || !amount) {
    return '0';
  }
  const usageList = usages.filter((row) => row.code === usageCode);
  if (usageList.length === 0 || !usageList[0].overview) {
    return '0';
  }
  const tax = usageList[0].overview / 100 + 1;
  const excludAmount = amount / tax;
  return String(Math.round(excludAmount));
}

export function getDiscountAmount(amount, member) {
  if (!amount || amount === '0' || !member || member.gradeCode !== 4) {
    return '0';
  }
  const discountBeforeAmount = Math.round(amount / 0.95);
  return String(discountBeforeAmount - amount);
}

export function getNewForm() {
  return {
    store: null,
    member: {},
    memberNo: '',
    visitedAt: '',
    includTaxAmount: '',
    excludTaxAmount: '',
    discountAmount: '',
    spareIncludTaxAmount: '',
    spareExcludTaxAmount: '',
    spareDiscountAmount: '',
    visitors: '',
    usageCode: 1,
  };
}

export function getNewMessages() {
  return {
    store: '',
    visitedAt: '',
    memberNo: '',
    includTaxAmount: '',
    excludTaxAmount: '',
    discountAmount: '',
    visitors: '',
  };
}
