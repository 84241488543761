import { call, put, takeLatest } from 'redux-saga/effects';
import { getStores } from '../../../services/common/selectList.service';
import getSummary from '../../../services/memberAnalysis/memberAnalysis.service';
import {
  ACTION_TYPE,
  actCallApiGetSelectListSuccess,
  actCallApiGetMemberAnalysisListSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 店舗取得
 * @param {*} param0
 */
function* callApiGetStore() {
  try {
    const stores = yield call(getStores);
    yield put(actCallApiGetSelectListSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員分析サマリを取得
 * @param {*} param0
 */
function* callApiGetSummary({ param }) {
  try {
    const datas = yield call(getSummary, param);
    yield put(actCallApiGetMemberAnalysisListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemberAnalysis() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS_SELECT, callApiGetStore);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS, callApiGetSummary);
}
