import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Box, ToggleButtonGroup, ToggleButton, Table, TableHead,
  TableContainer, TableRow, TableCell, TableBody,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import {
  ResponsiveContainer, ComposedChart,
  Line, Bar, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend,
} from 'recharts';
import Item from '../component/Item';
import ItemTitle from '../component/ItemTitle';
import {
  getChartTooltipFormat, getChartLegendFormat, getXlineFormat, getSummaryKey,
} from '../store-proc';
import { actCallApiGetStoreAllVisitStatus } from '../../../../redux/visit/storeVisitStatus/action';

class AllVisitItem extends React.Component {
  constructor() {
    super();
    this.state = {
      alignment: 'time',
      searchCondition: {},
      datas: {
        datas: [],
        includTaxAmount: 0,
        excludTaxAmount: 0,
        discountAmount: 0,
        discountBeforeAmount: 0,
        visit: 0,
      },
      displayType: '0',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSearch !== this.props.isSearch) {
      if (this.props.isSearch) {
        this.clearSearch();
      }
    }
    if (prevProps.storeVisitStatus.allDatas !== this.props.storeVisitStatus.allDatas) {
      if (this.props.storeVisitStatus.allDatas) {
        this.setDatas(this.props.storeVisitStatus.allDatas);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  }

  clearSearch = () => {
    this.props.onSearchClear();
    this.search(this.props.searchCondition, 'time');
    this.setState({
      alignment: 'time',
      searchCondition: this.props.searchCondition,
    });
  }

  onChange = (_, alignment) => {
    if (alignment === null) {
      return;
    }
    const { searchCondition } = this.state;
    this.setState({ alignment });
    this.search(searchCondition, alignment);
  }

  search = (searchCondition, searchType) => {
    const searchMemberGrades = searchCondition.searchMemberGrades.map((n) => n.id);
    this.props.dispatch(actCallApiGetStoreAllVisitStatus({
      searchMemberGrades,
      searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
      searchStartVisitDate: searchCondition.searchStartVisitDate,
      searchEndVisitDate: searchCondition.searchEndVisitDate,
      searchType,
    }));
  }

  onDisplayChange = (event) => {
    this.setState({ displayType: event.target.value });
  }

  render() {
    const {
      alignment, datas, displayType,
    } = this.state;

    return (
      <Item>
        <Box display="flex" mb={2}>
          <ItemTitle>全体</ItemTitle>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            size="small"
            onChange={this.onChange}
          >
            <ToggleButton value="time">時間</ToggleButton>
            <ToggleButton value="day">日</ToggleButton>
            <ToggleButton value="week">曜日</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">金額表示形式</FormLabel>
            <RadioGroup row value={displayType} onChange={this.onDisplayChange}>
              <FormControlLabel value="0" control={<Radio />} label="合計会計金額(税込)" />
              <FormControlLabel value="1" control={<Radio />} label="合計会計金額(税抜)" />
              <FormControlLabel value="2" control={<Radio />} label="合計値引き額" />
              <FormControlLabel value="3" control={<Radio />} label="合計値引き前金額(税抜)" />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={datas.datas}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />

                <XAxis dataKey="type" scale="band" tickFormatter={(tickItem) => getXlineFormat(tickItem, alignment)} />
                <YAxis orientation="left" unit="人" />
                <YAxis orientation="right" yAxisId="rightYAxis" unit="円" tickFormatter={(item) => item.toLocaleString()} />
                <Tooltip formatter={(value, name) => getChartTooltipFormat(value, name)} />
                <Legend formatter={(value, name) => getChartLegendFormat(value, name)} />
                <Bar dataKey="visit" barSize={20} fill="#8884d8" />
                <Line type="monotone" dataKey={getSummaryKey(displayType)} stroke="#008000" yAxisId="rightYAxis" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Box>

        <Box mt={5}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>来店者数</TableCell>
                  <TableCell>合計会計金額(税込)</TableCell>
                  <TableCell>合計会計金額(税抜)</TableCell>
                  <TableCell>合計値引き額</TableCell>
                  <TableCell>合計値引き前金額(税抜)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{datas.visit.toLocaleString()}</TableCell>
                  <TableCell>{datas.includTaxAmount.toLocaleString()}</TableCell>
                  <TableCell>{datas.excludTaxAmount.toLocaleString()}</TableCell>
                  <TableCell>{datas.discountAmount.toLocaleString()}</TableCell>
                  <TableCell>{datas.discountBeforeAmount.toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </Item>
    );
  }
}

const mapStateToProps = (state) => ({
  storeVisitStatus: state.storeVisitStatus,
});

export default connect(mapStateToProps)(AllVisitItem);
