export function getMonths() {
  return [
    { value: '01', label: '1月' },
    { value: '02', label: '2月' },
    { value: '03', label: '3月' },
    { value: '04', label: '4月' },
    { value: '05', label: '5月' },
    { value: '06', label: '6月' },
    { value: '07', label: '7月' },
    { value: '08', label: '8月' },
    { value: '09', label: '9月' },
    { value: '10', label: '10月' },
    { value: '11', label: '11月' },
    { value: '12', label: '12月' },
  ];
}

export function getKeywordHelper() {
  const longText = `
氏名、氏名フリガナ、電話番号、メールアドレス、郵便番号、住所、住所フリガナ、勤務先、勤務先フリガナ、勤務先電話番号、役職が検索できます。
複数のキーワードを検索する場合は、スペース区切りで入力してください。
`;
  return longText;
}

export function getCsvItem() {
  return [
    { label: '会員番号', key: 'memberNo' },
    { label: '姓', key: 'lastName' },
    { label: '名', key: 'firstName' },
    { label: '姓フリガナ', key: 'lastNameKana' },
    { label: '名フリガナ', key: 'firstNameKana' },
    { label: '登録店舗', key: 'storeName' },
    { label: '性別', key: 'gender' },
    { label: '電話番号', key: 'phone' },
    { label: 'メールアドレス', key: 'email' },
    { label: '生年月日', key: 'birthday' },
    { label: '郵便番号', key: 'zipCode' },
    { label: '都道府県', key: 'prefecture' },
    { label: '市区町村', key: 'city' },
    { label: '町名・番地・部屋番号', key: 'street' },
    { label: '都道府県フリガナ', key: 'prefectureKana' },
    { label: '市区町村フリガナ', key: 'cityKana' },
    { label: '町名・番地・部屋番号フリガナ', key: 'streetKana' },
    { label: '誤住所', key: 'incorrectAddress' },
    { label: '勤務先', key: 'workPlace' },
    { label: '勤務先フリガナ', key: 'workPlaceKana' },
    { label: '勤務先電話番号', key: 'workPlacePhone' },
    { label: '役職', key: 'position' },
    { label: '会員グレード', key: 'grade' },
    { label: '会員ランク', key: 'memberRank' },
    { label: '入会日', key: 'enrolledOn' },
    { label: '最新メモ', key: 'content' },
  ];
}
