// region Type
export const ACTION_TYPE = {
  CALL_API_GET_MEMBER_ANALYSIS_SELECT: 'CALL_API_GET_MEMBER_ANALYSIS_SELECT',
  CALL_API_GET_MEMBER_ANALYSIS_SELECT_SUCCESS: 'CALL_API_GET_MEMBER_ANALYSIS_SELECT_SUCCESS',
  CALL_API_GET_MEMBER_ANALYSIS: 'CALL_API_GET_MEMBER_ANALYSIS',
  CALL_API_GET_MEMBER_ANALYSIS_SUCCESS: 'CALL_API_GET_MEMBER_ANALYSIS_SUCCESS',
};
// endregion

// region action
export const actCallApiGetSelectList = () => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS_SELECT,
});

export const actCallApiGetSelectListSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS_SELECT_SUCCESS,
  stores,
});

export const actCallApiGetMemberAnalysisList = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS,
  param,
});

export const actCallApiGetMemberAnalysisListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS_SUCCESS,
  datas,
});

// endregion
