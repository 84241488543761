import { call, put, takeLatest } from 'redux-saga/effects';
import { login, getMe } from '../../../services/other/login.service';
import {
  ACTION_TYPE,
  actLoginSuccess, actLoginError,
  actGetMeError, actGetMeSuccess,
} from './action';

/**
 * Function using for call api login
 *
 * @param userInfo
 */
function* callApiLogin({ userInfo }) {
  try {
    const dataUserModel = yield call(login, userInfo);
    yield put(actLoginSuccess(dataUserModel));
  } catch (error) {
    yield put(actLoginError(error));
  }
}

function* callApiGetMe() {
  try {
    const result = yield call(getMe);
    yield put(actGetMeSuccess(result));
  } catch (error) {
    yield put(actGetMeError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallLogin() {
  yield takeLatest(ACTION_TYPE.CALL_API_LOGIN, callApiLogin);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ME, callApiGetMe);
}
