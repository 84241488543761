import * as React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Paper } from '@material-ui/core';
import { getChartTooltipFormat, getChartLegendFormat } from './daily-proc';

export default function DailyCharts(props) {
  const { rows } = props;

  return (
    <>
      <Paper>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={rows}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />

              <XAxis dataKey="storeName" scale="band" />
              <YAxis orientation="left" dataKey="visitNumber" unit="人" />
              <YAxis orientation="right" dataKey="includTaxAmount" yAxisId="rightYAxis" unit="円" tickFormatter={(item) => item.toLocaleString()} />
              <Tooltip formatter={(value, name) => getChartTooltipFormat(value, name)} />
              <Legend formatter={(value, name) => getChartLegendFormat(value, name)} />
              <Bar dataKey="visitNumber" barSize={20} fill="#413ea0" />
              <Line type="monotone" dataKey="includTaxAmount" stroke="#ff7300" yAxisId="rightYAxis" />
              <Line type="monotone" dataKey="excludTaxAmount" stroke="#008000" yAxisId="rightYAxis" />
              <Line type="monotone" dataKey="discountAmount" stroke="#dc143c" yAxisId="rightYAxis" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Paper>
    </>
  );
}
