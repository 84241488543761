import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  ListItem, ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { isShowItem } from '../check-show-screen';

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class HeaderMenuItem extends React.Component {
  onClick = (_, link) => {
    this.props.history.push({
      pathname: link,
    });
    this.props.onClose();
  }

  render() {
    const {
      classes, isNested, url, menuName, loginUser, location,
    } = this.props;

    const screenList = loginUser.user ? loginUser.user.screenList : [];

    const isShow = isShowItem(screenList, url);
    const path = `/${location.pathname.split('/')[1]}`;

    return (
      <ListItem
        button
        className={isNested ? classes.nested : ''}
        onClick={(event) => this.onClick(event, url)}
        style={{ display: isShow ? '' : 'none' }}
        selected={url === path}
      >
        <ListItemText primary={menuName} />
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginUser,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(HeaderMenuItem)));
