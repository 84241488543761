import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getScreens, getRoles, getRoleDetails, insertData, updateRoleScreen, updateData,
} from '../../../services/user/roleDetail.service';
import {
  ACTION_TYPE,
  actCallApiGetScreensSuccess, actCallApiGetRoleDetailSuccess, actCallApiUpdateRoleScreenSuccess,
  actCallApiCreateRoleSuccess, actCallApiUpdateRoleSuccess, actCallApiGetRoleSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 初期表示
 *
 * @param
 */
function* callApiGetScreens() {
  try {
    const screens = yield call(getScreens);
    yield put(actCallApiGetScreensSuccess(screens));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限取得
 *
 * @param {*} param0
 */
function* callApiGetRole({ param }) {
  try {
    const data = yield call(getRoles, param);
    yield put(actCallApiGetRoleSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限詳細取得
 *
 * @param {*} param0
 */
function* callApiGetRoleDetail({ param }) {
  try {
    const data = yield call(getRoleDetails, param);
    yield put(actCallApiGetRoleDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限画面更新
 *
 * @param
 */
function* callApiUpdateRoleScreen({ param }) {
  try {
    const data = yield call(updateRoleScreen, param);
    yield put(actCallApiUpdateRoleScreenSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限追加
 *
 * @param
 */
function* callApiInsertRole({ param }) {
  try {
    const data = yield call(insertData, param);
    yield put(actCallApiCreateRoleSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 権限更新
 *
 * @param
 */
function* callApiUpdateRole({ param }) {
  try {
    const data = yield call(updateData, param);
    yield put(actCallApiUpdateRoleSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallRoleDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_ROLE_GET_SCREENS, callApiGetScreens);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ROLE, callApiGetRole);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ROLE_DETAIL, callApiGetRoleDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREEN, callApiUpdateRoleScreen);
  yield takeLatest(ACTION_TYPE.CALL_API_ROLE_DETAIL_CREATE, callApiInsertRole);
  yield takeLatest(ACTION_TYPE.CALL_API_ROLE_DETAIL_UPDATE, callApiUpdateRole);
}
