import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import '../public/css/style.css';
import {
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import store from '../store/store';
import Login from './other/login/Login';
import Routes from './common/Routes';
import Auth from './common/Auth';
import Loading from '../components/atoms/loading/Loading';
import urls from '../constants/urls';
import Header from './common/header/Header';
import ServerErrorProc from './common/ServerErrorProc';

const theme = createTheme({
  palette: {
    primary: {
      light: '#3a3736',
      main: '#090604',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff3333',
      main: '#ff0000',
      dark: '#b20000',
      contrastText: '#fff',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: '16px 0',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#E8F0FE',
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          background: '#fff',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          wordBreak: 'break-all',
        },
        head: {
          background: 'rgb(245, 245, 245)',
          fontWeight: '600',
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    TableSortLabel: {
      styleOverrides: {
        active: {
          color: '#000',
        },
      },
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Loading />
        <ServerErrorProc />
        <Router history={createHashHistory()}>
          <Switch>
            <Route exact path={urls.OTHER.LOGIN} component={Login} />
            <Auth>
              <Header>
                <Routes />
              </Header>
            </Auth>
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}
