import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

export default function CollapseList(props) {
  const {
    isShow, menuTitle, onClick, children, isOpen,
  } = props;

  return (
    <List component="nav" style={{ display: isShow ? '' : 'none' }}>
      <ListItem button onClick={onClick}>
        <ListItemText disableTypography style={{ fontWeight: 'bold' }}>{menuTitle}</ListItemText>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  );
}
