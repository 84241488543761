import React from 'react';
import ReactDOM from 'react-dom';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './pages/App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <App />
  </StyledEngineProvider>,
  document.getElementById('root'),
);
