import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteStore, getData } from '../../../services/setting/storeList.service';
import { actCallApiServerError } from '../../common/common/action';
import { actCallApiDeleteStoreSuccess, actCallApiGetStoreSuccess, ACTION_TYPE } from './action';

/**
 * 店舗取得
 *
 */
function* callApiGetStore({ param }) {
  try {
    const datas = yield call(getData, param);
    yield put(actCallApiGetStoreSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param {*} param0
 */
function* callApiDeleteStore({ param }) {
  try {
    yield call(deleteStore, param);
    yield put(actCallApiDeleteStoreSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallStoreList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE, callApiGetStore);
  yield takeLatest(ACTION_TYPE.CALL_API_DELETE_STORE, callApiDeleteStore);
}
