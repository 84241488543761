import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Checkbox, Tooltip, IconButton,
  Button, Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  Delete as DeleteIcon,
  Close as CloseIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Feed as FeedIcon,
  BorderColor as BorderColorIcon,
  Download as DownloadIcon,
} from '@material-ui/icons';
import DeleteConfirmDialog from '../deleteConfirmDialog/DeleteConfirmDialog';
import Pagination from '../../atoms/pagination/Pagination';
import TableHeaderCell from '../../atoms/tableHeaderCell/TableHeaderCell';

const styles = () => ({
  highlight: {
    backgroundColor: '#E8F0FE',
  },
  title: {
    flex: '1 1 50%',
  },
  emptyTableCell: {
    textAlign: 'center',
  },
  downloadRoot: {
    marginRight: '10px',
  },
});

class DeleteDataTable extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: [],
      deleteConfopen: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      if (this.props.rows) {
        this.handleHeaderClose();
      }
    }
  }

  handleSelectAllClick = (event) => {
    const { rows } = this.props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.deleteable)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
  };

  handleClick = (_, name) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleHeaderClose = () => {
    this.setState({ selected: [] });
  }

  onPreDelete = () => {
    this.setState({ deleteConfopen: true, loading: false });
  }

  onDelete = () => {
    const { selected } = this.state;
    this.setState({ deleteConfopen: false, selected: [], loading: true });
    this.props.onDelete(selected);
  }

  handleClose = () => {
    this.setState({ deleteConfopen: false });
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      rows,
      procName,
      onAdd,
      onDownload,
      headCells,
      onDetail,
      secondFuncText,
      onSecondFunc,
      totalElements,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      handleRequestSort,
      order,
      orderBy,
    } = this.props;
    const {
      selected, deleteConfopen, loading,
    } = this.state;

    const addProc = onAdd || onDownload ? (
      <>
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div" />
        {onDownload && (
          <Tooltip title="CSVの出力">
            <Button color="secondary" variant="outlined" onClick={onDownload} startIcon={<DownloadIcon />} className={classes.downloadRoot}>CSVの出力</Button>
          </Tooltip>
        )}
        {onAdd && (
          <Tooltip title={`${procName}の追加`}>
            <Button color="secondary" variant="outlined" onClick={onAdd} startIcon={<AddCircleOutlineIcon />}>{`${procName}の追加`}</Button>
          </Tooltip>
        )}
      </>
    ) : null;

    const colSpan = headCells.length + (onDetail ? 1 : 0) + (secondFuncText ? 1 : 0) + 1;

    return (
      <div>
        <Paper>
          <Toolbar className={selected.length > 0 ? classes.highlight : ''}>
            {selected.length > 0 ? (
              <>
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                  <IconButton aria-label="delete" size="medium" onClick={this.handleHeaderClose}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  {selected.length}
                  {' '}
                  件選択中
                </Typography>
                <Tooltip title={`${procName}の削除`}>
                  <Button color="secondary" variant="outlined" onClick={this.onPreDelete} startIcon={<DeleteIcon />}>{`${procName}の削除`}</Button>
                </Tooltip>
              </>
            ) : addProc}
          </Toolbar>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length === selected.length
                      }
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((row) => row.deleteable).length === 0}
                    />
                  </TableCell>
                  {headCells.map((headCell) => (
                    <TableHeaderCell
                      headCell={headCell}
                      orderBy={orderBy}
                      order={order}
                      key={headCell.id}
                      handleRequestSort={handleRequestSort}
                    />
                  ))}
                  {onDetail && <TableCell />}
                  {secondFuncText && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = this.isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {row.deleteable && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => this.handleClick(event, row.id)}
                          />
                        )}
                      </TableCell>
                      {Object.keys(row).filter((value) => value !== 'id' && value !== 'deleteable' && value !== 'memberNameKana' && value !== 'memberAddress' && value !== 'memberAddressKana')
                        .map((key) => {
                          let dom = (
                            <TableCell key={key}>
                              {row[key]}
                            </TableCell>
                          );
                          if (key === 'memberName') {
                            dom = (
                              <TableCell key="memberName">
                                <Box>{row.memberNameKana}</Box>
                                <Box>{row.memberName}</Box>
                              </TableCell>
                            );
                          }
                          if (key === 'zipCode') {
                            dom = (
                              <TableCell key="memberAddress">
                                <Box display={row.zipCode ? '' : 'none'}>{`〒${row.zipCode}`}</Box>
                                <Box>{row.memberAddressKana}</Box>
                                <Box>{row.memberAddress}</Box>
                              </TableCell>
                            );
                          }
                          return dom;
                        })}
                      {onDetail && <TableCell><Button color="secondary" variant="outlined" onClick={(event) => onDetail(event, row)} startIcon={<FeedIcon />}>詳細を見る</Button></TableCell>}
                      {secondFuncText && <TableCell><Button color="secondary" variant="outlined" onClick={(event) => onSecondFunc(event, row)} startIcon={<BorderColorIcon />}>{secondFuncText}</Button></TableCell>}
                    </TableRow>
                  );
                })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell className={classes.emptyTableCell} colSpan={colSpan}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <DeleteConfirmDialog
            open={deleteConfopen}
            onClose={this.handleClose}
            onDelete={this.onDelete}
            procName={procName}
            loading={loading}
          />
        </Paper>
      </div>
    );
  }
}

DeleteDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onAdd: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onDetail: PropTypes.func,
  secondFuncText: PropTypes.string,
  onSecondFunc: PropTypes.func,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

DeleteDataTable.defaultProps = {
  onAdd: null,
  onDetail: null,
  secondFuncText: null,
  onSecondFunc: null,
  totalElements: 0,
};

export default withStyles(styles)(DeleteDataTable);
