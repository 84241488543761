import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Container, Stack } from '@material-ui/core';
import {
  BorderColor as BorderColorIcon,
  DirectionsWalk as DirectionsWalkIcon,
  AssignmentInd as AssignmentIndIcon,
} from '@material-ui/icons';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import Menu from './Menu';
import urls from '../../../constants/urls';

class Home extends Component {
  constructor(props) {
    super(props);
    props.dispatch(actSetBreadcrumbs(null));
  }

  render() {
    return (
      <Container>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Menu icon={<DirectionsWalkIcon />} title="来店登録" content="会員様毎に来店履歴を管理します" url={urls.VISIT.VISIT_REGIST} />
          <Menu icon={<BorderColorIcon />} title="メモ管理" content="会員様毎に管理用メモを管理します" url={urls.MEMBER.MEMO} />
          <Menu icon={<AssignmentIndIcon />} title="会員一覧" content="会員様情報を管理します" url={urls.MEMBER.MEMBERS} />
        </Stack>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
});

export default connect(mapStateToProps)(Home);
