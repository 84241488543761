import { ACTION_TYPE } from './action';

const roleDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_ROLE_GET_SCREENS_SUCCESS:
      return {
        ...state,
        screens: action.screens,
      };
    case ACTION_TYPE.CALL_API_GET_ROLE_SUCCESS:
      return {
        ...state,
        role: action.data,
      };
    case ACTION_TYPE.CALL_API_GET_ROLE_DETAIL_SUCCESS:
      return {
        ...state,
        roleDetail: action.data,
      };
    case ACTION_TYPE.CALL_API_UPDATE_ROLE_SCREEN_SUCCESS:
      return {
        ...state,
        roleScreens: action.roleScreens,
        isRoleScreenUpdate: true,
      };
    case ACTION_TYPE.CALL_API_ROLE_DETAIL_CREATE_SUCCESS:
      return {
        ...state,
        role: action.data,
        isRoleCreated: true,
      };
    case ACTION_TYPE.CALL_API_ROLE_DETAIL_UPDATE_SUCCESS:
      return {
        ...state,
        role: action.data,
        isRoleUpdated: true,
      };
    case ACTION_TYPE.RESET_ROLE_DETAIL_FLG:
      return {
        ...state,
        isRoleScreenUpdate: false,
        isRoleCreated: false,
        isRoleUpdated: false,
        isRoleCreatedMessage: false,
      };
    case ACTION_TYPE.SET_ROLE_CREATED:
      return {
        ...state,
        isRoleCreatedMessage: true,
      };
    default:
      return state;
  }
};

export default roleDetail;
