import { call, put, takeLatest } from 'redux-saga/effects';
import { getDatas, deleteRoles } from '../../../services/user/roleList.service';
import { getStores } from '../../../services/common/selectList.service';
import {
  ACTION_TYPE,
  actCallApiGetRoleListSuccess,
  actCallApiRoleDeleteSuccess,
  actCallApiSelectRoleSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 店舗取得
 *
 */
function* callApiGetStores() {
  try {
    const stores = yield call(getStores);
    yield put(actCallApiSelectRoleSuccess(stores));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param
 */
function* callApiRoleList({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiGetRoleListSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param
 */
function* callApiRoleListDelete({ values }) {
  try {
    yield call(deleteRoles, values);
    yield put(actCallApiRoleDeleteSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallRoleList() {
  yield takeLatest(ACTION_TYPE.CALL_API_SELECT_ROLES, callApiGetStores);
  yield takeLatest(ACTION_TYPE.CALL_API_ROLES_SEARCH, callApiRoleList);
  yield takeLatest(ACTION_TYPE.CALL_API_ROLE_DELETE, callApiRoleListDelete);
}
