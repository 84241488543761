import { ACTION_TYPE } from './action';

// region Reducer
const memberAnalysis = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS_SELECT_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_GET_MEMBER_ANALYSIS_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    default:
      return state;
  }
};

export default memberAnalysis;
