import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * メモ登録
 * @param {*} param
 * @returns
 */
export function create(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MEMBER.MEMO.MEMOS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * メモ更新
 * @param {*} param
 * @returns
 */
export function update(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.MEMBER.MEMO.MEMO, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 一件取得処理
 *
 * @param {*} param
 * @returns
 */
export function getData(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .getById(REST_API.MEMBER.MEMO.MEMO, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
