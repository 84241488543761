import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { TextField, Box, Container } from '@material-ui/core';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiSelectSystemUser,
  actCallApiGetSystemUser,
  actCallApiSystemUserDelete,
  actResetSystemUserFlg,
} from '../../../redux/user/systemUserList/action';
import getAdmin from '../../../helpers/common.helper';
import { actCallApiMe } from '../../../redux/other/login/action';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import SystemUserDetail from '../systemUserDetail/SystemUserDetail';
import ChangePassword from './changePassword/ChangePassword';
import LogoutModal from './LogoutModal';

class SystemUserList extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallApiSelectSystemUser());

    props.dispatch(actSetBreadcrumbs([{ name: 'システムユーザー' }]));

    this.headCells = [
      {
        id: 'name', numeric: false, label: '名前', sortKey: 'name',
      },
      {
        id: 'authName', numeric: false, label: '権限', sortKey: 'auth_name',
      },
      {
        id: 'storeName', numeric: false, label: '店舗', sortKey: 'store_name',
      },
    ];

    props.dispatch(
      actCallApiGetSystemUser({
        page: 0,
        records: 10,
      }),
    );

    this.state = {
      selects: {
        stores: [],
        roles: [],
      },
      datas: [],
      searchCondition: {
        searchStore: null,
        searchAuth: null,
        searchName: '',
        page: 0,
        records: 10,
        order: 'asc',
        orderBy: '',
      },
      isLogoutOpen: false,
      isSuccessOpen: false,
      successMessage: '',
      isDetailOpen: false,
      selectedRow: null,
      isChangePasswordOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.systemUserList.datas !== this.props.systemUserList.datas) {
      if (this.props.systemUserList.datas) {
        this.setDatas(this.props.systemUserList.datas);
      }
    }
    if (prevProps.loginUser.user !== this.props.loginUser.user) {
      if (this.props.loginUser.user) {
        this.onStoreChangeSearch(this.props.loginUser.user.store);
      }
    }
    if (prevProps.systemUserList.selects !== this.props.systemUserList.selects) {
      if (this.props.systemUserList.selects) {
        this.setSelects(this.props.systemUserList.selects);
      }
    }
    if (prevProps.systemUserList.isDeleteSuccess !== this.props.systemUserList.isDeleteSuccess) {
      if (this.props.systemUserList.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
    if (prevProps.systemUserDetail.isCreateSuccess
      !== this.props.systemUserDetail.isCreateSuccess) {
      if (this.props.systemUserDetail.isCreateSuccess) {
        this.setDetailSuccess(this.props.systemUserDetail.resultData, '保存', 0);
      }
    }
    if (prevProps.systemUserDetail.isUpdateSuccess
      !== this.props.systemUserDetail.isUpdateSuccess) {
      if (this.props.systemUserDetail.isUpdateSuccess) {
        this.setDetailSuccess(this.props.systemUserDetail.resultData, '保存', 1);
      }
    }
    if (prevProps.systemUserDetail.isChangePasswordSuccess
      !== this.props.systemUserDetail.isChangePasswordSuccess) {
      if (this.props.systemUserDetail.isChangePasswordSuccess) {
        this.setDetailSuccess(this.props.systemUserDetail.resultData, 'パスワード変更', 2);
      }
    }
  }

  setDetailSuccess(data, proc, procType) {
    const loginUser = this.props.loginUser ? this.props.loginUser.user.user : {};
    const isSameUser = loginUser.id === data.id;
    const isChangeLoginId = loginUser.loginId !== data.loginId;
    let isLogoutOpen = false;
    let isSuccessOpen = true;
    if ((isSameUser && procType === 1 && isChangeLoginId) || (isSameUser && procType === 2)) {
      isLogoutOpen = true;
      isSuccessOpen = false;
    }
    if (isSameUser && procType === 1 && !isChangeLoginId) {
      this.props.dispatch(actCallApiMe());
    }

    const name = `${data.lastName} ${data.firstName}`;
    this.setState({
      isSuccessOpen, isLogoutOpen, successMessage: `${name}の${proc}が完了しました`, isDetailOpen: false, isChangePasswordOpen: false,
    });

    if (!isLogoutOpen) {
      this.onSearch();
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSelects(selects) {
    this.setState({ selects });
  }

  setDeleteSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage: 'システムユーザーの削除が完了しました' });
  }

  onStoreChangeSearch = (searchStore) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStore,
      page: 0,
      records: 10,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  }

  onClickSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.onSearch();
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (value) => {
    this.props.dispatch(actResetSystemUserFlg());
    this.props.dispatch(
      actCallApiSystemUserDelete({
        deletes: value,
      }),
    );
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  }

  onAdd = () => {
    this.setState({ isDetailOpen: true, selectedRow: null });
  }

  onDetail = (_, row) => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, selectedRow: row });
  }

  onChangePassword = (_, row) => {
    this.setState({
      isChangePasswordOpen: true,
      selectedRow: row,
      isSuccessOpen: false,
    });
  }

  handleDetailClose = () => {
    this.setState({ isDetailOpen: false });
  }

  passwordDialogClose = () => {
    this.setState({ isChangePasswordOpen: false });
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(
      actCallApiGetSystemUser({
        page: searchCondition.page,
        records: searchCondition.records,
        searchStoreId:
        searchCondition.searchStore && searchCondition.searchStore.id,
        searchAuthId: searchCondition.searchAuth && searchCondition.searchAuth.id,
        searchName: searchCondition.searchName,
      }),
    );
  }

  render() {
    const {
      selects,
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDetailOpen,
      selectedRow,
      isChangePasswordOpen,
      isLogoutOpen,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <Container>
        <SearchBox onSearch={this.onClickSearch}>
          <Box display="flex">
            {admin && (
              <Box mr={2} width="30%">
                <FormTitle title="店舗" />
                <SearchSelectBox
                  options={selects.stores}
                  value={searchCondition.searchStore}
                  onChange={this.onChange}
                  name="searchStore"
                />
              </Box>
            )}

            <Box width="30%">
              <FormTitle title="権限" />
              <SearchSelectBox
                options={selects.roles}
                value={searchCondition.searchAuth}
                onChange={this.onChange}
                name="searchAuth"
              />
            </Box>
          </Box>
          <Box mt={2} width="60%">
            <FormTitle title="名前" />
            <TextField fullWidth name="searchName" variant="outlined" autoComplete="off" value={searchCondition.searchName} onChange={this.onChange} />
          </Box>
        </SearchBox>

        <DeleteDataTable
          rows={datas.content || []}
          headCells={this.headCells}
          procName="システムユーザー"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          onAdd={this.onAdd}
          onDetail={this.onDetail}
          onSecondFunc={this.onChangePassword}
          secondFuncText="パスワードを変更"
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
        <LogoutModal content={successMessage} open={isLogoutOpen} />
        <SystemUserDetail
          open={isDetailOpen}
          data={selectedRow}
          handleClose={this.handleDetailClose}
        />
        <ChangePassword
          open={isChangePasswordOpen}
          onClose={this.passwordDialogClose}
          data={selectedRow}
          updateSuccess={this.changePasswordSuccess}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  systemUserList: state.systemUserList,
  loginUser: state.loginUser,
  systemUserDetail: state.systemUserDetail,
});

export default connect(mapStateToProps)(SystemUserList);
