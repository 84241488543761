import React from 'react';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

export default function CancelButton(props) {
  const { children, onClick } = props;

  return (
    <Button onClick={onClick} variant="outlined" startIcon={<CancelIcon />}>{children}</Button>
  );
}
