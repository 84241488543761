import { call, put, takeLatest } from 'redux-saga/effects';
import { getStores } from '../../../services/common/selectList.service';
import {
  ACTION_TYPE,
  actCallSelectMemoStoresSuccess,
  actCallApiMemoSearchSuccess,
  actCallApiDeleteMemoSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';
import { getDatas, deleteMemos } from '../../../services/member/memoList.service';

/**
 * コンボボックス用一覧取得
 *
 */
function* callApiSelectMemoStore() {
  try {
    const selects = yield call(getStores);
    yield put(actCallSelectMemoStoresSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 検索処理
 *
 * @param {*} param0
 */
function* callApiGetMemos({ searchCondition }) {
  try {
    const datas = yield call(getDatas, searchCondition);
    yield put(actCallApiMemoSearchSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 削除処理
 *
 * @param {*} param0
 */
function* callApiDeleteMemos({ param }) {
  try {
    yield call(deleteMemos, param);
    yield put(actCallApiDeleteMemoSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMemoList() {
  yield takeLatest(ACTION_TYPE.CALL_API_SELECT_MEMO_STORES, callApiSelectMemoStore);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMO_SEARCH, callApiGetMemos);
  yield takeLatest(ACTION_TYPE.CALL_API_DELETE_MEMO, callApiDeleteMemos);
}
