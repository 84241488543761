function getDates() {
  return [
    { key: 'menVisit', label: '男性来店者数' },
    { key: 'womenVisit', label: '女性来店者数' },
    { key: 'otherVisit', label: 'その他来店者数' },
    { key: 'menIncludTaxAmount', label: '男性会計金額(税込)' },
    { key: 'menExcludTaxAmount', label: '男性会計金額(税抜)' },
    { key: 'menDiscountAmount', label: '男性値引き額' },
    { key: 'menDiscountBeforeAmount', label: '男性値引き前金額(税抜)' },
    { key: 'womenIncludTaxAmount', label: '女性会計金額(税込)' },
    { key: 'womenExcludTaxAmount', label: '女性会計金額(税抜)' },
    { key: 'womenDiscountAmount', label: '女性値引き額' },
    { key: 'womenDiscountBeforeAmount', label: '女性値引き前金額(税抜)' },
    { key: 'otherIncludTaxAmount', label: 'その他会計金額(税込)' },
    { key: 'otherExcludTaxAmount', label: 'その他会計金額(税抜)' },
    { key: 'otherDiscountAmount', label: 'その他値引き額' },
    { key: 'otherDiscountBeforeAmount', label: 'その他値引き前金額(税抜)' },
    { key: 'visit', label: '来店者数' },
    { key: 'includTaxAmount', label: '会計金額(税込)' },
    { key: 'excludTaxAmount', label: '会計金額(税抜)' },
    { key: 'discountAmount', label: '値引き額' },
    { key: 'discountBeforeAmount', label: '値引き前金額(税抜)' },
    { key: 'visitTwo', label: '～20代来店者数' },
    { key: 'visitThree', label: '30代来店者数' },
    { key: 'visitFour', label: '40代来店者数' },
    { key: 'visitFive', label: '50代来店者数' },
    { key: 'visitSix', label: '60代～来店者数' },
    { key: 'visitOther', label: 'その他来店者数' },
    { key: 'includTaxAmountTwo', label: '～20代会計金額(税込)' },
    { key: 'excludTaxAmountTwo', label: '～20代会計金額(税抜)' },
    { key: 'discountAmountTwo', label: '～20代値引き額' },
    { key: 'discountBeforeAmountTwo', label: '～20代値引き前金額(税抜)' },
    { key: 'includTaxAmountThree', label: '30代会計金額(税込)' },
    { key: 'excludTaxAmountThree', label: '30代会計金額(税抜)' },
    { key: 'discountAmountThree', label: '30代値引き額' },
    { key: 'discountBeforeAmountThree', label: '30代値引き前金額(税抜)' },
    { key: 'includTaxAmountFour', label: '40代会計金額(税込)' },
    { key: 'excludTaxAmountFour', label: '40代会計金額(税抜)' },
    { key: 'discountAmountFour', label: '40代値引き額' },
    { key: 'discountBeforeAmountFour', label: '40代値引き前金額(税抜)' },
    { key: 'includTaxAmountFive', label: '50代会計金額(税込)' },
    { key: 'excludTaxAmountFive', label: '50代会計金額(税抜)' },
    { key: 'discountAmountFive', label: '50代値引き額' },
    { key: 'discountBeforeAmountFive', label: '50代値引き前金額(税抜)' },
    { key: 'includTaxAmountSix', label: '60代会計金額(税込)' },
    { key: 'excludTaxAmountSix', label: '60代会計金額(税抜)' },
    { key: 'discountAmountSix', label: '60代値引き額' },
    { key: 'discountBeforeAmountSix', label: '60代値引き前金額(税抜)' },
    { key: 'includTaxAmountOther', label: 'その他会計金額(税込)' },
    { key: 'excludTaxAmountOther', label: 'その他会計金額(税抜)' },
    { key: 'discountAmountOther', label: 'その他値引き額' },
    { key: 'discountBeforeAmountOther', label: 'その他値引き前金額(税抜)' },

  ];
}
export function getChartTooltipFormat(value, name) {
  const labelMap = getDates();
  const labels = labelMap.filter((data) => data.key === name);
  let label = name;
  if (labels.length > 0) {
    label = labels[0].label;
  }
  return [value.toLocaleString(), label];
}

export function getChartLegendFormat(name) {
  const labelMap = getDates();
  const labels = labelMap.filter((data) => data.key === name);
  let label = name;
  if (labels.length > 0) {
    label = labels[0].label;
  }
  return [label];
}

export function getXlineFormat(tickItem, alignment) {
  if (alignment !== 'week') {
    return tickItem;
  }
  let weekName = '';

  switch (tickItem) {
    case '0':
      weekName = '日曜日';
      break;
    case '1':
      weekName = '月曜日';
      break;
    case '2':
      weekName = '火曜日';
      break;
    case '3':
      weekName = '水曜日';
      break;
    case '4':
      weekName = '木曜日';
      break;
    case '5':
      weekName = '金曜日';
      break;
    case '6':
      weekName = '土曜日';
      break;
    default:
      weekName = '';
      break;
  }
  return weekName;
}

export function getSummaryKey(displayType) {
  let key = '';
  switch (displayType) {
    case '0':
      key = 'includTaxAmount';
      break;
    case '1':
      key = 'excludTaxAmount';
      break;
    case '2':
      key = 'discountAmount';
      break;
    case '3':
      key = 'discountBeforeAmount';
      break;
    default:
      key = '';
      break;
  }
  return key;
}
