import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Dialog, AppBar, Toolbar, IconButton, Box, Slide,
  Container, TextField, FormControl, RadioGroup,
  FormControlLabel, Radio, Switch,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import LoadingButton from '@material-ui/lab/LoadingButton';
import CloseIcon from '@material-ui/icons/Close';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';
import Datepicker from '../../../../components/atoms/datepicker/Datepicker';
import Validation from './validation';
import {
  actCallApiSelectMemberDetail, actResetMemberDetailFlg,
  actCallApiCreateMember, actCallApiUpdateMember,
  actCallApiGetMemberAddress, actSetMemberCreateSuccess,
  actCallApiGetUpdateMemberData, actCallApiCheckExistMember,
} from '../../../../redux/member/memberDetail/action';
import urls from '../../../../constants/urls';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const newForm = {
  memberNo: '',
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  store: {},
  genderCode: '',
  phone: '',
  birthday: '',
  email: '',
  zipCode: '',
  prefecture: '',
  city: '',
  street: '',
  prefectureKana: '',
  cityKana: '',
  streetKana: '',
  incorrectAddress: false,
  workPlace: '',
  workPlaceKana: '',
  workPlacePhone: '',
  position: '',
  gradeCode: '4',
  enrolledOn: '',
};

const newMessage = {
  memberNo: '',
  lastName: '',
  firstName: '',
  zipCode: '',
  email: '',
  store: '',
};

class MemberUpsertModal extends Component {
  constructor(props) {
    super(props);
    props.dispatch(actCallApiSelectMemberDetail());
    this.state = {
      btnLoading: false,
      loading: false,
      form: newForm,
      messages: newMessage,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.memberDetail.isGetAddressSuccess
      !== this.props.memberDetail.isGetAddressSuccess) {
      if (this.props.memberDetail.isGetAddressSuccess) {
        this.setAddress(this.props.memberDetail.address);
      }
    }
    if (prevProps.memberDetail.isCreateSuccess !== this.props.memberDetail.isCreateSuccess) {
      if (this.props.memberDetail.isCreateSuccess) {
        this.setCreateSuccess(this.props.memberDetail.createDate);
      }
    }
    if (prevProps.memberDetail.memberData !== this.props.memberDetail.memberData) {
      if (this.props.memberDetail.memberData) {
        this.setMemberData(this.props.memberDetail.memberData);
      }
    }
    if (prevProps.memberDetail.isUpdateSuccess !== this.props.memberDetail.isUpdateSuccess) {
      if (this.props.memberDetail.isUpdateSuccess) {
        this.updateSuccess();
      }
    }
    if (prevProps.memberDetail.isCheckSuccess !== this.props.memberDetail.isCheckSuccess) {
      if (this.props.memberDetail.isCheckSuccess) {
        this.successMemberNoCheck(this.props.memberDetail.checkData);
      }
    }
    if (prevProps.common.isLoading
      !== this.props.common.isLoading) {
      if (!this.props.common.isLoading) {
        this.setLoading(this.props.common.isLoading);
      }
    }
  }

  setLoading = (btnLoading) => {
    this.setState({ btnLoading });
  }

  successMemberNoCheck = (data) => {
    if (!data) {
      return;
    }
    const { messages } = this.state;
    this.setState({
      messages: { ...messages, memberNo: '既に使用されている会員番号です' },
    });
  }

  updateSuccess = () => {
    this.props.onUpdateSuccess();
  }

  setMemberData = (form) => {
    this.setState({ form, loading: false, btnLoading: false });
  }

  setCreateSuccess = (data) => {
    this.props.dispatch(actSetMemberCreateSuccess());
    this.props.onClose();
    this.props.history.push({
      pathname: urls.MEMBER.MEMBER_DETAIL,
      state: { memberId: data.id },
    });
  }

  setAddress = (data) => {
    if (!data) {
      return;
    }
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        prefecture: data.address1,
        prefectureKana: data.kana1,
        city: data.address2,
        cityKana: data.kana2,
        street: data.address3,
        streetKana: data.kana3,
      },
    });
  }

  init = () => {
    const { memberNo, id } = this.props;
    const store = this.props.loginUser ? this.props.loginUser.user.store : {};
    const form = {
      ...newForm,
      memberNo,
      store,
      enrolledOn: format(new Date(), 'yyyy/MM/dd'),
    };
    let btnLoading = false;
    let loading = false;
    if (id) {
      this.props.dispatch(actCallApiGetUpdateMemberData({ id }));
      btnLoading = true;
      loading = true;
    }
    this.setState({
      form, messages: newMessage, btnLoading, loading,
    });
  }

  onChange = (event) => {
    const { value, name } = event.target;

    const message = Validation.formValidate(name, value);
    const { form, messages } = this.state;
    this.setState({
      form: { ...form, [name]: value },
      messages: { ...messages, [name]: message },
    });

    if (name === 'zipCode' && !message && value.length >= 7) {
      this.props.dispatch(actResetMemberDetailFlg());
      this.props.dispatch(actCallApiGetMemberAddress({ zipCode: value }));
    }
  }

  onChangeBoolean = (event) => {
    const { checked, name } = event.target;
    const { form } = this.state;
    this.setState({
      form: { ...form, [name]: checked },
    });
  }

  onCheckMemberNo = () => {
    const { form } = this.state;
    if (!form.memberNo) {
      return;
    }
    this.props.dispatch(actResetMemberDetailFlg());
    this.props.dispatch(actCallApiCheckExistMember(
      { memberNo: form.memberNo, id: form.id ? form.id : null },
    ));
  }

  onSave = () => {
    const { form, messages } = this.state;
    if (messages.memberNo) {
      return;
    }

    let tempMessages = messages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessages = {
        ...tempMessages,
        [key]: msg,
      };
    });

    this.setState({ messages: tempMessages, btnLoading: !isError });

    if (isError) {
      return;
    }

    const param = {
      ...form,
      storeId: form.store && form.store.id,
    };

    this.props.dispatch(actResetMemberDetailFlg());
    if (form.id) {
      this.props.dispatch(actCallApiUpdateMember(param));
    } else {
      this.props.dispatch(actCallApiCreateMember(param));
    }
  }

  render() {
    const {
      open,
      onClose,
    } = this.props;
    const selects = this.props.memberDetail.selects || {};

    const {
      form, messages, btnLoading, loading,
    } = this.state;

    return (
      <>
        <Dialog
          fullScreen
          open={open}
          onClose={onClose}
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Box sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontWeight="fontWeightBold">
                {form.id ? '会員の編集' : '会員の追加'}
              </Box>
              <LoadingButton variant="outlined" color="inherit" onClick={this.onSave} loading={btnLoading} loadingPosition="start" startIcon={<SaveIcon />}>保存する</LoadingButton>
            </Toolbar>
          </AppBar>
          <Container sx={{ mb: 4, mt: 12, display: loading ? 'none' : '' }}>
            <Box display="flex">
              <Box>
                <FormTitle title="会員番号" isRequired />
                <TextField
                  variant="outlined"
                  name="memberNo"
                  error={messages.memberNo !== ''}
                  value={form.memberNo}
                  onChange={this.onChange}
                  onBlur={this.onCheckMemberNo}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
                <FormErrorText error>{messages.memberNo}</FormErrorText>
              </Box>
              <Box ml={2}>
                <FormTitle title="入会日" isRequired />
                <Datepicker
                  value={form.enrolledOn}
                  name="enrolledOn"
                  handleChange={this.onChange}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <FormTitle title="登録店舗" isRequired />
              <SearchSelectBox
                disableClearable
                error={messages.store !== ''}
                options={selects.stores}
                value={form.store}
                onChange={this.onChange}
                name="store"
              />
              <FormErrorText error>{messages.store}</FormErrorText>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="30%">
                <FormTitle title="姓" isRequired />
                <TextField
                  name="lastName"
                  variant="outlined"
                  value={form.lastName}
                  fullWidth
                  onChange={this.onChange}
                  error={messages.lastName !== ''}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 80,
                  }}
                />
                <FormErrorText error>{messages.lastName}</FormErrorText>
              </Box>
              <Box ml={2} width="30%">
                <FormTitle title="名" isRequired />
                <TextField
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  value={form.firstName}
                  onChange={this.onChange}
                  autoComplete="off"
                  error={messages.firstName !== ''}
                  inputProps={{
                    maxLength: 40,
                  }}
                />
                <FormErrorText error>{messages.firstName}</FormErrorText>
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="30%">
                <FormTitle title="姓フリガナ" />
                <TextField
                  name="lastNameKana"
                  variant="outlined"
                  value={form.lastNameKana}
                  onChange={this.onChange}
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Box>
              <Box ml={2} width="30%">
                <FormTitle title="名フリガナ" />
                <TextField
                  name="firstNameKana"
                  variant="outlined"
                  fullWidth
                  value={form.firstNameKana}
                  onChange={this.onChange}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <FormControl component="fieldset">
                <FormTitle title="性別" />
                <RadioGroup row value={form.genderCode} name="genderCode" onChange={this.onChange}>
                  <FormControlLabel value="" control={<Radio />} label="設定なし" />
                  {selects.genders && selects.genders.map((gender) => (
                    <FormControlLabel value={gender.id.toString()} control={<Radio />} label={gender.name} key={`${gender.id}gender`} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="30%">
                <FormTitle title="電話番号" />
                <TextField
                  name="phone"
                  variant="outlined"
                  fullWidth
                  value={form.phone}
                  onChange={this.onChange}
                  error={false}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </Box>
              <Box ml={2}>
                <FormTitle title="誕生日" />
                <Datepicker
                  clearable
                  value={form.birthday}
                  name="birthday"
                  handleChange={this.onChange}
                />
              </Box>
            </Box>
            <Box mt={3} width="60%">
              <FormTitle title="メールアドレス" />
              <TextField
                name="email"
                fullWidth
                variant="outlined"
                value={form.email}
                onChange={this.onChange}
                error={messages.email !== ''}
                autoComplete="off"
                inputProps={{
                  maxLength: 80,
                }}
              />
              <FormErrorText error>{messages.email}</FormErrorText>
            </Box>
            <Box mt={3}>
              <FormTitle title="郵便番号" />
              <TextField
                name="zipCode"
                variant="outlined"
                value={form.zipCode}
                onChange={this.onChange}
                error={messages.zipCode !== ''}
                autoComplete="off"
                inputProps={{
                  maxLength: 8,
                }}
              />
              <FormErrorText error>{messages.zipCode}</FormErrorText>
            </Box>
            <Box mt={3} display="flex">
              <Box width="30%">
                <FormTitle title="都道府県" />
                <TextField
                  variant="outlined"
                  value={form.prefecture}
                  onChange={this.onChange}
                  name="prefecture"
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 80,
                  }}
                />
              </Box>
              <Box ml={2} width="30%">
                <FormTitle title="都道府県フリガナ" />
                <TextField
                  variant="outlined"
                  value={form.prefectureKana}
                  onChange={this.onChange}
                  name="prefectureKana"
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="50%">
                <FormTitle title="市区町村フリガナ" />
                <TextField
                  name="cityKana"
                  variant="outlined"
                  value={form.cityKana}
                  onChange={this.onChange}
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
              <Box ml={2} width="50%">
                <FormTitle title="その他番地・フリガナ" />
                <TextField
                  name="streetKana"
                  variant="outlined"
                  value={form.streetKana}
                  onChange={this.onChange}
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="50%">
                <FormTitle title="市区町村" />
                <TextField
                  name="city"
                  fullWidth
                  variant="outlined"
                  value={form.city}
                  onChange={this.onChange}
                  error={false}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 40,
                  }}
                />
              </Box>
              <Box ml={2} width="50%">
                <FormTitle title="その他番地" />
                <TextField
                  name="street"
                  fullWidth
                  variant="outlined"
                  value={form.street}
                  onChange={this.onChange}
                  error={false}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="50%">
                <FormTitle title="誤住所" />
                <FormControlLabel
                  control={<Switch onChange={this.onChangeBoolean} name="incorrectAddress" checked={form.incorrectAddress} color="primary" />}
                  label="誤住所にする"
                />
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="50%">
                <FormTitle title="勤務先" />
                <TextField
                  name="workPlace"
                  variant="outlined"
                  value={form.workPlace}
                  onChange={this.onChange}
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
              <Box ml={2} width="40%">
                <FormTitle title="勤務先電話番号" />
                <TextField
                  name="workPlacePhone"
                  variant="outlined"
                  value={form.workPlacePhone}
                  onChange={this.onChange}
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Box width="50%">
                <FormTitle title="勤務先フリガナ" />
                <TextField
                  name="workPlaceKana"
                  variant="outlined"
                  value={form.workPlaceKana}
                  onChange={this.onChange}
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Box>
              <Box ml={2} width="50%">
                <FormTitle title="役職" />
                <TextField
                  name="position"
                  variant="outlined"
                  value={form.position}
                  onChange={this.onChange}
                  error={false}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 128,
                  }}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <FormControl component="fieldset">
                <FormTitle title="グレード" />
                <RadioGroup row value={form.gradeCode} name="gradeCode" onChange={this.onChange}>
                  <FormControlLabel value="" control={<Radio />} label="設定なし" />
                  {selects.grades && selects.grades.map((grade) => (
                    <FormControlLabel value={grade.id.toString()} control={<Radio />} label={grade.name} key={`${grade.id}grade`} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Container>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  memberDetail: state.memberDetail,
  loginUser: state.loginUser,
  common: state.common,
});

export default withRouter(connect(mapStateToProps)(MemberUpsertModal));
