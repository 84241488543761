import { call, put, takeLatest } from 'redux-saga/effects';
import { getStoreAndGrades } from '../../../services/common/selectList.service';
import { getMembers, deleteMembers } from '../../../services/visit/visitStatusList.service';
import {
  ACTION_TYPE,
  actCallApiGetSelectSuccess,
  actCallApiGetMemberSuccess,
  actCallApiDeleteVisitStatusSuccess,
} from './action';
import { actCallApiServerError } from '../../common/common/action';

/**
 * 店舗と会員グレード取得
 * @param {*} param0
 */
function* callApiGetStoreAndGrade() {
  try {
    const selects = yield call(getStoreAndGrades);
    yield put(actCallApiGetSelectSuccess(selects));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 会員別サマリを取得
 * @param {*} param0
 */
function* callApiGetMember({ param }) {
  try {
    const datas = yield call(getMembers, param);
    yield put(actCallApiGetMemberSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 来店状況削除
 * @param {*} param0
 */
function* callApiDeleteVisitStatus({ param }) {
  try {
    yield call(deleteMembers, param);
    yield put(actCallApiDeleteVisitStatusSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallVisitStatusList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_MEMBER_VISIT_STATUS_SELECT, callApiGetStoreAndGrade);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_VISIT_STATUS_MEMBER, callApiGetMember);
  yield takeLatest(ACTION_TYPE.CALL_API_DELETE_VISIT_STATUS, callApiDeleteVisitStatus);
}
