import { call, put, takeLatest } from 'redux-saga/effects';
import { actCallApiServerError } from '../../common/common/action';
import {
  ACTION_TYPE,
  actCallApiCreateStoreSuccess,
  actCallApiGetStoreDetailSuccess,
  actCallApiUpdateStoreSuccess,
} from './action';
import { createStore, getData, updateStore } from '../../../services/setting/storeDetail.service';

/**
 * 作成処理
 *
 * @param {*} param0
 */
function* callApiStoreCreate({ param }) {
  try {
    const data = yield call(createStore, param);
    yield put(actCallApiCreateStoreSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 一件取得処理
 *
 * @param {*} param0
 */
function* callApiStoreGet({ param }) {
  try {
    const data = yield call(getData, param);
    yield put(actCallApiGetStoreDetailSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * 更新処理
 *
 * @param {*} param0
 */
function* callApiStoreUpdate({ param }) {
  try {
    const data = yield call(updateStore, param);
    yield put(actCallApiUpdateStoreSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallStoreDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_STORE, callApiStoreCreate);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_STORE_DETAIL, callApiStoreGet);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_STORE, callApiStoreUpdate);
}
