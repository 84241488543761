// region Type
export const ACTION_TYPE = {
  CALL_API_SELECT_ROLES: 'CALL_API_SELECT_ROLES',
  CALL_API_SELECT_ROLES_SUCCESS: 'CALL_API_SELECT_ROLES_SUCCESS',
  CALL_API_ROLES_SEARCH: 'CALL_API_ROLES_SEARCH',
  CALL_API_ROLES_SEARCH_SUCCESS: 'CALL_API_ROLES_SEARCH_SUCCESS',
  CALL_API_ROLE_DELETE: 'CALL_API_ROLE_DELETE',
  CALL_API_ROLE_DELETE_SUCCESS: 'CALL_API_ROLE_DELETE_SUCCESS',
  SET_ROLE_LIST_SEARCH_CONDITION: 'SET_ROLE_LIST_SEARCH_CONDITION',
  RESET_ROLE_LIST_FLG: 'RESET_ROLE_LIST_FLG',
};
// endregion

// region action
export const actCallApiSelectRole = () => ({
  type: ACTION_TYPE.CALL_API_SELECT_ROLES,
});

export const actCallApiSelectRoleSuccess = (stores) => ({
  type: ACTION_TYPE.CALL_API_SELECT_ROLES_SUCCESS,
  stores,
});

export const actCallApiGetRoleList = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_ROLES_SEARCH,
  searchCondition,
});

export const actCallApiGetRoleListSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_ROLES_SEARCH_SUCCESS,
  datas,
});

export const actCallApiRoleDelete = (values) => ({
  type: ACTION_TYPE.CALL_API_ROLE_DELETE,
  values,
});

export const actCallApiRoleDeleteSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_ROLE_DELETE_SUCCESS,
  datas,
});

export const actSetSearchCondition = (searchCondition) => ({
  type: ACTION_TYPE.SET_ROLE_LIST_SEARCH_CONDITION,
  searchCondition,
});

export const actRoleListFlgReset = () => ({
  type: ACTION_TYPE.RESET_ROLE_LIST_FLG,
});
// endregion
