import { ACTION_TYPE } from './action';

// region Reducer
const dailyVisitStatus = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_DAILY_VISIT_STATUS_SELECT_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_GET_DAILY_VISIT_STATUS_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    default:
      return state;
  }
};

export default dailyVisitStatus;
