// region Type
export const ACTION_TYPE = {
  CALL_API_GET_STORE_VISIT_STATUS_SELECT: 'CALL_API_GET_STORE_VISIT_STATUS_SELECT',
  CALL_API_GET_STORE_VISIT_STATUS_SELECT_SUCCESS: 'CALL_API_GET_STORE_VISIT_STATUS_SELECT_SUCCESS',
  CALL_API_GET_STORE_ALL_VISIT_STATUS: 'CALL_API_GET_STORE_ALL_VISIT_STATUS',
  CALL_API_GET_STORE_ALL_VISIT_STATUS_SUCCESS: 'CALL_API_GET_STORE_ALL_VISIT_STATUS_SUCCESS',
  CALL_API_GET_STORE_TAX_VISIT_STATUS: 'CALL_API_GET_STORE_TAX_VISIT_STATUS',
  CALL_API_GET_STORE_TAX_VISIT_STATUS_SUCCESS: 'CALL_API_GET_STORE_TAX_VISIT_STATUS_SUCCESS',
  CALL_API_GET_STORE_GENDER_VISIT_STATUS: 'CALL_API_GET_STORE_GENDER_VISIT_STATUS',
  CALL_API_GET_STORE_GENDER_VISIT_STATUS_SUCCESS: 'CALL_API_GET_STORE_GENDER_VISIT_STATUS_SUCCESS',
  CALL_API_GET_STORE_AGE_VISIT_STATUS: 'CALL_API_GET_STORE_AGE_VISIT_STATUS',
  CALL_API_GET_STORE_AGE_VISIT_STATUS_SUCCESS: 'CALL_API_GET_STORE_AGE_VISIT_STATUS_SUCCESS',
};
// endregion

// region action
export const actCallApiGetSelectList = () => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_VISIT_STATUS_SELECT,
});

export const actCallApiGetSelectListSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_VISIT_STATUS_SELECT_SUCCESS,
  selects,
});

export const actCallApiGetStoreAllVisitStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_ALL_VISIT_STATUS,
  param,
});

export const actCallApiGetStoreAllVisitStatusSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_ALL_VISIT_STATUS_SUCCESS,
  datas,
});

export const actCallApiGetStoreTaxVisitStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_TAX_VISIT_STATUS,
  param,
});

export const actCallApiGetStoreTaxVisitStatusSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_TAX_VISIT_STATUS_SUCCESS,
  datas,
});

export const actCallApiGetStoreGenderVisitStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_GENDER_VISIT_STATUS,
  param,
});

export const actCallApiGetStoreGenderVisitStatusSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_GENDER_VISIT_STATUS_SUCCESS,
  datas,
});

export const actCallApiGetStoreAgeVisitStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_AGE_VISIT_STATUS,
  param,
});

export const actCallApiGetStoreAgeVisitStatusSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_STORE_AGE_VISIT_STATUS_SUCCESS,
  datas,
});
// endregion
