import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  Drawer, IconButton, Divider, Box, Button, Chip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  Close as CloseIcon,
  Celebration as CelebrationIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Feed as FeedIcon,
  Grade as GradeIcon,
} from '@material-ui/icons';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SecondColorText from '../../../components/atoms/secondColorText/SecondColorText';
import { actCallApiGetVisitRegistDetail } from '../../../redux/visit/visitRegist/action';
import VisitRegistMemoDetail from './VisitRegistMemoDetail';
import urls from '../../../constants/urls';
import { isShowItem } from '../../common/header/check-show-screen';

const styles = (theme) => ({
  drawer: {
    width: 500,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 500,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
});

class VisitRegistDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMemoOpen: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (this.props.id) {
        this.init(this.props.id);
      }
    }
  }

  init = (id) => {
    this.props.dispatch(actCallApiGetVisitRegistDetail({ id }));
  }

  onMemoDetailClose = () => {
    this.setState({ isMemoOpen: false });
  }

  onMemoDetailOpen = () => {
    this.setState({ isMemoOpen: true });
  }

  onMemberDetailClick = () => {
    const row = this.props.visitRegist.data;
    this.props.history.push({
      pathname: urls.MEMBER.MEMBER_DETAIL,
      state: { memberId: row.memberId },
    });
  }

  render() {
    const {
      open, onClose, onMemoSave, classes, loginUser,
    } = this.props;
    const { isMemoOpen } = this.state;
    const row = this.props.visitRegist.data || {};
    const screenList = loginUser.user ? loginUser.user.screenList : [];
    const isMemberDetailShow = isShowItem(screenList, urls.MEMBER.MEMBERS);

    return (
      <>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            {`${row.lastName} ${row.firstName}`}
          </div>
          <Divider />
          <Box>
            <Box p={2} fontSize={17}>
              <Box display="flex" mb={2}>
                <Box mr={5}>
                  <FormTitle title="来店時間" />
                  <Box>{row.visitTime}</Box>
                </Box>
                <Box>
                  <FormTitle title="会計金額(税込)" />
                  <Box>{row.includTaxAmount}</Box>
                </Box>
              </Box>
              <Box display="flex" mb={2}>
                <Box mr={5}>
                  <FormTitle title="会計金額(税抜)" />
                  <Box>{row.excludTaxAmount}</Box>
                </Box>
                <Box>
                  <FormTitle title="値引き額" />
                  <Box>{row.discountAmount}</Box>
                </Box>
              </Box>
              <Box display="flex" mb={2}>
                <Box mr={5}>
                  <FormTitle title="来店人数" />
                  <Box>{row.visitors}</Box>
                </Box>
                <Box>
                  <FormTitle title="利用タイプ" />
                  <Box>{row.usageType}</Box>
                </Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="最新のメモ" />
                <Box>
                  {row.memo && row.memo.split('\n').map((t, i) => (
                    t === '' ? <br key={`${t} ${i + 1}`} /> : <Box key={`${t} ${i + 1}`}>{t}</Box>
                  ))}
                </Box>
              </Box>
              <Button color="secondary" variant="outlined" onClick={this.onMemoDetailOpen} startIcon={<AddCircleOutlineIcon />}>メモを登録する</Button>
            </Box>
            <Divider />
            <Box fontSize={15} p={2} fontWeight="fontWeightBold">会員情報</Box>
            <Divider />
            <Box pl={2} pt={2} mb={3}>
              {row.todayBirthday && <Box mb={2}><Chip color="secondary" icon={<CelebrationIcon />} label="本日お誕生日" /></Box>}
              <Box display={row.grade ? '' : 'none'} my={1}>
                <Chip
                  style={{
                    background: row.gradeBackGround,
                    color: row.gradeColor,
                  }}
                  icon={<GradeIcon style={{ color: row.gradeColor }} />}
                  label={row.grade}
                />
              </Box>
              <Box mb={2}>
                <FormTitle title="会員番号" />
                <Box>{row.memberNo}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="名前" />
                <SecondColorText>{`${row.lastNameKana} ${row.firstNameKana}`}</SecondColorText>
                <Box>{`${row.lastName} ${row.firstName}`}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="性別" />
                <Box>{row.gender}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="電話番号" />
                <Box>{row.phone}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="生年月日" />
                <Box>{row.birthday}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="メールアドレス" />
                <Box>{row.email}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="住所" />
                <Box>{row.zipCode && `〒${row.zipCode}`}</Box>
                <SecondColorText>{row.addressKana}</SecondColorText>
                <Box>{row.address}</Box>
              </Box>
              <Box mb={2}>
                <FormTitle title="勤務先" />
                <SecondColorText>{row.workPlaceKana}</SecondColorText>
                <Box>{row.workPlace}</Box>
                <Box>{row.workPlacePhone}</Box>
                <Box>{row.position}</Box>
              </Box>
              {isMemberDetailShow && (
                <Button variant="outlined" color="secondary" onClick={this.onMemberDetailClick} startIcon={<FeedIcon />}>会員の詳細情報を見る</Button>
              )}
            </Box>
          </Box>
        </Drawer>
        <VisitRegistMemoDetail
          open={isMemoOpen}
          onClose={this.onMemoDetailClose}
          memberId={row.memberId}
          memberName={`${row.lastName} ${row.firstName}`}
          onMemoSave={onMemoSave}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  visitRegist: state.visitRegist,
  loginUser: state.loginUser,
});

export default withRouter(withStyles(styles)(connect(mapStateToProps)(VisitRegistDetail)));
