import { ACTION_TYPE } from './action';

// region Reducer
const visitStatusDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL_SELECT_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.CALL_API_GET_VISIT_STATUS_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_UPDATE_VISIT_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_CREATE_VISIT_STATUS_SUCCESS:
      return {
        ...state,
        isCreateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_CHECK_VISIT_STATUS_MEMBER_SUCCESS:
      return {
        ...state,
        isCheckSuccess: true,
        checkData: action.data,
      };
    case ACTION_TYPE.RESET_VISIT_STATUS_DETAIL_FLG:
      return {
        ...state,
        isCheckSuccess: false,
        isUpdateSuccess: false,
        isCreateSuccess: false,
      };
    default:
      return state;
  }
};

export default visitStatusDetail;
