// #region Type
export const ACTION_TYPE = {
  TOGGLE_LOADING_SCREEN: 'TOGGLE_LOADING_SCREEN',
  CALL_API_SERVER_ERROR: 'CALL_API_SERVER_ERROR',
  CALL_API_RESET_SERVER_ERROR: 'CALL_API_RESET_SERVER_ERROR',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
};
// #endregion

// #region action
export const actToggleLoadingScreen = (isLoading) => ({
  type: ACTION_TYPE.TOGGLE_LOADING_SCREEN,
  isLoading,
});

export const actCallApiServerError = (error) => ({
  type: ACTION_TYPE.CALL_API_SERVER_ERROR,
  errorMessages: error.response.data.errorDetails,
});

export const actCallApiResetServerError = () => ({
  type: ACTION_TYPE.CALL_API_RESET_SERVER_ERROR,
});

export const actSetBreadcrumbs = (breadcrumbs) => ({
  type: ACTION_TYPE.SET_BREADCRUMBS,
  breadcrumbs,
});
// #endregion
