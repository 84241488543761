import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Box, Container, TextField,
  FormControl, Select, MenuItem,
} from '@material-ui/core';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchMultiSelectBox from '../../../components/atoms/searchMultiSelectBox/SearchMultiSelectBox';
import urls from '../../../constants/urls';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import {
  actCallApiDeleteMember, actCallApiMemberSearch,
  actResetMemberListFlg, actSetMemberListCondition,
  actCallApiGetSelectList,
} from '../../../redux/member/memberList/action';
import MemberCreateCheckModal from '../memberDetail/MemberCreateCheckModal';
import MemberUpsertModal from '../memberDetail/upsertModal/MemberUpsertModal';
import {
  getMonths, getKeywordHelper,
} from './member-proc';
import MemberDownloadModal from './MemberDownloadModal';

class MemberList extends Component {
  constructor(props) {
    super(props);

    this.month = getMonths();
    this.helper = getKeywordHelper();

    props.dispatch(actSetBreadcrumbs([{ name: '会員一覧' }]));

    let searchCondition = {
      memberNo: '',
      birthdayMonth: '0',
      keyword: '',
      searchStoreIds: [],
      searchMemberRankIds: [],
      page: 0,
      records: 10,
      order: 'asc',
      orderBy: '',
    };

    if (props.memberList.searchCondition) {
      searchCondition = props.memberList.searchCondition;
    }

    this.state = {
      searchCondition,
      datas: [],
      selects: { stores: [], memberRanks: [] },
      isSuccessOpen: false,
      successMessage: '',
      isCreateCheckModalOpen: false,
      isCreateModalOpen: false,
      isDonwloadOpen: false,
    };

    this.headCells = [
      {
        id: 'memberNo', numeric: false, label: '会員番号', sortKey: 'member_no',
      },
      {
        id: 'memberName', numeric: false, label: '会員名', sortKey: 'member_name',
      },
      {
        id: 'memberRank', numeric: false, label: '会員ランク', sortKey: 'member_rank',
      },
      {
        id: 'memberAddress', numeric: false, label: '住所', sortKey: 'member_address',
      },
    ];
    this.props.dispatch(actCallApiGetSelectList());
    this.search(searchCondition);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memberList.selects !== this.props.memberList.selects) {
      if (this.props.memberList.selects) {
        this.setSelects(this.props.memberList.selects);
      }
    }
    if (prevProps.memberList.datas !== this.props.memberList.datas) {
      if (this.props.memberList.datas) {
        this.setDatas(this.props.memberList.datas);
      }
    }
    if (prevProps.memberList.isDeleteSuccess !== this.props.memberList.isDeleteSuccess) {
      if (this.props.memberList.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
  }

  setSelects(selects) {
    this.setState({ selects });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  search = (searchCondition) => {
    const searchStoreIds = searchCondition.searchStoreIds.map((n) => n.id);
    const searchMemberRankIds = searchCondition.searchMemberRankIds.map((n) => n.id);
    this.props.dispatch(
      actCallApiMemberSearch({
        memberNo: searchCondition.memberNo,
        page: searchCondition.page,
        records: searchCondition.records,
        sortKey: searchCondition.orderBy,
        sortDirection: searchCondition.order,
        keyword: searchCondition.keyword,
        birthdayMonth: searchCondition.birthdayMonth !== '0' ? searchCondition.birthdayMonth : '',
        searchMemberRankIds,
        searchStoreIds,
      }),
    );
  }

  onClickSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  onDelete = (value) => {
    this.props.dispatch(actResetMemberListFlg());
    this.props.dispatch(actCallApiDeleteMember({
      deletes: value,
    }));
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isSuccessOpen: false });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  }

  setDeleteSuccess = () => {
    this.search(this.state.searchCondition);
    this.setState({ isSuccessOpen: true, successMessage: '会員の削除が完了しました' });
  }

  onDetail = (_, row) => {
    this.props.history.push({
      pathname: urls.MEMBER.MEMBER_DETAIL,
      state: { memberId: row.id },
    });
    const { searchCondition } = this.state;
    this.props.dispatch(actSetMemberListCondition(searchCondition));
  }

  onCreateCheckModalClose = () => {
    this.setState({ isCreateCheckModalOpen: false });
  }

  onCreateCheckModalOpen = () => {
    this.setState({ isCreateCheckModalOpen: true, isSuccessOpen: false });
  }

  onCreateModalOpen = (createMemberNo) => {
    this.setState({ isCreateCheckModalOpen: false, isCreateModalOpen: true, createMemberNo });
  }

  onCreateModalClose = () => {
    this.setState({ isCreateModalOpen: false });
  }

  onClickDownloadOpen = () => {
    this.setState({ isDonwloadOpen: true });
  }

  onClickDownloadClose = () => {
    this.setState({ isDonwloadOpen: false });
  }

  render() {
    const {
      searchCondition, datas, isSuccessOpen, successMessage,
      isCreateCheckModalOpen, isCreateModalOpen, createMemberNo,
      isDonwloadOpen, selects,
    } = this.state;

    return (
      <Container>
        <SearchBox onSearch={this.onClickSearch}>
          <Box display="flex">
            <Box>
              <FormTitle title="会員番号" />
              <TextField
                fullWidth
                name="memberNo"
                variant="outlined"
                autoComplete="off"
                value={searchCondition.memberNo}
                onChange={this.onChange}
              />
            </Box>
            <Box ml={2}>
              <FormTitle title="誕生月" />
              <FormControl fullWidth>
                <Select
                  value={searchCondition.birthdayMonth}
                  onChange={this.onChange}
                  name="birthdayMonth"
                >
                  <MenuItem value="0">設定なし</MenuItem>
                  {this.month.map((month) => (
                    <MenuItem value={month.value} key={month.value}>{month.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box width="40%" ml={2}>
              <FormTitle title="会員ランク" />
              <SearchMultiSelectBox
                options={selects.memberRanks}
                values={searchCondition.searchMemberRankIds}
                name="searchMemberRankIds"
                onChange={this.onChange}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <FormTitle title="来店店舗" />
            <SearchMultiSelectBox
              options={selects.stores}
              values={searchCondition.searchStoreIds}
              name="searchStoreIds"
              onChange={this.onChange}
            />
          </Box>
          <Box mt={2}>
            <FormTitle title="キーワード" helper={this.helper} />
            <TextField
              fullWidth
              name="keyword"
              variant="outlined"
              autoComplete="off"
              value={searchCondition.keyword}
              onChange={this.onChange}
            />
          </Box>
        </SearchBox>
        <DeleteDataTable
          procName="会員"
          rows={datas.content || []}
          headCells={this.headCells}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          handleRequestSort={this.handleRequestSort}
          handleChangePage={this.handleChangePage}
          onAdd={this.onCreateCheckModalOpen}
          onDetail={this.onDetail}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          onDownload={this.onClickDownloadOpen}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
        <MemberCreateCheckModal
          open={isCreateCheckModalOpen}
          onClose={this.onCreateCheckModalClose}
          onNext={this.onCreateModalOpen}
        />
        <MemberUpsertModal
          open={isCreateModalOpen}
          onClose={this.onCreateModalClose}
          memberNo={createMemberNo}
        />
        <MemberDownloadModal
          open={isDonwloadOpen}
          onClose={this.onClickDownloadClose}
          searchCondition={searchCondition}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  memberList: state.memberList,
});

export default connect(mapStateToProps)(MemberList);
