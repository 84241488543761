import React from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@material-ui/lab/LoadingButton';
import SaveIcon from '@material-ui/icons/Save';

export default function SaveButton(props) {
  const { children, onClick, loading } = props;

  return (
    <LoadingButton
      color="secondary"
      onClick={onClick}
      loading={loading}
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="outlined"
    >
      {children}
    </LoadingButton>
  );
}

SaveButton.propTypes = {
  loading: PropTypes.bool,
};

SaveButton.defaultProps = {
  loading: false,
};
