import React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  Container, Box, FormControl, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import Datepicker from '../../../components/atoms/datepicker/Datepicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import getAdmin from '../../../helpers/common.helper';
import {
  actCallApiGetSelectList,
  actCallApiGetMemberAnalysisList,
} from '../../../redux/memberAnalysis/memberAnalysis/action';
import MemberAnalysisesAmountTable from '../memberAnalysises/MemberAnalysisesAmountTable';
import MemberAnalysisesVisitCountTable from '../memberAnalysises/MemberAnalysisesVisitCountTable';

class StoreMemberAnalysis extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actSetBreadcrumbs([{ name: '店舗別会員分析' }]));
    props.dispatch(actCallApiGetSelectList());

    let searchStore = null;
    if (props.loginUser.user.store) {
      searchStore = props.loginUser.user.store;
    }

    const searchCondition = {
      searchStore,
      searchStartVisitDate: '',
      searchEndVisitDate: '',
      displayType: '0',
    };

    this.state = {
      stores: [],
      summaryDatas: [],
      searchCondition,
      displayTargetType: '0',
    };

    this.search(searchCondition);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.memberAnalysis.stores !== this.props.memberAnalysis.stores) {
      if (this.props.memberAnalysis.stores) {
        this.setStores(this.props.memberAnalysis.stores);
      }
    }
    if (prevProps.memberAnalysis.datas !== this.props.memberAnalysis.datas) {
      if (this.props.memberAnalysis.datas) {
        this.setSummary(this.props.memberAnalysis.datas);
      }
    }
  }

  setSummary = (summaryDatas) => {
    this.setState({ summaryDatas });
  }

  setStores = (stores) => {
    this.setState({ stores });
  }

  search = (searchCondition) => {
    this.props.dispatch(actCallApiGetMemberAnalysisList({
      searchStartVisitDate: searchCondition.searchStartVisitDate,
      searchEndVisitDate: searchCondition.searchEndVisitDate,
      searchStoreId: searchCondition.searchStore.id,
      displayType: searchCondition.displayType,
    }));
  }

  onSearchChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({ searchCondition: { ...searchCondition, [name]: value } });
  }

  onRadioChange = (event) => {
    const { value } = event.target;
    const { searchCondition } = this.state;
    this.setState({ searchCondition: { ...searchCondition, displayType: value } });
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
    this.setState({ displayTargetType: searchCondition.displayType });
  }

  render() {
    const {
      stores, searchCondition, summaryDatas, displayTargetType,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <>
        <Container>
          <SearchBox onSearch={this.onSearch}>
            <Box>
              <FormTitle title="来店日" />
              <Box display="flex" mt={1} alignItems="center">
                <Box>
                  <Datepicker
                    value={searchCondition.searchStartVisitDate}
                    name="searchStartVisitDate"
                    handleChange={this.onSearchChange}
                    clearable
                  />
                </Box>
                <Box mx={4}>～</Box>
                <Box>
                  <Datepicker
                    minDate={searchCondition.searchStartVisitDate}
                    name="searchEndVisitDate"
                    value={searchCondition.searchEndVisitDate}
                    handleChange={this.onSearchChange}
                    clearable
                  />
                </Box>
              </Box>
            </Box>
            {admin && (
              <Box width="30%" mt={3}>
                <FormTitle title="来店店舗" />
                <SearchSelectBox
                  disableClearable
                  options={stores}
                  value={searchCondition.searchStore}
                  name="searchStore"
                  onChange={this.onSearchChange}
                />
              </Box>
            )}
            <Box>
              <Box width="30%" mt={3}>
                <FormControl component="fieldset">
                  <FormTitle title="表示対象" />
                  <RadioGroup row value={searchCondition.displayType} onChange={this.onRadioChange}>
                    <FormControlLabel value="0" control={<Radio />} label="金額TOP100" />
                    <FormControlLabel value="1" control={<Radio />} label="利用回数TOP100" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </SearchBox>

          <Box display={displayTargetType === '0' ? '' : 'none'}>
            <MemberAnalysisesAmountTable
              rows={summaryDatas}
            />
          </Box>
          <Box display={displayTargetType === '1' ? '' : 'none'}>
            <MemberAnalysisesVisitCountTable
              rows={summaryDatas}
            />
          </Box>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  memberAnalysis: state.memberAnalysis,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps)(StoreMemberAnalysis));
