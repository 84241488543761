import React from 'react';
import { isValid, format } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';
import { TextField } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { DateTimePicker as MaterialDateTimePicker } from '@material-ui/lab';
import PropTypes from 'prop-types';

export default function DateTimePicker(props) {
  const {
    value,
    handleChange,
    error,
    name,
  } = props;

  const onChange = (changeValue) => {
    let formatValue = changeValue;
    if (isValid(changeValue)) {
      formatValue = format(changeValue, 'yyyy/MM/dd HH:mm');
    }
    handleChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
      <MaterialDateTimePicker
        renderInput={(params) => <TextField {...params} helperText={null} error={error} />}
        showTodayButton
        toolbarTitle="日付を選択"
        cancelText="キャンセル"
        todayText="今日"
        okText="決定"
        value={fnsValue}
        onChange={onChange}
        mask="____年__月__日 __時__分"
        toolbarFormat="yyyy年MM月dd日"
        inputFormat="yyyy年MM月dd日 HH時mm分"
      />
    </LocalizationProvider>
  );
}

DateTimePicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  value: null,
  clearable: false,
};
