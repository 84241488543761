import { ACTION_TYPE } from './action';
// region Reducer
const storeList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_STORE_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.CALL_API_DELETE_STORE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
      };
    case ACTION_TYPE.RESET_STORE_FLG:
      return {
        ...state,
        isDeleteSuccess: false,
      };
    default:
      return state;
  }
};
export default storeList;
