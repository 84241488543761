import React from 'react';
import {
  Container, Paper, Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    lineHeight: '4.5',
  },
  root: {
    padding: theme.spacing(2),
  },
}));

export default function ApplicationError() {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper variant="outlined" className={classes.root}>
        <Box fontWeight="fontWeightBold" textAlign="center">予期せぬエラーが発生しました</Box>
        <Box textAlign="center">恐れ入りますがTOPに戻り、操作をやり直してください。</Box>
      </Paper>
    </Container>
  );
}
