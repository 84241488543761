import {
  Box, DialogActions, DialogContent,
  LinearProgress, TextField,
} from '@material-ui/core';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import {
  actCallApiCreateStore,
  actCallApiGetStoreDetail,
  actCallApiUpdateStore, actResetStoreDetailFlg,
} from '../../../redux/setting/storeDetail/action';
import Validation from './validation';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';

class StoreDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false,
      loading: false,
      info: {
        storeName: '',
      },
      message: {
        storeName: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.storeDetail.data !== this.props.storeDetail.data) {
      if (this.props.storeDetail.data) {
        this.setData(this.props.storeDetail.data);
      }
    }
  }

  setData(info) {
    this.setState({
      info: { ...info, storeName: info.name },
      loading: false,
      btnLoading: false,
    });
  }

  handleSave = () => {
    const { info } = this.state;
    let isError = false;
    const msg = Validation.formValidate(info.storeName);
    if (msg) isError = true;
    this.setState({ message: { storeName: msg }, btnLoading: !isError });
    if (isError) return;
    const param = {
      id: info.id,
      name: info.storeName,
    };
    this.props.dispatch(actResetStoreDetailFlg());
    if (info.id) {
      this.props.dispatch(actCallApiUpdateStore(param));
    } else {
      this.props.dispatch(actCallApiCreateStore(param));
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    const { info, message } = this.state;
    this.setState({
      info: { ...info, [name]: value },
      message: { ...message, [name]: Validation.formValidate(value) },
    });
  };

  init() {
    const { data } = this.props;
    const info = {
      storeName: '',
    };
    const message = {
      storeName: '',
    };
    let btnLoading = false;
    let loading = false;
    if (data && data.id) {
      this.props.dispatch(actCallApiGetStoreDetail({ id: data.id }));
      btnLoading = true;
      loading = true;
    }
    this.setState({
      info,
      message,
      btnLoading,
      loading,
    });
  }

  render() {
    const { open, handleClose } = this.props;
    const {
      btnLoading, loading, info, message,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={handleClose}>
        <DialogColorTitle>
          <Box display={info.id ? 'none' : ''}>店舗の追加</Box>
          <Box display={info.id ? '' : 'none'}>店舗の変更</Box>
        </DialogColorTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Box>
            <FormTitle title="店舗名" isRequired />
            <TextField
              name="storeName"
              variant="outlined"
              value={info.storeName}
              error={message.storeName !== ''}
              onChange={this.handleChange}
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
            <FormErrorText error>{message.storeName}</FormErrorText>
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose}>キャンセル</CancelButton>
          <SaveButton loading={btnLoading} onClick={this.handleSave}>保存する</SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  storeDetail: state.storeDetail,
});
export default connect(mapStateToProps)(StoreDetail);
