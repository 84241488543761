import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { TextField, Box, Container } from '@material-ui/core';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import {
  actCallApiSelectRole,
  actCallApiGetRoleList,
  actRoleListFlgReset,
  actCallApiRoleDelete,
  actSetSearchCondition,
} from '../../../redux/user/roleList/action';
import getAdmin from '../../../helpers/common.helper';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { actSetBreadcrumbs } from '../../../redux/common/common/action';
import RoleDetailDialog from '../roleDetail/RoleDetailDialog';
import urls from '../../../constants/urls';

class RoleList extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(actCallApiSelectRole());

    props.dispatch(actSetBreadcrumbs([{ name: '権限' }]));

    this.headCells = [
      {
        id: 'authName', numeric: false, label: '権限名', sortKey: 'auth_name',
      },
      {
        id: 'storeName', numeric: false, label: '店舗', sortKey: 'store_name',
      },
      {
        id: 'admin', numeric: false, label: 'システム管理者', sortKey: 'is_admin',
      },
    ];

    let searchCondition = {
      searchStore: null,
      searchRoleName: '',
      page: 0,
      records: 10,
      order: 'asc',
      orderBy: '',
    };

    if (props.roleList.searchCondition) {
      searchCondition = props.roleList.searchCondition;
    }

    if (props.roleList.searchCondition || props.loginUser.user) {
      this.search(searchCondition);
    }

    this.state = {
      stores: [],
      datas: [],
      searchCondition,
      isSuccessOpen: false,
      isDetailModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUser.user !== this.props.loginUser.user) {
      if (this.props.loginUser.user) {
        this.setUser(this.props.loginUser.user);
      }
    }
    if (prevProps.roleList.datas !== this.props.roleList.datas) {
      if (this.props.roleList.datas) {
        this.setDatas(this.props.roleList.datas);
      }
    }
    if (prevProps.roleList.stores !== this.props.roleList.stores) {
      if (this.props.roleList.stores) {
        this.setStores(this.props.roleList.stores);
      }
    }
    if (prevProps.roleList.isDeleteSuccess !== this.props.roleList.isDeleteSuccess) {
      if (this.props.roleList.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
  }

  setUser(user) {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchStore: user.store,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(searchCondition);
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setStores(stores) {
    this.setState({ stores });
  }

  setDeleteSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  }

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  }

  search = (searchCondition) => {
    this.props.dispatch(actCallApiGetRoleList({
      page: searchCondition.page,
      records: searchCondition.records,
      searchStoreId: searchCondition.searchStore && searchCondition.searchStore.id,
      searchRoleName: searchCondition.searchRoleName,
      sortDirection: searchCondition.order,
      sortKey: searchCondition.orderBy,
    }));
  }

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (value) => {
    this.props.dispatch(actRoleListFlgReset());
    this.props.dispatch(actCallApiRoleDelete({ deletes: value }));
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  }

  onAdd = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(actSetSearchCondition(searchCondition));
    this.setState({ isDetailModalOpen: true, isSuccessOpen: false });
  }

  onDetail = (_, row) => {
    const { searchCondition } = this.state;
    this.props.dispatch(actSetSearchCondition(searchCondition));
    this.props.history.push({
      pathname: urls.USER.ROLE_DETAIL,
      state: { roleId: row.id },
    });
  }

  handleDetailClose = () => {
    this.setState({ isDetailModalOpen: false });
  }

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      stores, datas, searchCondition,
      isSuccessOpen, isDetailModalOpen,
    } = this.state;
    const admin = getAdmin(this.props.loginUser);

    return (
      <Container>
        <SearchBox onSearch={this.onSearch}>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            {admin && (
              <Box width={{ xs: '100%', sm: '30%' }} mr={2}>
                <FormTitle title="店舗" />
                <SearchSelectBox options={stores} value={searchCondition.searchStore} onChange={this.onChange} name="searchStore" />
              </Box>
            )}

            <Box width={{ xs: '100%', sm: '30%' }} mt={{ xs: 2, sm: 0 }}>
              <FormTitle title="権限名" />
              <TextField name="searchRoleName" variant="outlined" autoComplete="off" fullWidth value={searchCondition.searchRoleName} onChange={this.onChange} />
            </Box>
          </Box>
        </SearchBox>

        <DeleteDataTable
          rows={datas.content || []}
          headCells={this.headCells}
          procName="権限"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          onAdd={this.onAdd}
          onDetail={this.onDetail}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
        <SuccessSnackbar open={isSuccessOpen} handleClose={this.handleSuccessSnackbarClose} message="権限の削除が完了しました" />

        <RoleDetailDialog
          open={isDetailModalOpen}
          handleClose={this.handleDetailClose}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  roleList: state.roleList,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps)(RoleList));
