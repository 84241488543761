import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    flex: '1 1 50%',
  },
}));

export default function ItemTitle(props) {
  const classes = useStyles();

  return (
    <Box fontWeight="fontWeightBold" className={classes.root} display="flex" alignItems="center" fontSize="h5.fontSize">{props.children}</Box>
  );
}
