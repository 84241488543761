import React from 'react';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Box, Paper, Button,
  Toolbar, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Feed as FeedIcon } from '@material-ui/icons';
import Pagination from '../../../components/atoms/pagination/Pagination';

const useStyles = makeStyles(() => ({
  emptyTableCell: {
    textAlign: 'center',
  },
}));

export default function VisitRegistTable(props) {
  const classes = useStyles();
  const {
    rows,
    onDetail,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    selectedId,
  } = props;

  return (
    <Box mt={5}>
      <Paper>
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {`本日 (${format(new Date(), 'yyyy年MM月dd日 EEEEEE曜日', { locale: ja })}) の来店履歴`}
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table size="medium" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>来店時間</TableCell>
                <TableCell>会員番号</TableCell>
                <TableCell>名前</TableCell>
                <TableCell>会計金額(税込)</TableCell>
                <TableCell>来店人数</TableCell>
                <TableCell>利用タイプ</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  selected={selectedId && selectedId === row.id}
                >
                  <TableCell>{row.visitTime}</TableCell>
                  <TableCell>{row.memberNo}</TableCell>
                  <TableCell>
                    <Box>{`${row.lastNameKana} ${row.firstNameKana}`}</Box>
                    <Box>{`${row.lastName} ${row.firstName}`}</Box>
                  </TableCell>
                  <TableCell>{row.money}</TableCell>
                  <TableCell>{row.visitors}</TableCell>
                  <TableCell>{row.usageType}</TableCell>
                  <TableCell>
                    <Button color="secondary" onClick={(event) => onDetail(event, row)} startIcon={<FeedIcon />} variant="outlined">詳細を見る</Button>
                  </TableCell>
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell className={classes.emptyTableCell} colSpan={6}>
                      本日の来店情報はありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
