export const ACTION_TYPE = {
  CALL_API_SELECT_MEMBER_DETAIL: 'CALL_API_SELECT_MEMBER_DETAIL',
  CALL_API_SELECT_MEMBER_DETAIL_SUCCESS: 'CALL_API_SELECT_MEMBER_DETAIL_SUCCESS',
  CALL_API_GET_MEMBER_DETAIL: 'CALL_API_GET_MEMBER_DETAIL',
  CALL_API_GET_MEMBER_DETAIL_SUCCESS: 'CALL_API_GET_MEMBER_DETAIL_SUCCESS',
  CALL_API_GET_UPDATE_MEMBER_DATA: 'CALL_API_GET_UPDATE_MEMBER_DATA',
  CALL_API_GET_UPDATE_MEMBER_DATA_SUCCESS: 'CALL_API_GET_UPDATE_MEMBER_DATA_SUCCESS',
  CALL_API_CREATE_MEMBER: 'CALL_API_CREATE_MEMBER',
  CALL_API_CREATE_MEMBER_SUCCESS: 'CALL_API_CREATE_MEMBER_SUCCESS',
  CALL_API_UPDATE_MEMBER: 'CALL_API_UPDATE_MEMBER',
  CALL_API_UPDATE_MEMBER_SUCCESS: 'CALL_API_UPDATE_MEMBER_SUCCESS',
  CALL_API_MEMBER_EXIST_CHECK: 'CALL_API_MEMBER_EXIST_CHECK',
  CALL_API_MEMBER_EXIST_CHECK_SUCCESS: 'CALL_API_MEMBER_EXIST_CHECK_SUCCESS',
  CALL_API_GET_MEMBER_ADDRESS: 'CALL_API_GET_MEMBER_ADDRESS',
  CALL_API_GET_MEMBER_ADDRESS_SUCCESS: 'CALL_API_GET_MEMBER_ADDRESS_SUCCESS',
  CALL_API_GET_MEMBER_DETAIL_MEMO: 'CALL_API_GET_MEMBER_DETAIL_MEMO',
  CALL_API_GET_MEMBER_DETAIL_MEMO_SUCCESS: 'CALL_API_GET_MEMBER_DETAIL_MEMO_SUCCESS',
  CALL_API_CREATE_MEMBER_DETAIL_MEMO: 'CALL_API_CREATE_MEMBER_DETAIL_MEMO',
  CALL_API_CREATE_MEMBER_DETAIL_MEMO_SUCCESS: 'CALL_API_CREATE_MEMBER_DETAIL_MEMO_SUCCESS',
  CALL_API_UPDATE_MEMBER_DETAIL_MEMO: 'CALL_API_UPDATE_MEMBER_DETAIL_MEMO',
  CALL_API_UPDATE_MEMBER_DETAIL_MEMO_SUCCESS: 'CALL_API_UPDATE_MEMBER_DETAIL_MEMO_SUCCESS',
  CALL_API_DELETE_MEMBER_DETAIL_MEMO: 'CALL_API_DELETE_MEMBER_DETAIL_MEMO',
  CALL_API_DELETE_MEMBER_DETAIL_MEMO_SUCCESS: 'CALL_API_DELETE_MEMBER_DETAIL_MEMO_SUCCESS',
  SET_MEMBER_CREATE_SUCCESS: 'SET_MEMBER_CREATE_SUCCESS',
  RESET_MEMBER_DETAIL_FLG: 'RESET_MEMBER_DETAIL_FLG',
};

export const actCallApiSelectMemberDetail = () => ({
  type: ACTION_TYPE.CALL_API_SELECT_MEMBER_DETAIL,
});

export const actCallApiSelectMemberDetailSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_SELECT_MEMBER_DETAIL_SUCCESS,
  selects,
});

export const actCallApiGetMemberDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL,
  param,
});

export const actCallApiGetMemberDetailSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_SUCCESS,
  data,
});

export const actCallApiGetUpdateMemberData = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_UPDATE_MEMBER_DATA,
  param,
});

export const actCallApiGetUpdateMemberDataSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_UPDATE_MEMBER_DATA_SUCCESS,
  data,
});

export const actCallApiCreateMember = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_MEMBER,
  param,
});

export const actCallApiCreateMemberSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CREATE_MEMBER_SUCCESS,
  data,
});

export const actCallApiUpdateMember = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMBER,
  param,
});

export const actCallApiUpdateMemberSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMBER_SUCCESS,
  data,
});

export const actCallApiCheckExistMember = (param) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_EXIST_CHECK,
  param,
});

export const actCallApiCheckExistMemberSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_EXIST_CHECK_SUCCESS,
  data,
});

export const actCallApiGetMemberAddress = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_ADDRESS,
  param,
});

export const actCallApiGetMemberAddressSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_ADDRESS_SUCCESS,
  data,
});

export const actCallApiGetMemberDetailMemo = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_MEMO,
  param,
});

export const actCallApiGetMemberDetailMemoSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_MEMBER_DETAIL_MEMO_SUCCESS,
  data,
});

export const actCallApiCreateMemberDetailMemo = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_MEMBER_DETAIL_MEMO,
  param,
});

export const actCallApiCreateMemberDetailMemoSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CREATE_MEMBER_DETAIL_MEMO_SUCCESS,
  data,
});

export const actCallApiUpdateMemberDetailMemo = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL_MEMO,
  param,
});

export const actCallApiUpdateMemberDetailMemoSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_MEMBER_DETAIL_MEMO_SUCCESS,
  data,
});

export const actCallApiDeleteMemberDetailMemo = (param) => ({
  type: ACTION_TYPE.CALL_API_DELETE_MEMBER_DETAIL_MEMO,
  param,
});

export const actCallApiDeleteMemberDetailMemoSuccess = () => ({
  type: ACTION_TYPE.CALL_API_DELETE_MEMBER_DETAIL_MEMO_SUCCESS,
});

export const actSetMemberCreateSuccess = () => ({
  type: ACTION_TYPE.SET_MEMBER_CREATE_SUCCESS,
});

export const actResetMemberDetailFlg = () => ({
  type: ACTION_TYPE.RESET_MEMBER_DETAIL_FLG,
});
