import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  TextField, DialogActions, DialogContent,
  LinearProgress, Box, FormControlLabel, Switch,
} from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import {
  actCallApiUpdateRole, actCallApiCreateRole,
  actResetRoleDetailFlg, actCallApiGetRole, actSetRoleCreated,
} from '../../../redux/user/roleDetail/action';
import getAdmin from '../../../helpers/common.helper';
import urls from '../../../constants/urls';
import CancelButton from '../../../components/atoms/cancaelButton/CancelButton';
import SaveButton from '../../../components/atoms/saveButton/SaveButton';

class RoleDetailDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        id: null,
        store: null,
        name: '',
        admin: false,
      },
      nameErrorMessage: '',
      btnLoading: false,
      loading: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.roleDetail.role !== this.props.roleDetail.role) {
      if (this.props.roleDetail.role) {
        this.setData(this.props.roleDetail.role);
      }
    }
    if (prevProps.roleDetail.isRoleCreated !== this.props.roleDetail.isRoleCreated) {
      if (this.props.roleDetail.isRoleCreated) {
        this.goDetail(this.props.roleDetail.role);
      }
    }
  }

  setData(info) {
    this.setState({ info, loading: false, btnLoading: false });
  }

  handleSave = () => {
    const { info } = this.state;
    let btnLoading = true;
    let nameErrorMessage = '';

    if (!info.name) {
      btnLoading = false;
      nameErrorMessage = '入力してください';
    }

    this.setState({ nameErrorMessage, btnLoading });

    if (nameErrorMessage) {
      return;
    }

    this.props.dispatch(actResetRoleDetailFlg());

    const param = {
      id: info.id,
      storeId: info.store.id,
      name: info.name,
      admin: info.admin,
    };

    if (!info.id) {
      this.props.dispatch(actCallApiCreateRole(param));
    } else {
      this.props.dispatch(actCallApiUpdateRole(param));
    }
  }

  onChange = (event) => {
    const { value, name } = event.target;
    const { info, nameErrorMessage } = this.state;

    let message = nameErrorMessage;
    if (name === 'nameErrorMessage') {
      message = value ? '' : '入力してください';
    }

    this.setState({
      info: { ...info, [name]: value },
      nameErrorMessage: message,
    });
  }

  onAdminChange = (event) => {
    const { info } = this.state;
    this.setState({
      info: { ...info, admin: event.target.checked },
    });
  }

  goDetail = (data) => {
    this.props.dispatch(actSetRoleCreated());
    this.props.history.push({
      pathname: urls.USER.ROLE_DETAIL,
      state: { roleId: data.id },
    });
    this.props.handleClose();
  }

  init() {
    const { id } = this.props;
    const { user } = this.props.loginUser;

    let store = null;
    if (user) {
      store = user.store;
    }

    const info = {
      id: id || null,
      store,
      name: '',
      admin: false,
    };

    let loading = false;
    let btnLoading = false;
    if (id) {
      this.props.dispatch(actCallApiGetRole({ id }));
      loading = true;
      btnLoading = true;
    }

    this.setState({
      loading,
      info,
      nameErrorMessage: '',
      btnLoading,
    });
  }

  render() {
    const {
      open, handleClose,
    } = this.props;

    const {
      info, nameErrorMessage, loading, btnLoading,
    } = this.state;
    const stores = this.props.roleList.stores || [];
    const admin = getAdmin(this.props.loginUser);

    return (
      <MaterialDialog open={open} onClose={handleClose}>
        <DialogColorTitle>{ info.id ? '権限の変更' : '権限の追加' }</DialogColorTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          {admin && (
            <Box mb={3}>
              <FormTitle title="店舗" isRequired />
              <SearchSelectBox
                options={stores}
                width="100%"
                value={info.store}
                onChange={this.onChange}
                name="store"
                disableClearable
                disabled={info.id !== null}
              />
            </Box>
          )}
          <Box>
            <FormTitle title="名前" isRequired />
            <TextField
              id="auth-name"
              name="name"
              variant="outlined"
              value={info.name}
              onChange={this.onChange}
              fullWidth
              error={nameErrorMessage !== ''}
              inputProps={{
                maxLength: 50,
              }}
            />
            <FormErrorText error>{nameErrorMessage}</FormErrorText>
          </Box>
          <Box mt={3}>
            <FormTitle title="システム管理者" isRequired />
            <FormControlLabel control={<Switch checked={info.admin} onChange={this.onAdminChange} />} label="システム管理者にする" />
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose}>キャンセル</CancelButton>
          <SaveButton onClick={this.handleSave} loading={btnLoading}>保存する</SaveButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  roleList: state.roleList,
  roleDetail: state.roleDetail,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps)(RoleDetailDialog));
