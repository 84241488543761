import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export default function DialogColorTitle(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <DialogTitle className={classes.root}>{children}</DialogTitle>
  );
}

DialogColorTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
