import { ACTION_TYPE } from './action';

// region Reducer
const systemUserDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_SYSTEM_USER_CREATE_SUCCESS:
      return {
        ...state,
        resultData: action.datas,
        isCreateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_SYSTEM_USER_UPDATE_SUCCESS:
      return {
        ...state,
        resultData: action.datas,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.CALL_API_GET_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CALL_API_SYSTEM_USER_LOGIN_ID_CHECK_SUCCESS:
      return {
        ...state,
        isCheckSuccess: true,
        result: action.result,
      };
    case ACTION_TYPE.CALL_API_SYSTEM_USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePasswordSuccess: true,
        resultData: action.data,
      };
    case ACTION_TYPE.RESET_SYSTEM_USER_DETAIL_FLG:
      return {
        ...state,
        isCheckSuccess: false,
        isCreateSuccess: false,
        isUpdateSuccess: false,
        isChangePasswordSuccess: false,
      };
    default:
      return state;
  }
};

export default systemUserDetail;
