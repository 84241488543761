import { all } from 'redux-saga/effects';

import watchCallLogin from './other/login/saga';

import watchCallSystemUserList from './user/systemUserList/saga';
import watchCallSystemUserDetail from './user/systemUserDetail/saga';
import watchCallRoleList from './user/roleList/saga';
import watchCallRoleDetail from './user/roleDetail/saga';

import watchCallStoreList from './setting/storeList/saga';
import watchCallStoreDetail from './setting/storeDetail/saga';

import watchCallVisitRegist from './visit/visitRegist/saga';
import watchCallDailyVisitStatus from './visit/dailyVisitStatus/saga';
import watchCallVisitStatusList from './visit/visitStatusList/saga';
import watchCallStoreVisitStatus from './visit/storeVisitStatus/saga';
import watchCallVisitStatusDetail from './visit/visitStatusDetail/saga';

import watchCallMemoList from './member/memoList/saga';
import watchCallMemoDetail from './member/memoDetail/saga';
import watchCallMemberList from './member/memberList/saga';
import watchCallMemberDetail from './member/memberDetail/saga';

import watchCallMemberAnalysis from './memberAnalysis/memberAnalysis/saga';
import watchCallUseStoreMemberAnalysis from './memberAnalysis/useStoreMemberAnalysises/saga';

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* rootSaga() {
  yield all([
    watchCallLogin(),

    watchCallSystemUserList(),
    watchCallSystemUserDetail(),
    watchCallRoleList(),
    watchCallRoleDetail(),

    watchCallStoreList(),
    watchCallStoreDetail(),

    watchCallVisitRegist(),
    watchCallDailyVisitStatus(),
    watchCallVisitStatusList(),
    watchCallStoreVisitStatus(),
    watchCallVisitStatusDetail(),

    watchCallMemoList(),
    watchCallMemoDetail(),
    watchCallMemberList(),
    watchCallMemberDetail(),

    watchCallMemberAnalysis(),
    watchCallUseStoreMemberAnalysis(),
  ]);
}
