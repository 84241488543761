import { COMMON } from './common';

export const REST_URL = COMMON.ROOT_URL;

export const REST_API = {
  COMMON: {
    LOGIN: `${REST_URL}/authenticate`,
    ME: `${REST_URL}/me`,
  },

  SELECTS: {
    STORES: `${REST_URL}/select-stores`,
    STORE_GRADE: `${REST_URL}/select-stores-grades`,
    STORE_USAGE: `${REST_URL}/select-stores-usages`,
    ADDRESS: `${REST_URL}/zipcode`,
    STORES_MEMBER_RANKS: `${REST_URL}/select-stores-member-ranks`,
  },

  SETTING: {
    STORE: {
      STORE_LIST: `${REST_URL}/stores`,
      STORE_DETAIL: `${REST_URL}/stores/{id}`,
    },
  },

  MEMBER: {
    MEMBER: {
      MEMBER_SELECTS: `${REST_URL}/members/selects`,
      MEMBERS: `${REST_URL}/members`,
      MEMBER_DETAIL: `${REST_URL}/members/details/{id}`,
      MEMBER: `${REST_URL}/members/{id}`,
      MEMBER_CHECK: `${REST_URL}/members/check`,
      MEMBERS_CSV: `${REST_URL}/members/csv-datas`,
    },
    MEMO: {
      MEMOS: `${REST_URL}/memos`,
      MEMO: `${REST_URL}/memos/{id}`,
    },
  },

  VISIT: {
    VISIT_REGIST: {
      VISIT_REGIST: `${REST_URL}/visit-regists`,
      VISIT_REGIST_DETAIL: `${REST_URL}/visit-regists/{id}`,
    },
    VISIT_STATUS: {
      DAILY: `${REST_URL}/daily-visit-statuses`,
      LIST: `${REST_URL}/visit-statuses`,
    },
    VISIT_STATUS_DETAIL: {
      VISIT_STATUS_DETAIL: `${REST_URL}/visit-statuses/{id}`,
    },
    VISIT_STATUS_STORE: {
      ALL: `${REST_URL}/store-visit-statuses/all`,
      TAX: `${REST_URL}/store-visit-statuses/tax`,
      GENDER: `${REST_URL}/store-visit-statuses/gender`,
      AGE: `${REST_URL}/store-visit-statuses/age`,
    },
  },

  MEMBER_ANALYSIS: {
    MEMBER_ANALYSIS: {
      MEMBER_ANALYSIS_LIST: `${REST_URL}/member-analysises`,
    },
    USE_STORE_MEMBER_ANALYSIS: {
      USE_STORE_MEMBER_ANALYSIS_LIST: `${REST_URL}/use-store-member-analysises`,
    },
  },

  USER: {
    SYSTEM_USER: {
      SELECT_LIST: `${REST_URL}/system-users/select-lists`,
      SYSTEM_USERS: `${REST_URL}/system-users`,
      SYSTEM_USER: `${REST_URL}/system-users/{id}`,
      LOGIN_ID_CHECK: `${REST_URL}/system-users/login-check`,
      CHANGE_PASSWORD: `${REST_URL}/system-users/password/{id}`,
    },
    ROLE: {
      ROLES: `${REST_URL}/roles`,
      ROLE: `${REST_URL}/roles/{id}`,
      ROLE_DETAIL: `${REST_URL}/roles/detail/{id}`,
      SCREENS: `${REST_URL}/screens`,
      ROLE_SCREENS: `${REST_URL}/role-screens/{id}`,
    },
  },
};
