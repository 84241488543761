import { REST_API } from '../../constants/restUrls';
import HttpConnection from '../../utils/http-connection';

/**
 * コンボボックス用の一覧を取得する
 *
 * @returns
 */
export function getSelects() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.USER.SYSTEM_USER.SELECT_LIST)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 検索条件からシステムユーザー情報を取得する
 *
 * @param {*} serachCondition
 * @returns
 */
export function getDatas(serachCondition) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.USER.SYSTEM_USER.SYSTEM_USERS, serachCondition)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 削除処理
 *
 * @param {*} param
 * @returns
 */
export function deleteSystemUser(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .deletes(REST_API.USER.SYSTEM_USER.SYSTEM_USERS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
